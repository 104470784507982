import { useEffect, useState } from "react";

import ErrorText from "@/foundation/Typography/ErrorText";
import { FullPageLoader } from "../../../pages/FullPageLoader/FullPageLoader";
import { AdminTitle } from "../../components/AdminPageTemplate/AdminTitle";
import { ApiController } from "../../../service/ApiController";
import { Table, TableProps } from "../../components/Table/Table";
import { Card } from "@/components/Card/Card";

export const Config = () => {
  const [config, setConfig] = useState<TableProps[]>([]);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    async function getConfig() {
      setLoading(true);
      setError("");

      try {
        const configRes = await ApiController.findAdminConfig();

        setConfig([
          {
            label: "Benchmark in days",
            value: configRes.benchmarkInDays.toString(),
          },
          {
            label: "Invoicing amount",
            value: configRes.invoicingAmount,
          },
          { label: "Tags", value: configRes.tags.join(", ") },
        ]);
        setLoading(false);
      } catch (error) {
        if (error instanceof Error) {
          setError(error.message);
          setLoading(false);
        }
      }
    }
    getConfig();
  }, []);

  return (
    <>
      <AdminTitle title="Config" />
      {loading && <FullPageLoader />}
      {error && <ErrorText>{error}</ErrorText>}
      {!loading && !error && (
        <Card>
          <>
            {config.map(({ label, value }) => (
              <Table key={`config-${label}`} label={label} value={value} />
            ))}
          </>
        </Card>
      )}
    </>
  );
};
