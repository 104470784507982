import { useEffect, useState } from "react";
import { Card } from "@/components/Card/Card";
import { ApiController } from "../../../service/ApiController";
import { useNavigate } from "react-router-dom";
import { Table, TableProps } from "../Table/Table";
import { User } from "../../pages/UserDetails/types";

interface UserData {
  id: string;
  data: TableProps[];
}

const transformUserData = (users: User[]): UserData[] => {
  return users.map((user) => {
    return {
      id: user._id,
      data: [
        {
          label: "Full Name",
          value: `${user.first_name} ${user.last_name}`,
        },
        {
          label: "Email",
          value: user.email,
        },
      ],
    };
  });
};

export const InactiveUsersCard = () => {
  const navigate = useNavigate();

  const [inactiveUsers, setInactiveUsers] = useState<UserData[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function getAllUsers() {
      try {
        const users = await ApiController.findAllUsers({
          isActive: false,
          type: "customer",
        });

        setInactiveUsers(transformUserData(users));
      } catch (error) {
        if (error instanceof Error) {
          console.error(error.message);
        }
      }
    }

    setLoading(true);
    getAllUsers();
    setLoading(false);
  }, [setInactiveUsers]);

  return (
    <Card title="Inactive Users">
      <div className="flex flex-col space-y-5">
        {!loading ? (
          inactiveUsers.length > 0 ? (
            inactiveUsers.map((user, index) => (
              <button
                key={`inactive-user=${index}`}
                onClick={() => navigate(`/admin/users/${user.id}`)}
                className="w-full"
              >
                <Card>
                  <>
                    {user.data.map(({ label, value }, index) => (
                      <Table
                        key={`inactive-user-${index}-${label}`}
                        value={value}
                        label={label}
                      />
                    ))}
                  </>
                </Card>
              </button>
            ))
          ) : (
            <p className="text-center">No inactive users</p>
          )
        ) : (
          <p>Loading...</p>
        )}
      </div>
    </Card>
  );
};
