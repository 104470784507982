import { AdminTransaction } from "../../../pages/TransactionDetails/types";
import { YearAndMonth } from "../../../types/types";

export const getLastDateOfMonth = (selectedPeriod: YearAndMonth): string => {
  // Returns an string with last date of selected month:
  const lastDate = new Date(
    parseInt(selectedPeriod.year),
    parseInt(selectedPeriod.month),
    0,
  );
  return `${lastDate.getDate()}/${selectedPeriod.month}/${selectedPeriod.year}`;
};

export const getAndSortDates = (transactions: AdminTransaction[]): Date[] => {
  return transactions
    .reduce((acc, transaction) => {
      const date = transaction.date_solicitor_groups_assigned?.assigned_at;
      if (date !== undefined) {
        acc.push(new Date(date));
      }
      return acc;
    }, [] as Date[])
    .sort((a, b) => a.getTime() - b.getTime());
};

export const generateYearMonthOptions = (
  dates: Date[],
): {
  label: string;
  value: {
    year: string;
    month: string;
  };
}[] => {
  const yearMonthSet = new Set<string>();
  dates.forEach((date) => {
    const { year, month } = getYearAndMonth(date);
    yearMonthSet.add(`${year}-${month}`);
  });

  return Array.from(yearMonthSet).map((ym) => {
    const [year, month] = ym.split("-");
    const date = new Date(parseInt(year, 10), parseInt(month, 10) - 1);
    const monthName = date.toLocaleString("default", { month: "long" });
    return { label: `${monthName} ${year}`, value: { year, month } };
  });
};

export const getYearAndMonth = (date: Date): YearAndMonth => {
  return {
    year: date.getFullYear().toString(),
    month: (date.getMonth() + 1).toString().padStart(2, "0"),
  };
};
