import Typography from "@/foundation/Typography/Typography";

interface FieldSetProps {
  children: React.ReactNode;
  legend: string;
  description?: string;
}

export const FieldSet = ({ children, legend, description }: FieldSetProps) => (
  <fieldset className="mt-[32px]">
    <legend>
      <Typography
        type="h2"
        variant="xl"
        weight="bold"
        className="text-brand-heavy-teal-100 mb-[16px]"
      >
        {legend}
      </Typography>
    </legend>

    {description && (
      <Typography className="text-brand-heavy-teal-100 max-w-[750px] mb-[16px]">
        {description}
      </Typography>
    )}

    {children}
  </fieldset>
);
