import { useTabletMediaQuery } from "../../../mediaQueries";
import Stars from "../../../assets/five-stars.svg";
import Typography from "@/foundation/Typography/Typography";

interface ReviewProps {
  type: string;
  initials: string;
  title: string;
  content: string;
}

export const Review = ({ type, initials, title, content }: ReviewProps) => {
  const isTablet = useTabletMediaQuery();

  return (
    <div>
      <div
        className={`relative rounded-2xl p-[32px] m-[16px] bg-brand-sand-100 ${isTablet ? "w-[370px]" : "w-[330px]"} shrink-0`}
      >
        <div className="absolute top-[-50px] right-[32px] rounded-full w-[100px] h-[100px] bg-brand-heavy-warmth-100 flex flex-col items-center justify-center">
          <Typography
            className={`font-display text-brand-heavy-teal-100`}
            type="p"
            variant="h3"
            weight="semi-bold"
          >
            {initials}
          </Typography>

          <Typography
            className="text-brand-heavy-teal-100 mt-[-4px]"
            type="p"
            variant="base"
            weight="regular"
          >
            {type}
          </Typography>
        </div>

        <img src={Stars} className="w-[100px] h-[20px]" />

        <Typography
          className={`font-display text-brand-heavy-teal-100 my-[23px]`}
          type="h3"
          variant="h3"
          weight="semi-bold"
        >
          {title}
        </Typography>

        <Typography
          className={`text-brand-heavy-teal-100`}
          type="p"
          variant="base"
          weight="regular"
        >
          {content}
        </Typography>
      </div>
    </div>
  );
};
