import { StepperProps } from "@/components/Stepper/Stepper";

// Buyer Flow Stepper Options - Welcome Page
export const buyWelcome: StepperProps[] = [
  {
    variant: "static",
    time: "1 min",
    details: "About you",
  },
  {
    variant: "static",
    time: "1 min",
    details: "About the property",
  },
  {
    variant: "static",
    time: "1 min",
    details: "Terms & Conditions",
  },
  {
    variant: "static",
    time: "1 min",
    details: "What’s included",
  },
  {
    variant: "static",
    time: "1 min",
    details: "Success",
  },
];

// Seller Flow Stepper Options - Welcome Page
export const sellWelcome: StepperProps[] = [
  {
    variant: "static",
    time: "1 min",
    details: "About you",
  },
  {
    variant: "static",
    time: "1 min",
    details: "About the property",
  },
  {
    variant: "static",
    time: "1 min",
    details: "Terms & Conditions",
  },
  {
    variant: "static",
    time: "1 min",
    details: "Payment",
  },
  {
    variant: "static",
    time: "1 min",
    details: "Success",
  },
];

// Buyer Flow Stepper Options – About You Page
export const buyAboutYou: StepperProps[] = [
  {
    variant: "active",
    time: "1 min",
    details: "About you",
  },
  {
    variant: "static",
    time: "1 min",
    details: "About the property",
  },
  {
    variant: "static",
    time: "1 min",
    details: "Terms & Conditions",
  },
  {
    variant: "static",
    time: "1 min",
    details: "What’s included",
  },
  {
    variant: "static",
    time: "1 min",
    details: "Success",
  },
];

// Seller Flow Stepper Options – About You Page
export const sellAboutYou: StepperProps[] = [
  {
    variant: "active",
    time: "1 min",
    details: "About you",
  },
  {
    variant: "static",
    time: "1 min",
    details: "About the property",
  },
  {
    variant: "static",
    time: "1 min",
    details: "Terms & Conditions",
  },
  {
    variant: "static",
    time: "1 min",
    details: "Payment",
  },
  {
    variant: "static",
    time: "1 min",
    details: "Success",
  },
];

// Buyer Flow Stepper Options – About The Property Page
export const buyAboutTheProperty: StepperProps[] = [
  {
    variant: "success",
    time: "1 min",
    details: "About you",
  },
  {
    variant: "active",
    time: "1 min",
    details: "About the property",
  },
  {
    variant: "static",
    time: "1 min",
    details: "Terms & Conditions",
  },
  {
    variant: "static",
    time: "1 min",
    details: "What’s included",
  },
  {
    variant: "static",
    time: "1 min",
    details: "Success",
  },
];

// Seller Flow Stepper Options – About The Property Page
export const sellAboutTheProperty: StepperProps[] = [
  {
    variant: "success",
    time: "1 min",
    details: "About you",
  },
  {
    variant: "active",
    time: "1 min",
    details: "About the property",
  },
  {
    variant: "static",
    time: "1 min",
    details: "Terms & Conditions",
  },
  {
    variant: "static",
    time: "1 min",
    details: "Payment",
  },
  {
    variant: "static",
    time: "1 min",
    details: "Success",
  },
];

// Buyer Flow Stepper Options – Terms & Conditions Page
export const buyTerms: StepperProps[] = [
  {
    variant: "success",
    time: "1 min",
    details: "About you",
  },
  {
    variant: "success",
    time: "1 min",
    details: "About the property",
  },
  {
    variant: "active",
    time: "1 min",
    details: "Terms & Conditions",
  },
  {
    variant: "static",
    time: "1 min",
    details: "What’s included",
  },
  {
    variant: "static",
    time: "1 min",
    details: "Success",
  },
];

// Seller Flow Stepper Options – Terms & Conditions Page
export const sellTerms: StepperProps[] = [
  {
    variant: "success",
    time: "1 min",
    details: "About you",
  },
  {
    variant: "success",
    time: "1 min",
    details: "About the property",
  },
  {
    variant: "active",
    time: "1 min",
    details: "Terms & Conditions",
  },
  {
    variant: "static",
    time: "1 min",
    details: "Payment",
  },
  {
    variant: "static",
    time: "1 min",
    details: "Success",
  },
];

// Buyer Flow Stepper Options – What’s Included Page
export const buyWhatsIncluded: StepperProps[] = [
  {
    variant: "success",
    time: "1 min",
    details: "About you",
  },
  {
    variant: "success",
    time: "1 min",
    details: "About the property",
  },
  {
    variant: "success",
    time: "1 min",
    details: "Terms & Conditions",
  },
  {
    variant: "active",
    time: "1 min",
    details: "What's included",
  },
  {
    variant: "static",
    time: "1 min",
    details: "Success",
  },
];

// Seller Flow Stepper Options – Payment Page
export const sellPayment: StepperProps[] = [
  {
    variant: "success",
    time: "1 min",
    details: "About you",
  },
  {
    variant: "success",
    time: "1 min",
    details: "About the property",
  },
  {
    variant: "success",
    time: "1 min",
    details: "Terms & Conditions",
  },
  {
    variant: "active",
    time: "1 min",
    details: "Payment",
  },
  {
    variant: "static",
    time: "1 min",
    details: "Success",
  },
];

// Buyer Flow Stepper Options – Success Page
export const buySuccess: StepperProps[] = [
  {
    variant: "success",
    time: "1 min",
    details: "About you",
  },
  {
    variant: "success",
    time: "1 min",
    details: "About the property",
  },
  {
    variant: "success",
    time: "1 min",
    details: "Terms & Conditions",
  },
  {
    variant: "success",
    time: "1 min",
    details: "What's included",
  },
  {
    variant: "success",
    time: "1 min",
    details: "Success",
  },
];

// Seller Flow Stepper Options – Payment Success Page
export const sellPaymentSuccess: StepperProps[] = [
  {
    variant: "success",
    time: "1 min",
    details: "About you",
  },
  {
    variant: "success",
    time: "1 min",
    details: "About the property",
  },
  {
    variant: "success",
    time: "1 min",
    details: "Terms & Conditions",
  },
  {
    variant: "success",
    time: "1 min",
    details: "Payment",
  },
  {
    variant: "success",
    time: "1 min",
    details: "Success",
  },
];
