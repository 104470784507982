import Typography from "@/foundation/Typography/Typography";
import { Input } from "../../../Formik/Input/Input";
import { formatPostcode } from "@shared/utils";
import { useField } from "formik";

interface ManualAddressFormProps {
  isManualAddressOpen: boolean;
  setIsManualAddressOpen: (value: boolean) => void;
  namespace: string;
}

export const ManualAddressForm = ({
  isManualAddressOpen,
  setIsManualAddressOpen,
  namespace,
}: ManualAddressFormProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, _2, helpers] = useField<string>(`${namespace}.postcode`);

  const onSetPostcode = (postcode: string) => {
    helpers.setValue(postcode);
  };

  return (
    <>
      {isManualAddressOpen ? (
        <div className="flex flex-col w-full md:w-[320px] space-y-[8px] mt-[24px]">
          <Input fieldName={`${namespace}.line_1`} label="Address line 1" />
          <Input
            fieldName={`${namespace}.line_2`}
            label="Address Line Two (optional)"
          />
          <Input
            fieldName={`${namespace}.line_3`}
            label="Address Line Three (optional)"
          />
          <Input fieldName={`${namespace}.post_town`} label="Town" />
          <Input fieldName={`${namespace}.country`} label="Country" />
          <Input
            fieldName={`${namespace}.postcode`}
            label="Postcode"
            onChange={(newVal) => {
              onSetPostcode(formatPostcode(newVal.target.value));
            }}
          />
        </div>
      ) : (
        <button
          className="underline cursor-pointer text-brand-heavy-teal-75 my-[16px]"
          onClick={() => setIsManualAddressOpen(true)}
          type="button"
        >
          <Typography
            variant="base"
            weight="regular"
            type="span"
            className="text-brand-heavy-teal-75"
          >
            Enter address manually
          </Typography>
        </button>
      )}
    </>
  );
};
