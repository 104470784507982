import { useState } from "react";

import { Container } from "../../../components/Container/Container";
import { SimpleAccordion } from "../Accordion/SimpleAccordion";
import { useTabletMediaQuery } from "../../../mediaQueries";
import Typography from "@/foundation/Typography/Typography";

import { questions } from "./questions";

export const Faqs = () => {
  const isTablet = useTabletMediaQuery();

  const [openAccordionIndex, setOpenAccordionIndex] = useState<number | null>(
    null,
  );

  const toggleAccordion = (index: number | null) => {
    setOpenAccordionIndex(index === openAccordionIndex ? null : index);
  };

  return (
    <div className="pb-[80px]">
      <Container maxWidth={1150}>
        <div className="mb-[40px]">
          <Typography
            className="font-display text-center text-brand-heavy-teal-100 mb-[16px]"
            type="h2"
            variant={isTablet ? "h1" : "h2"}
            weight="semi-bold"
          >
            FAQs
          </Typography>
        </div>

        <div className="max-w-[896px] mx-auto px-[32px]">
          {questions.map((question, index) => (
            <SimpleAccordion
              key={index}
              isOpen={openAccordionIndex === index}
              onToggle={() => toggleAccordion(index)}
              title={question.title}
              description={question.answer}
            />
          ))}
        </div>

        <div className="flex justify-center mt-[40px]">
          <a href="#contact" className="text-brand-heavy-teal-100 underline">
            Any other questions? Get in touch
          </a>
        </div>
      </Container>
    </div>
  );
};
