type ContainerProps = {
  children?: React.ReactNode;
  maxWidth?: number;
};

export const Container = ({ children, maxWidth, ...props }: ContainerProps) => (
  <div
    className="w-full mx-auto"
    style={{ maxWidth: maxWidth || "1240px" }}
    {...props}
  >
    {children}
  </div>
);
