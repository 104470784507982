import { ComponentProps } from "react";

import Typography from "./Typography";

export type ErrorTextProps = ComponentProps<"div"> & {
  children: React.ReactNode;
};

export const ErrorText = ({ children }: ErrorTextProps) => {
  return (
    <div className="text-ui-error-100 mt-[8px]">
      <Typography type="p" variant="sm" weight="regular">
        {children}
      </Typography>
    </div>
  );
};

export default ErrorText;
