import { useState } from "react";
import { withZodSchema } from "formik-validator-zod";
import { ErrorMessage, Formik } from "formik";
import { z } from "zod";
import { Modal } from "../../../components/Modal/Modal";
import { ApiController } from "../../../service/ApiController";
import {
  PurchaseIntent,
  PurchaseIntentSignificantDates,
} from "../../pages/TransactionDetails/types";
import {
  DateInput,
  DateInputOptionalSchema,
  dateFormInputToIsoDate,
  transformDateForDateInput,
  DateInputValue,
} from "../../../components/Formik/DateInput";

interface SignificantDatesEditModalProps {
  purchaseIntentId: string;
  significantDates: PurchaseIntentSignificantDates;
  onCancel: () => void;
  onSaved: (updatedPurchaseIntent: PurchaseIntent) => void;
}

interface FormState {
  aborted: DateInputValue;
  exchanged: DateInputValue;
  completed: DateInputValue;
  saleAgreed: DateInputValue;
}

const SignificantDatesForm = z.object({
  aborted: DateInputOptionalSchema,
  exchanged: DateInputOptionalSchema,
  completed: DateInputOptionalSchema,
  saleAgreed: DateInputOptionalSchema,
});

export const SignificantDatesEditModal = ({
  purchaseIntentId,
  significantDates,
  onCancel,
  onSaved,
}: SignificantDatesEditModalProps) => {
  const [error, setError] = useState<string | null>(null);

  const getInitialValues = (): FormState => {
    const { aborted, exchanged, completed, sale_agreed } = significantDates;

    return {
      aborted: transformDateForDateInput(
        aborted ? new Date(aborted) : undefined,
      ),
      exchanged: transformDateForDateInput(
        exchanged ? new Date(exchanged) : undefined,
      ),
      completed: transformDateForDateInput(
        completed ? new Date(completed) : undefined,
      ),
      saleAgreed: transformDateForDateInput(
        sale_agreed ? new Date(sale_agreed) : undefined,
      ),
    };
  };

  const prepareFormValuesForApi = (
    values: FormState,
  ): PurchaseIntentSignificantDates => {
    const { aborted, exchanged, completed, saleAgreed } = values;

    return {
      aborted: dateFormInputToIsoDate(aborted),
      exchanged: dateFormInputToIsoDate(exchanged),
      completed: dateFormInputToIsoDate(completed),
      sale_agreed: dateFormInputToIsoDate(saleAgreed),
    };
  };

  const onSave = async (formValue: FormState) => {
    setError(null);

    try {
      const newSignificantDates = prepareFormValuesForApi(formValue);

      const updatedPurchaseIntent =
        await ApiController.updatePurchaseIntentSignificantDates(
          purchaseIntentId,
          newSignificantDates,
        );

      onSaved(updatedPurchaseIntent);
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
      }
    }
  };

  return (
    <Formik
      initialValues={getInitialValues()}
      validate={withZodSchema(SignificantDatesForm)}
      onSubmit={onSave}
    >
      {({ handleSubmit }) => {
        return (
          <form>
            <Modal
              title="Edit Significant Dates"
              cancelButtonFunction={onCancel}
              saveButtonFunction={handleSubmit}
            >
              <div className="flex flex-col space-y-3">
                <DateInput namespace="saleAgreed" label="Sale Agreed" />
                <DateInput namespace="exchanged" label="Exhanged" />
                <DateInput namespace="completed" label="Completed" />
                <DateInput namespace="aborted" label="Aborted" />
              </div>

              {error && <ErrorMessage name={error} />}
            </Modal>
          </form>
        );
      }}
    </Formik>
  );
};
