import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ApiController } from "../../service/ApiController";
import { Background } from "../../components/Background/Background";
import { Container } from "../../components/Container/Container";
import { Header } from "../../components/Header/Header";
import { Nav } from "../../components/Nav/Nav";
import { TransactionsList } from "./TransactionsList";
import { FullPageLoader } from "../FullPageLoader/FullPageLoader";

import { PurchaseIntentResponse, TransactionResponse } from "@shared/types";
import { useAuthStore, UserType } from "../../store/auth/auth";
import { useLoginStore } from "../../store/Login/login";
import { useTransactionFormStore } from "../../store/TransactionForm/transactionForm";
import { usePurchaseIntentFormStore } from "../../store/PurchaseIntentForm/purchaseIntentForm";
import {
  transformTransactionForLocalStorage,
  transformPurchaseIntentForLocalStorage,
} from "@transformers";

const useFetchTransactions = (
  userData: UserType,
  setSales: React.Dispatch<React.SetStateAction<TransactionResponse[]>>,
  setPurchases: React.Dispatch<React.SetStateAction<TransactionResponse[]>>,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  const fetchTransactions = useCallback(async () => {
    if (!userData) return;

    try {
      const sales = await ApiController.findTransactionsByUserId(userData._id);
      const purchases =
        await ApiController.findTransactionsByPurchaseIntentUserId(
          userData._id,
        );

      setSales(sortMostRecent(sales));
      setPurchases(sortMostRecent(purchases));
      setIsLoading(false);
    } catch (error) {
      console.error("Failed to fetch transactions:", error);
    }
  }, [userData, setSales, setPurchases, setIsLoading]);

  useEffect(() => {
    fetchTransactions();
  }, [fetchTransactions]);
};

const sortMostRecent = (transactions: TransactionResponse[]) => {
  return transactions.sort(
    (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
  );
};

export const WelcomeBack = () => {
  const navigate = useNavigate();
  const { setRole } = useLoginStore();
  const { userData } = useAuthStore();
  const { updateTransaction } = useTransactionFormStore();
  const { updatePurchaseIntent } = usePurchaseIntentFormStore();

  const [sales, setSales] = useState<TransactionResponse[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [purchases, setPurchases] = useState<TransactionResponse[]>([]);
  const [userName, setUserName] = useState<string | null | undefined>("");

  useFetchTransactions(
    userData as UserType,
    setSales,
    setPurchases,
    setIsLoading,
  );

  const handleResumeTransaction = (
    transactionId: string,
    type: "sale" | "purchase",
  ) => {
    if (!userData) return;

    const transactions = type === "sale" ? sales : purchases;
    const transaction = transactions.find((t) => t._id === transactionId);

    if (!transaction) {
      return console.error("Transaction not found");
    }

    if (type === "sale") {
      updateTransaction(transformTransactionForLocalStorage(transaction));
      setRole("sell");
      navigate("/sell/payment");
    } else {
      const currentPurchaseIntent = transaction.purchase_intent.find((intent) =>
        intent.buyers.some((buyer) => buyer.user._id === userData._id),
      ) as PurchaseIntentResponse | undefined;

      if (!currentPurchaseIntent) {
        return console.error("Matching purchase intent not found");
      }

      const propertyAddress = transaction.property_id.address;

      updatePurchaseIntent(
        transformPurchaseIntentForLocalStorage(
          currentPurchaseIntent,
          propertyAddress,
        ),
      );
      setRole("buy");
      navigate("/buy/whats-included");
    }
  };

  useEffect(() => {
    if (userData) {
      setUserName(userData.first_name);
    }
  }, [userData]);

  if (isLoading) {
    return <FullPageLoader />;
  }

  return (
    <Background className="flex flex-col">
      <Nav />

      <Container>
        <div className="flex flex-col px-[24px] lg:px-0">
          <Header title={`Welcome back${userName ? `, ${userName}` : ""}`} />

          <div className="mb-[14px]">
            <TransactionsList
              transactions={sales}
              type="sell"
              handleResumeTransaction={(id) =>
                handleResumeTransaction(id, "sale")
              }
            />
            <TransactionsList
              transactions={purchases}
              type="buy"
              handleResumeTransaction={(id) =>
                handleResumeTransaction(id, "purchase")
              }
            />
          </div>
        </div>
      </Container>
    </Background>
  );
};
