import { useNavigate } from "react-router-dom";

import { Button } from "@/components/Button/Button";
import { Container } from "../../components/Container/Container";
import { Nav } from "../../components/Nav/Nav";
import Typography from "@/foundation/Typography/Typography";

import BackgroundImage from "../../assets/layer2.png";

export const LinkExpired = () => {
  const navigate = useNavigate();

  return (
    <div
      className="bg-brand-heavy-warmth-75 flex flex-col px-[24px]"
      style={{
        backgroundImage: `url(${BackgroundImage})`,
        backgroundPosition: "center 190px",
        backgroundRepeat: "no-repeat",
        backgroundSize: "622px 624px",
        backgroundAttachment: "fixed",
        minHeight: "100vh",
      }}
    >
      <Nav />

      <Container>
        <div className="flex flex-col my-64 h-max items-center">
          <Typography
            type="h1"
            variant="h1"
            weight="bold"
            className=" text-brand-heavy-teal-100 font-display text-center mb-[24px]"
            data-testid="header-title"
          >
            Your link has expired
          </Typography>

          <Typography
            type="p"
            variant="lg"
            weight="bold"
            className=" text-brand-heavy-teal-100 mb-[36px] text-center"
          >
            Please go to the Sign In page and we&apos;ll send you another link.
          </Typography>

          <Button
            type="button"
            variant="primary"
            onClick={() => navigate(`/sign-in`)}
            className="w-full max-w-[320px]"
          >
            Go to Sign In
          </Button>
        </div>
      </Container>
    </div>
  );
};
