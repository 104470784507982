import { api } from ".";
import { UserType } from "../../store/auth/auth";

import { StripeCustomer } from "@shared/types";

export const createOrUpdateCustomer = async (
  user: UserType,
): Promise<StripeCustomer> => {
  try {
    const response = await api.post("/stripe/customers", user);
    const stripeCustomer: StripeCustomer = response.data;

    if (!stripeCustomer.id) {
      throw new Error(
        response.data.error || "Failed to create / update Stripe customer",
      );
    }

    return stripeCustomer;
  } catch (error) {
    throw new Error("Create new Stripe customer failed");
  }
};
