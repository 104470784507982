import { useState } from "react";
import { useField } from "formik";

import Typography from "@/foundation/Typography/Typography";
import { SelectableCardGroup } from "@/components/SelectableCard/SelectableCardGroup";
import ErrorText from "@/foundation/Typography/ErrorText";
import { typeOfPropertyCard, typeOfPropertyOptions } from "../Fields";
import { Tenure } from "@shared/types";
import { isOtherTenure } from "../../../../utils/utils";
import { Input } from "../../../Formik/Input/Input";

export const TypeOfProperty = () => {
  const [fieldTenureOption, metaTenureOption, helpersTenureOption] =
    useField("tenureOption");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, _2, helpersTenureDetails] = useField("tenureDetails");

  const [selectedTenure, setSelectedTenure] = useState(() => {
    return fieldTenureOption.value === null
      ? null
      : isOtherTenure(fieldTenureOption.value)
        ? Tenure.Other
        : fieldTenureOption.value;
  });

  return (
    <>
      <label htmlFor="typeOfProperty" className="mb-[16px] mt-[32px]">
        <Typography
          type="h2"
          variant="xl"
          weight="bold"
          className="text-brand-heavy-teal-100"
        >
          Property type
        </Typography>
      </label>

      <Typography className="text-brand-heavy-teal-100 max-w-[750px] mb-[16px]">
        What type of property are you selling? Please select the option that
        best describes your property.
      </Typography>

      <div>
        <SelectableCardGroup
          options={typeOfPropertyOptions}
          selectedOption={selectedTenure}
          radioName="tenureOption"
          card={typeOfPropertyCard.map((card) => {
            const isFreeholdSelected =
              card.option === Tenure.Freehold &&
              selectedTenure === Tenure.Freehold;
            const isLeaseholdSelected =
              card.option === Tenure.Leasehold &&
              selectedTenure === Tenure.Leasehold;
            const isShareOfFreeholdSelected =
              card.option === Tenure.ShareOfFreehold &&
              selectedTenure === Tenure.ShareOfFreehold;
            return {
              ...card,
              icon:
                isFreeholdSelected ||
                isLeaseholdSelected ||
                isShareOfFreeholdSelected
                  ? card.whiteIcon
                  : card.icon,
            };
          })}
          handleChange={(option: string) => {
            if (option === Tenure.Other) {
              setTimeout(() => helpersTenureDetails.setTouched(true));
              helpersTenureDetails.setValue("");
            }
            helpersTenureOption.setValue(option);
            setSelectedTenure(option);
          }}
          value={fieldTenureOption.value}
          otherCard={true}
          variant={
            metaTenureOption.error && metaTenureOption.touched
              ? "error"
              : "enabled"
          }
        />
      </div>

      {selectedTenure === Tenure.Other && (
        <div className="mt-[16px] w-full md:w-[320px]">
          <Input fieldName="tenureDetails" label="Please give details" />
        </div>
      )}

      <div className="h-[16px]">
        {metaTenureOption.error && metaTenureOption.touched && (
          <ErrorText>{metaTenureOption.error}</ErrorText>
        )}
      </div>
    </>
  );
};
