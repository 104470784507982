import TextInput from "@/components/Input/TextInput";
import ErrorText from "@/foundation/Typography/ErrorText";
import { useField, useFormikContext } from "formik";

interface InputProps {
  fieldName: string;
  label: string;
  fieldType?: "text";
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const Input = ({
  fieldName,
  label,
  fieldType = "text",
  onChange,
}: InputProps) => {
  const [field, meta] = useField(fieldName);
  const { touched } = meta;
  const { errors } = useFormikContext();

  return (
    <>
      <TextInput
        {...field}
        label={label}
        type={fieldType}
        variant={
          errors[fieldName as keyof typeof errors] && touched
            ? "error"
            : "enabled"
        }
        onChange={onChange || field.onChange}
        className="min-w-[320px]"
      />

      <div className="h-[16px]">
        {errors[fieldName as keyof typeof errors] && touched && (
          <ErrorText>{errors[fieldName as keyof typeof errors]}</ErrorText>
        )}
      </div>
    </>
  );
};
