import { operations } from "@shared/types.d";
import { api } from "../../../service/ApiController";
import { AdminTransaction } from "../../pages/TransactionDetails/types";

type GetTransactionsQueryParams =
  operations["TransactionController_findAll"]["parameters"]["query"];

export const findAllTransactions = async (
  params: GetTransactionsQueryParams,
): Promise<AdminTransaction[]> => {
  const queryParams: { [key: string]: string } = {
    searchValue: params?.searchValue ?? "",
  };

  if (params?.isPaid !== undefined) {
    queryParams["isPaid"] = params.isPaid.toString();
  }

  const searchParams = new URLSearchParams(queryParams);

  try {
    const response = await api.get(`/transaction?${searchParams.toString()}`);
    return response.data;
  } catch (error) {
    throw new Error("Transactions not found");
  }
};
