import validator from "validator";

export const refineMobileNumber = (phoneNumber: string) => {
  const numericPhoneNumber = parseFloat(phoneNumber.replace(/,/g, ""));
  return (
    !isNaN(numericPhoneNumber) &&
    numericPhoneNumber.toString().length >= 5 &&
    validator.isMobilePhone(phoneNumber)
  );
};
