import { ComponentProps, useState } from "react";
import Checkbox from "./Checkbox";

interface Options {
  label: string;
  value: string;
}

export type CheckboxButtonsProps = ComponentProps<"input"> & {
  options: Options[];
  setSelectedOptions: (value: string[]) => void;
  initialSelectedOptions: { [keyof: string]: boolean };
};

export const CheckboxButtons = ({
  options,
  setSelectedOptions,
  initialSelectedOptions,
}: CheckboxButtonsProps) => {
  const [isCheckedMap, setIsCheckedMap] = useState<Record<string, boolean>>(
    initialSelectedOptions,
  );
  const handleCheckedInput = (options: Options) => {
    const newIsCheckedMap = {
      ...isCheckedMap,
      [options.label]: !isCheckedMap[options.label],
    };
    setIsCheckedMap(newIsCheckedMap);

    const newSelectedOptions = Object.keys(newIsCheckedMap).filter(
      (option) => newIsCheckedMap[option],
    );
    setSelectedOptions(newSelectedOptions);
  };

  return (
    <fieldset className="flex flex-wrap">
      {options.map((option) => (
        <div key={option.label} className="mr-[16px] mb-[16px]">
          <Checkbox
            name={option.label}
            variant="enabled"
            isChecked={isCheckedMap[option.label]}
            label={option.label}
            handleCheckedInput={() => handleCheckedInput(option)}
          />
        </div>
      ))}
    </fieldset>
  );
};
