import { ComponentProps, FocusEventHandler, useRef, useState } from "react";

import Icons, { TextInputIconProps } from "../../foundation/Icons/Icons";
import { statusConfig } from "./statusConfig";

export type TextInputProps = ComponentProps<"input"> & {
  variant?: "enabled" | "disabled" | "success" | "error";
  showIcon?: boolean;
  name: string;
  label?: string;
  value?: string | number;
  onFocus?: (e: FocusEventHandler<HTMLInputElement>) => void;
  onBlur?: (e: FocusEventHandler<HTMLInputElement>) => void;
  icon?: TextInputIconProps;
  className?: string;
};

const TextInput = ({
  variant = "enabled",
  showIcon = false,
  name,
  label,
  value = "",
  onFocus,
  onBlur,
  icon = "Placeholder",
  className,
  ...props
}: TextInputProps) => {
  const [isOnFocus, setIsOnFocus] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setIsOnFocus(true);
    onFocus && onFocus(e);
  };

  const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setIsOnFocus(false);
    onBlur && onBlur(e);
  };

  const inputClasses = `w-full h-[64px] pt-[18px] pb-[4px] pl-[24px] pr-[14px] shadow-questionType rounded-lg border-2 hover:border-ui-black-25 flex flex-col text-base text-ui-black-75 leading-none focus:border-brand-heavy-teal-100 focus:outline-none focus:shadow-outline ${
    showIcon ? " pl-[52px]" : " pl-7"
  }`;

  const statusClasses = statusConfig[variant] || "";

  return (
    <div className="relative">
      <label
        className={`absolute left-[0] ${
          showIcon ? "pl-[54px]" : "pl-[25px]"
        } leading-none transition-transform duration-300 transform cursor-text ${
          isOnFocus || value !== ""
            ? " text-xs text-ui-black-75 -translate-y-1/2 top-[22px] border-brand-heavy-teal-100"
            : " text-base text-ui-black-75/60 top-[24px]"
        }`}
        htmlFor={name}
        onClick={() => {
          if (inputRef.current) {
            inputRef.current.focus();
          }
        }}
      >
        {label}
      </label>

      <input
        id={name}
        name={name}
        disabled={variant === "disabled"}
        value={value}
        className={`${inputClasses} ${statusClasses} ${className}`}
        ref={inputRef}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        {...props}
      />

      {showIcon && (
        <div className="absolute top-[8px] left-[4px] m-[16px]">
          <Icons iconName={icon} colour={variant} />
        </div>
      )}
    </div>
  );
};

export default TextInput;
