import { useTabletMediaQuery } from "../../../mediaQueries";
import FiveStars from "../../../assets/five-stars.svg";
import Icons from "@/foundation/Icons/Icons";
import QuotationMark from "../../../assets/quotation-mark.svg";
import Typography from "@/foundation/Typography/Typography";

interface AccordionProps {
  propertyType: string;
  title: string;
  subtitle: string;
  description: string;
  imageUrl: string;
  quoteHeadline: string;
  quoteBody: string;
  isOpen: boolean;
  onToggle: () => void;
}

export const Accordion = ({
  propertyType,
  title,
  subtitle,
  description,
  imageUrl,
  quoteHeadline,
  quoteBody,
  isOpen,
  onToggle,
}: AccordionProps) => {
  const isTablet = useTabletMediaQuery();
  const accordionContentClass = isOpen ? "visible" : "hidden";

  return (
    <div className="rounded-2xl bg-ui-white-100 p-[8px] md:p-[24px] w-full mb-[16px]">
      <div className="flex-col relative flex md:flex-row">
        <div
          className={`rounded-2xl shrink-0 overflow-hidden ${
            isTablet
              ? `mr-[45px] w-[235px] ${isOpen ? "h-[295px]" : "h-[153px]"}`
              : "w-full h-[200px]"
          }`}
        >
          <img src={imageUrl} className="w-full h-auto rounded-2xl" />
        </div>

        <div className="pt-[32px] w-full md:relative md:pt-[24px]">
          <div className="mb-[12px] flex flex-col items-center md:flex-row md:align-middle">
            <Typography
              className="uppercase text-brand-heavy-teal-100 tracking-loose mb-[8px] text-center md:mr-[8px]"
              type="p"
              variant="sm"
              weight="bold"
            >
              {propertyType}
            </Typography>

            <img src={FiveStars} className="w-[136px] md:mt-[-3px]" />
          </div>

          <Typography
            className="font-display text-brand-heavy-teal-100 text-center md:text-left"
            type="h3"
            variant={isTablet ? "h1" : "h2"}
            weight="semi-bold"
          >
            {title}
          </Typography>

          <Typography
            className={`text-brand-heavy-teal-100 ${isTablet ? (isOpen ? "mb-[40px]" : "mb-0") : "mb-[32px] text-center"}`}
            type="p"
            variant="xl"
            weight="bold"
          >
            {subtitle}
          </Typography>

          <div className={accordionContentClass}>
            <Typography
              className="text-brand-heavy-teal-100"
              type="p"
              variant="lg"
              weight="regular"
            >
              {description}
            </Typography>
          </div>

          <button
            className="absolute top-[8px] right-[8px] md:top-0 md:right-0 w-[56px] h-[56px] bg-brand-heavy-teal-100 rounded-full flex items-center justify-center"
            onClick={() => onToggle()}
          >
            <span className={isOpen ? "rotate-180 mb-[4px]" : "mb-[-4px]"}>
              <Icons iconName="ArrowDown" colour="complement" size="24" />
            </span>
            <span className="hidden">Open</span>
          </button>
        </div>
      </div>

      <div className={accordionContentClass}>
        <div className="relative flex bg-brand-heavy-warmth-100 rounded-2xl flex-col md:items-center px-[12px] py-[36px] mt-[32px] md:flex-row md:p-[36px] md:mt-[45px]">
          <div className="flex flex-row items-center w-[100%] md:w-[40%]">
            <img
              src={QuotationMark}
              className="shrink-0 mt-[-4px] ml-[16px] md:mt-[-38px]"
            />

            <Typography
              className="text-brand-heavy-teal-100 mx-[16px] mt-[36px] md:mt-0"
              type="p"
              variant="h3"
              weight="bold"
            >
              {quoteHeadline}
            </Typography>
          </div>

          <div className="mt-[36px] md:mt-0 md:w-[60%]">
            <Typography
              className="text-brand-heavy-teal-100"
              type="p"
              variant="base"
              weight="regular"
            >
              <i>{quoteBody}</i>
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};
