import Icons from "../../foundation/Icons/Icons";
import Typography from "../../foundation/Typography/Typography";

import { FeatureCardProps } from "./FeatureCard.types";

export const FeatureCard = ({
  description,
  iconName,
  title,
  customClass,
  cardColour,
  orientation,
}: FeatureCardProps) => (
  <>
    <div
      className={`flex flex-col relative bg-ui-white-100 shadow-questionType rounded-lg ${customClass} mb-[16px] border border-ui-black-10`}
    >
      {orientation === "portrait" && iconName ? (
        <>
          <div
            className={`h-[64px] flex items-center ${cardColour || "bg-brand-heavy-teal-100"} rounded-t-lg pr-[16px]`}
          >
            {iconName && (
              <div className="rounded-full h-[32px] w-[32px] bg-brand-teal-100 border border-brand-teal-100 flex items-center justify-center mx-[16px]">
                <Icons iconName={iconName} size="16" colour="highlight" />
              </div>
            )}

            <div>
              <Typography
                type="h4"
                variant="h4"
                weight="bold"
                className={`${cardColour ? "text-brand-teal-100" : "text-ui-white-100"}`}
              >
                {title}
              </Typography>
            </div>
          </div>

          <div className="flex items-center p-[24px]">
            <Typography
              type="p"
              variant="base"
              weight="regular"
              className="font-normal"
            >
              {description}
            </Typography>
          </div>
        </>
      ) : (
        <>
          <div className="w-full max-w-[353px] flex items-center justify-between">
            {iconName && (
              <div className="ml-[16px]">
                <div className="relative flex items-center justify-center ml-[8px]">
                  <Icons iconName={iconName} size="60" colour="highlight" />
                </div>
                <div className="absolute left-[10px] top-[50px]">
                  <Icons iconName="GreenTick" size="30" colour="highlight" />
                </div>
              </div>
            )}

            <div className="py-[44px] px-[16px]">
              <Typography type="h3" variant="h3" weight="semi-bold">
                {title}
              </Typography>

              <Typography
                type="p"
                variant="sm"
                weight="regular"
                className="font-normal py-[12px]"
              >
                {description}
              </Typography>
            </div>
          </div>
        </>
      )}
    </div>
  </>
);
