import * as Sentry from "@sentry/react";
import { isAxiosError } from "axios";

import { api } from ".";
import { SignUpType } from "@schema";
import { paths } from "@shared/types.d";

type SignUpBody =
  paths["/api/authentication/sign-up"]["post"]["requestBody"]["content"]["application/json"];

type SignUpResponse =
  paths["/api/authentication/sign-up"]["post"]["responses"]["201"]["content"]["application/json"];

export const signUp = async (
  values: SignUpType,
  role: "buy" | "sell",
  preventEmail: boolean,
): Promise<SignUpResponse> => {
  try {
    const { firstName, lastName, middleName, email } = values;

    const body: SignUpBody = {
      firstName,
      lastName,
      middleName,
      destination: email,
      role,
      preventEmail,
    };

    const response = await api.post("/authentication/sign-up", body);

    return response.data;
  } catch (error: unknown) {
    Sentry.captureException(error);

    if (isAxiosError(error)) {
      if (error.response?.status === 429) {
        throw new Error(`Too many requests. Please try again after 5 minutes.`);
      } else if (error.response?.data?.message) {
        throw new Error(`Sign in failed: ${error.response.data.message}`);
      } else {
        throw new Error(`Sign in failed: ${error.message}`);
      }
    } else if (error instanceof Error) {
      throw new Error(`Sign in failed: ${error.message}`);
    } else {
      throw new Error("Sign in failed for an unknown reason");
    }
  }
};
