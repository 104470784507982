import { useMediaQuery } from "react-responsive";

const mediaQueries = {
  wide: 1440,
  large: 1150,
  medium: 768,
  small: 375,
};

const useMobileMediaQuery = () =>
  useMediaQuery({ query: `(min-width: ${mediaQueries.small}px)` });

const useTabletMediaQuery = () =>
  useMediaQuery({ query: `(min-width: ${mediaQueries.medium}px)` });

const useDesktopMediaQuery = () =>
  useMediaQuery({ query: `(min-width: ${mediaQueries.large}px)` });

const useWideDesktopMediaQuery = () =>
  useMediaQuery({ query: `(min-width: ${mediaQueries.wide}px)` });

export {
  useMobileMediaQuery,
  useTabletMediaQuery,
  useDesktopMediaQuery,
  useWideDesktopMediaQuery,
};
