import Typography from "@/foundation/Typography/Typography";

export const OverviewBuyer = () => (
  <div className="sticky top-0 flex-shrink-0 w-full md:w-[300px] md:overflow-y-scroll pt-[48px] md:max-h-[calc(100vh-100px)]">
    <Typography
      type="h4"
      variant="h4"
      weight="bold"
      className="mb-4 text-brand-heavy-teal-100"
    >
      Overview
    </Typography>

    <ul>
      <li>
        <a href="#1-about-our-terms">
          <Typography
            type="h3"
            variant="base"
            weight="semi-bold"
            className="mb-[16px] text-brand-heavy-teal-50"
          >
            1. About our terms
          </Typography>
        </a>
      </li>
      <li>
        <a href="#2-about-us">
          <Typography
            type="h3"
            variant="base"
            weight="semi-bold"
            className="mb-[16px] text-brand-heavy-teal-50"
          >
            2. About us
          </Typography>
        </a>
      </li>
      <li>
        <a href="#3-using-the-propelr-service">
          <Typography
            type="h3"
            variant="base"
            weight="semi-bold"
            className="mb-[16px] text-brand-heavy-teal-50"
          >
            3. Using the Propelr Service
          </Typography>
        </a>
      </li>
      <li>
        <a href="#4-cooperation-with-solicitors">
          <Typography
            type="h3"
            variant="base"
            weight="semi-bold"
            className="mb-[16px] text-brand-heavy-teal-50"
          >
            4. Cooperation with Solicitors
          </Typography>
        </a>
      </li>
      <li>
        <a href="#5-registration-and-password-security">
          <Typography
            type="h3"
            variant="base"
            weight="semi-bold"
            className="mb-[16px] text-brand-heavy-teal-50"
          >
            5. Registration and password security
          </Typography>
        </a>
      </li>
      <li>
        <a href="#6-infringing-content">
          <Typography
            type="h3"
            variant="base"
            weight="semi-bold"
            className="mb-[16px] text-brand-heavy-teal-50"
          >
            6. Infringing content
          </Typography>
        </a>
      </li>
      <li>
        <a href="#7-your-privacy-and-personal-information">
          <Typography
            type="h3"
            variant="base"
            weight="semi-bold"
            className="mb-[16px] text-brand-heavy-teal-50"
          >
            7. Your privacy and personal information
          </Typography>
        </a>
      </li>
      <li>
        <a href="#8-ownership-use-and-intellectual-property-rights">
          <Typography
            type="h3"
            variant="base"
            weight="semi-bold"
            className="mb-[16px] text-brand-heavy-teal-50"
          >
            8. Ownership, use and intellectual property rights
          </Typography>
        </a>
      </li>
      <li>
        <a href="#9-submitting-information-to-the-propelr-service">
          <Typography
            type="h3"
            variant="base"
            weight="semi-bold"
            className="mb-[16px] text-brand-heavy-teal-50"
          >
            9. Submitting information to the Propelr Service
          </Typography>
        </a>
      </li>
      <li>
        <a href="#10-retention-of-property-information">
          <Typography
            type="h3"
            variant="base"
            weight="semi-bold"
            className="mb-[16px] text-brand-heavy-teal-50"
          >
            10. Retention of Property information
          </Typography>
        </a>
      </li>
      <li>
        <a href="#11-accuracy-of-information-and-availability-of-the-service">
          <Typography
            type="h3"
            variant="base"
            weight="semi-bold"
            className="mb-[16px] text-brand-heavy-teal-50"
          >
            11. Accuracy of information and availability of the service
          </Typography>
        </a>
      </li>
      <li>
        <a href="#12-hyperlinks-and-third-party-sites">
          <Typography
            type="h3"
            variant="base"
            weight="semi-bold"
            className="mb-[16px] text-brand-heavy-teal-50"
          >
            12. Hyperlinks and third party sites
          </Typography>
        </a>
      </li>
      <li>
        <a href="#13-our-responsibility-to-you">
          <Typography
            type="h3"
            variant="base"
            weight="semi-bold"
            className="mb-[16px] text-brand-heavy-teal-50"
          >
            13. Our responsibility to you
          </Typography>
        </a>
      </li>
      <li>
        <a href="#14-events-beyond-our-control">
          <Typography
            type="h3"
            variant="base"
            weight="semi-bold"
            className="mb-[16px] text-brand-heavy-teal-50"
          >
            14. Events beyond our control
          </Typography>
        </a>
      </li>
      <li>
        <a href="#15-no-third-party-rights">
          <Typography
            type="h3"
            variant="base"
            weight="semi-bold"
            className="mb-[16px] text-brand-heavy-teal-50"
          >
            15. No third party rights
          </Typography>
        </a>
      </li>
      <li>
        <a href="#16-variation">
          <Typography
            type="h3"
            variant="base"
            weight="semi-bold"
            className="mb-[16px] text-brand-heavy-teal-50"
          >
            16. Variation
          </Typography>
        </a>
      </li>
      <li>
        <a href="#17-complaints">
          <Typography
            type="h3"
            variant="base"
            weight="semi-bold"
            className="mb-[16px] text-brand-heavy-teal-50"
          >
            17. Complaints
          </Typography>
        </a>
      </li>
      <li>
        <a href="#18-registration-process-for-first-time-users">
          <Typography
            type="h3"
            variant="base"
            weight="semi-bold"
            className="mb-[16px] text-brand-heavy-teal-50"
          >
            18. Registration Process for first-time users
          </Typography>
        </a>
      </li>
      <li>
        <a href="#19-information-to-be-provided">
          <Typography
            type="h3"
            variant="base"
            weight="semi-bold"
            className="mb-[16px] text-brand-heavy-teal-50"
          >
            19. Information to be provided
          </Typography>
        </a>
      </li>
      <li>
        <a href="#20-payment-of-costs">
          <Typography
            type="h3"
            variant="base"
            weight="semi-bold"
            className="mb-[16px] text-brand-heavy-teal-50"
          >
            20. Payment of costs
          </Typography>
        </a>
      </li>
      <li>
        <a href="#21-registration-process-for-first-time-users">
          <Typography
            type="h3"
            variant="base"
            weight="semi-bold"
            className="mb-[16px] text-brand-heavy-teal-50"
          >
            21. Registration Process for first-time users
          </Typography>
        </a>
      </li>
    </ul>
  </div>
);
