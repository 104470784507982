import CaseStudyImage1 from "../../../assets/case-studies/case-studies-1.jpg";
import CaseStudyImage2 from "../../../assets/case-studies/case-studies-2.jpg";
import CaseStudyImage3 from "../../../assets/case-studies/case-studies-3.jpg";

interface CaseStudiesContentType {
  propertyType: string;
  title: string;
  subtitle: string;
  description: string;
  imageUrl: string;
  quoteHeadline: string;
  quoteBody: string;
}

export const CaseStudiesContent: CaseStudiesContentType[] = [
  {
    propertyType: "SOUTH LONDON FLAT",
    title: "86% faster",
    subtitle: "vs UK average",
    description:
      "The seller of this flat didn’t expect to need a quick exchange. But when their buyer needed to serve notice on their lease within four weeks or else the deal would’ve fallen through, they were grateful that they had gone with Propelr. Even with the challenges of having an inexperienced buyer needing a mortgage over the Christmas period, Propelr secured the exchange within a month.",
    imageUrl: CaseStudyImage1,
    quoteHeadline: "A record breaking exchange",
    quoteBody:
      "One of the quickest exchanges on a leasehold flat in my 22-year career with a first time buyer. From the day we agreed the sale to exchange of contracts only took 26 days including the Christmas period! – South London Estate Agent",
  },
  {
    propertyType: "NORFOLK FARMHOUSE",
    title: "8 days",
    subtitle: "offer to exchange",
    description:
      "The seller of a highly desirable but unusual ex-farmhouse in Norfolk wasted four months under offer last year. Unreliable buyers, problems with lenders and several fall throughs left her with a hefty legal bill and still no closer to a sale of an off-the-grid unfurnished former farmhouse. Deciding she needed more control, she instructed Propelr - who got the property exchanged within days.",
    imageUrl: CaseStudyImage3,
    quoteHeadline: "Propelr is a no brainer",
    quoteBody:
      "From the moment we instructed Propelr to exchange of contracts we couldn’t believe how seamless the process was. – Norfolk Farmhouse Seller",
  },
  {
    propertyType: "London Flat With Major Works",
    title: "76% faster",
    subtitle: "vs UK average",
    description:
      "This flat had a large Section 20 Major Works bill attached that would have scared many potential buyers off. With a tight deadline to sell the property, the sellers turned to Propelr. Thanks to Propelr, all the uncertainties were resolved at the outset. With a complete understanding of the property, the buyer was able to have a detailed discussion and negotiation before making a decision to place an offer.",
    imageUrl: CaseStudyImage2,
    quoteHeadline: "Propelr got us the exchange",
    quoteBody:
      "Overall process was smooth and efficient, and got us the timely exchange that we wanted. Propelr and the solicitors were professional throughout. No complaints and very happy with the service! – London Flat Seller",
  },
];
