import { useEffect, useState } from "react";
import { format } from "date-fns";
import {
  PurchaseIntent,
  PurchaseIntentSignificantDates,
} from "../../pages/TransactionDetails/types";
import { Table, TableProps } from "../Table/Table";
import { Card } from "@/components/Card/Card";
import { SignificantDatesEditModal } from "./SignificantDatesEditModal";

interface SignificantDatesCardProps {
  id: string;
  significantDates: PurchaseIntentSignificantDates;
  onSiginificantDatesUpdated: (updatedPurchaseIntent: PurchaseIntent) => void;
}

const getDisplayDate = (date: string | undefined) => {
  return date ? format(new Date(date), "dd/MM/yyyy") : undefined;
};

export const SignificantDatesCard = ({
  id,
  significantDates,
  onSiginificantDatesUpdated,
}: SignificantDatesCardProps) => {
  const [significantDatesDisplay, setSignificantDatesDisplay] = useState<
    TableProps[]
  >([]);
  const [editSignificantDatesModalOpen, setEditSignificantDatesModalOpen] =
    useState(false);

  useEffect(() => {
    setSignificantDatesDisplay([
      {
        label: "Sale Agreed",
        value: getDisplayDate(significantDates?.sale_agreed),
      },
      {
        label: "Exchanged",
        value: getDisplayDate(significantDates?.exchanged),
      },
      {
        label: "Completed",
        value: getDisplayDate(significantDates?.completed),
      },
      {
        label: "Aborted",
        value: getDisplayDate(significantDates?.aborted),
      },
    ]);
  }, [significantDates]);

  return (
    <>
      <Card
        title="Significant Dates"
        titleBackgroundColor="brand-heavy-warmth-25"
        titleColor="brand-heavy-teal-100"
        titleRightContent={
          <button
            className="text-brand-heavy-teal-100 underline"
            onClick={() => setEditSignificantDatesModalOpen(true)}
          >
            Edit
          </button>
        }
      >
        <>
          {significantDatesDisplay.map(({ value, label }) => (
            <Table
              key={`purchase-intent-${id}-${label}`}
              value={value}
              label={label}
            />
          ))}
        </>
      </Card>
      {editSignificantDatesModalOpen && (
        <SignificantDatesEditModal
          purchaseIntentId={id}
          significantDates={significantDates}
          onCancel={() => setEditSignificantDatesModalOpen(false)}
          onSaved={(updatedPurchaseIntent) => {
            onSiginificantDatesUpdated(updatedPurchaseIntent);
            setEditSignificantDatesModalOpen(false);
          }}
        />
      )}
    </>
  );
};
