import { AddressInputs } from "./AddressInputs";

export const SellerPropertyAddress = () => (
  <AddressInputs
    addressTitle="Sale property address"
    addressDescription="Please check carefully that this is your sale property address and amend
        it if it's not. Errors at this stage can result in delays and extra
        expense later on."
    cardAddressTitle="Sale property address"
    namespace="propertyAddress"
    showUseCorrespondenceAddress
  />
);
