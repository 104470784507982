import Icons from "../../foundation/Icons/Icons";
import Typography from "../../foundation/Typography/Typography";

import { TextInputIconProps } from "../../foundation/Icons/Icons";

export interface WelcomeCardProps {
  description?: string;
  iconName?: TextInputIconProps;
  title: string;
  customClass?: string;
}

export const WelcomeCard = ({
  description,
  iconName,
  title,
  customClass,
}: WelcomeCardProps) => (
  <>
    <div
      className={`flex flex-col justify-between relative bg-ui-white-100 shadow-questionType rounded-xl overflow-hidden ${customClass} border border-ui-black-10 mb-[24px]`}
    >
      <div>
        <div className="flex justify-between items-center bg-brand-heavy-warmth-50 rounded-t-xl px-[32px] py-[16px]">
          <Typography
            type="h3"
            variant="xl"
            weight="bold"
            className="text-brand-heavy-teal-100 text-pretty"
          >
            {title}
          </Typography>
        </div>

        <div className="flex items-start">
          {iconName && (
            <div className="relative ml-[24px] mt-[24px]">
              <div className="relative flex items-center justify-center">
                <Icons iconName={iconName} size="60" colour="highlight" />
              </div>

              <div className="absolute right-[0px] top-[0px]">
                <Icons iconName="GreenTick" size="30" colour="highlight" />
              </div>
            </div>
          )}

          <div className="py-[24px] px-[24px]">
            <Typography
              type="p"
              variant="base"
              weight="regular"
              className="font-normal"
            >
              {description}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  </>
);
