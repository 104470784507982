import { Field, FieldProps, FormikProps } from "formik";

import ErrorText from "@/foundation/Typography/ErrorText";
import { relationshipOptions } from "../Fields";
import { GiftorSchemaState } from "../../../../schema";
import { Price } from "../Price/Price";
import { Select } from "@/components/Input/Select";
import { FieldSet } from "../../FieldSet/FieldSet";
import { Input } from "../../../Formik/Input/Input";

interface GiftorFormProps {
  formik: FormikProps<GiftorSchemaState>;
}

export const GiftorForm = ({ formik }: GiftorFormProps) => {
  const { errors, touched } = formik;

  return (
    <>
      {/* Amount */}

      <Price priceTitle="Amount" typeOfPrice="amount" placeholder="Amount" />

      {/* Legal Name */}

      <FieldSet legend="Giftor's legal name">
        <div className="flex flex-col md:flex-row md:flex-wrap gap-[16px] w-[100%]">
          <div className="w-[100%] md:max-w-[320px]">
            <Input fieldName="firstName" label="First name" />
          </div>
          <div className="w-[100%] md:max-w-[320px]">
            <Input fieldName="middleName" label="Middle name (optional)" />
          </div>
          <div className="w-[100%] md:max-w-[320px]">
            <Input fieldName="lastName" label="Last name" />
          </div>
        </div>
      </FieldSet>

      {/* Contact Details (Email & Phone) */}

      <FieldSet legend="Giftor's contact details">
        <div className="flex flex-col md:flex-row md:flex-wrap gap-[16px] w-full">
          <div>
            <Input fieldName="mobileNumber" label="Mobile Number" />
          </div>
          <div>
            <Input fieldName="email" label="Email" />
          </div>
        </div>
      </FieldSet>

      {/* Relationship */}

      <FieldSet legend="Buyer's relationship to giftor">
        <Field name="relationship">
          {({ field }: FieldProps) => {
            return (
              <Select
                {...field}
                name="relationship"
                id="relationship"
                options={relationshipOptions}
                value={field.value || ""}
                variant={
                  formik.errors.relationship && formik.touched.relationship
                    ? "error"
                    : "enabled"
                }
              />
            );
          }}
        </Field>
        <div className="h-[16px]">
          {errors.relationship && touched.relationship && (
            <ErrorText>Required field</ErrorText>
          )}
        </div>
      </FieldSet>
    </>
  );
};
