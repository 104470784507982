import StepperGroup from "@/components/Stepper/StepperGroup";
import { Background } from "../../Background/Background";
import { Container } from "../../Container/Container";
import { Header } from "../../Header/Header";
import { Nav } from "../../Nav/Nav";
import { StepperProps } from "@/components/Stepper/Stepper";

interface PageTemplateProps {
  title: string;
  stepperOptions: StepperProps[];
  children: React.ReactNode;
}

export const PageTemplate = ({
  title,
  stepperOptions,
  children,
}: PageTemplateProps) => (
  <Background className="flex flex-col">
    <Nav />
    <Container>
      <div className="flex flex-col px-[24px] lg:px-0">
        <Header title={title}>
          <div className="md:max-w-[916px] lg:max-w-[1116px]">
            <StepperGroup options={stepperOptions} />
          </div>
        </Header>
        {children}
      </div>
    </Container>
  </Background>
);
