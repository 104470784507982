import { useEffect, useState } from "react";

import { Card } from "@/components/Card/Card";
import { AdminUser } from "../../pages/UserDetails/types";
import { Table, TableProps } from "../Table/Table";

export interface UserInformationProps {
  user: AdminUser;
  title: string;
  preContent?: React.ReactNode;
  postContent?: React.ReactNode;
}

export const UserInformation = ({
  user,
  title,
  preContent,
  postContent,
}: UserInformationProps) => {
  const [userInformation, setUserInformation] = useState<TableProps[]>([]);

  useEffect(() => {
    setUserInformation([
      { label: "First Name", value: user.first_name },
      { label: "Middle Name", value: user?.middle_name || "" },
      { label: "Last Name", value: user.last_name },
      { label: "Email", value: user.email },
      {
        label: "Phone Number",
        value: user.phone_number || user.profile?.phone_number || "",
      },
    ]);
  }, [user]);

  return (
    <Card
      title={title}
      titleBackgroundColor="brand-heavy-warmth-25"
      titleColor="brand-heavy-teal-100"
    >
      <>
        {preContent}
        {userInformation.map(({ label, value }) => (
          <Table key={`${label}-${value}`} label={label} value={value} />
        ))}
        {postContent}
      </>
    </Card>
  );
};
