import { create } from "zustand";
import { SignUpType } from "@schema";
import { Role } from "@shared/types";

export const initialLoginState: SignUpType = {
  firstName: "",
  middleName: "",
  lastName: "",
  email: "",
};

interface LoginStoreState extends SignUpType {
  isRegistered: boolean;
  role: Role;
  updateField: (field: keyof SignUpType, value: string) => void;
  setIsRegistered: (isRegistered: boolean) => void;
  setRole: (role: Role) => void;
}

export const useLoginStore = create<LoginStoreState>((set) => ({
  ...initialLoginState,
  isRegistered: false,
  role: window.location.pathname.includes("sell") ? "sell" : "buy",

  updateField: (field, value) => set((state) => ({ ...state, [field]: value })),
  setIsRegistered: (isRegistered) => set({ isRegistered }),
  setRole: (role) => set({ role }),
}));
