import * as Sentry from "@sentry/react";
import { Formik, FormikHelpers } from "formik";
import { withZodSchema } from "formik-validator-zod";

import { ApiController } from "../../../service/ApiController";
import { Button } from "@/components/Button/Button";
import { Input } from "../../Formik/Input/Input";
import { initialLoginState } from "../../../store/Login/login";

import { SignUpSchema, SignUpType } from "@schema";
import { useRecaptcha } from "@utils";

import Logo from "../../../assets/logos/small-logo.svg";

interface SignUpFormProps {
  state: SignUpType;
  onSubmit: (values: SignUpType) => void;
}

export const SignUpForm = ({ onSubmit }: SignUpFormProps) => {
  const recaptchaContext = useRecaptcha();

  const handleSubmit = async (
    values: SignUpType,
    formikActions: FormikHelpers<SignUpType>,
  ) => {
    try {
      if (!recaptchaContext?.executeRecaptcha) {
        throw new Error("reCAPTCHA context is not available");
      }

      const token = await recaptchaContext.executeRecaptcha("login");
      if (!token) {
        Sentry.captureException(new Error("reCAPTCHA token is not available"));
        return;
      }

      await ApiController.verifyRecaptcha(token);
      onSubmit(values);
      formikActions.resetForm({
        values: { firstName: "", middleName: "", lastName: "", email: "" },
      });
    } catch (error: unknown) {
      if (error instanceof Error) {
        Sentry.captureException(error);
        console.error(error.message);
      } else {
        console.error("An unexpected reCAPTCHA error occurred", error);
      }
    }
  };

  return (
    <Formik
      initialValues={{ ...initialLoginState }}
      onSubmit={handleSubmit}
      validate={withZodSchema(SignUpSchema)}
      validateOnMount
    >
      {(formik) => (
        <form
          onSubmit={formik.handleSubmit}
          data-testid="signup-form"
          className="flex flex-col flex-start w-[100%] max-w-[400px] pt-[40px]"
        >
          <div className="mb-[8px]">
            <Input fieldName="firstName" label="First name" />
          </div>

          <div className="mb-[8px]">
            <Input fieldName="middleName" label="Middle name (optional)" />
          </div>

          <div className="mb-[20px]">
            <Input fieldName="lastName" label="Last name" />
          </div>

          <div className="mb-[36px] text-center">
            <hr className="relative top-[9px] text-brand-heavy-warmth-100" />
            <div className="bg-brand-medium-sand-50 w-[50px] mx-auto relative z-30 flex items-center justify-center">
              <img src={Logo} width={16} />
            </div>
          </div>

          <div className="mb-[16px] ">
            <Input fieldName="email" label="Email address" />
          </div>

          <Button
            variant="primary"
            size="full"
            type="submit"
            disabled={!formik.isValid}
            className="text-brand-sand-100 mb-[24px]"
          >
            Send me a magic link
          </Button>
        </form>
      )}
    </Formik>
  );
};
