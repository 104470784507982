import SearchesImage from "../../assets/image-searches.svg";
import ContactOfSellerImage from "../../assets/image-contact-of-seller.svg";

export const feesCards = [
  {
    id: 1,
    name: "Search pack",
    description:
      "A search pack is a bundle of documents that provides detailed information on your property. Typically paid and raised by you but with Propelr already done ahead of time and free of charge to you.",
    line_1: "Essential to the legal process and ordered ahead of time.",
    line_2:
      "Providing information about local planning, roads, railway, drainage, infrastructure and environmental matters.",
    line_3:
      "Avoid potential costly mistakes by uncovering potential issues in advance.",
    price: "£400",
    image: SearchesImage,
  },
  {
    id: 2,
    name: "Core legal fees",
    description:
      "These are the standard legal fees that you would have needed to pay as part of the purchase.",
    line_1: "Quality, independent legal advice by award-winning solicitors.",
    line_2:
      "Your solicitor already up to speed with your transaction with legals completed ahead of time.",
    line_3: "Use money saved for something nice for the home.",
    price: "£2,250",
    image: ContactOfSellerImage,
  },
];
