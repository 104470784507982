import { Field, FieldProps, FormikProps } from "formik";

import ErrorText from "@/foundation/Typography/ErrorText";
import {
  mortgageProvidersOptions,
  topFiveMortgageProvidersOptions,
} from "../Fields";
import { BuyerPropertyDetailsState } from "../../../../schema";
import { Select } from "@/components/Input/Select";
import { FieldSet } from "../../FieldSet/FieldSet";

interface RemortgageProps {
  formik: FormikProps<BuyerPropertyDetailsState>;
}

export const Remortgage = ({ formik }: RemortgageProps) => {
  const getMortgageProvidersOptions = () => {
    return [
      { label: "Select your option", value: "", disabled: true },
      {
        label: "Not Sure Yet",
        value: "Not Sure Yet",
      },
      {
        label: "───────────────────────────",
        value: "lineBreak",
        isLineBreak: true,
      },
      ...topFiveMortgageProvidersOptions.map((provider) => ({
        label: provider,
        value: provider,
      })),
      {
        label: "───────────────────────────",
        value: "lineBreak2",
        isLineBreak: true,
      },
      ...mortgageProvidersOptions
        .filter((provider) => {
          return (
            !topFiveMortgageProvidersOptions.includes(provider) &&
            provider !== "Not Sure Yet"
          );
        })
        .map((provider) => ({
          label: provider,
          value: provider,
        })),
    ];
  };

  return (
    <FieldSet
      legend="Who is providing the mortgage?"
      description="If you are unsure who your mortgage provider will be, please select
        'not sure' from the options below."
    >
      <Field name="mortgageProvider">
        {({ field }: FieldProps) => {
          return (
            <Select
              {...field}
              name="mortgageProvider"
              id="mortgageProvider"
              options={getMortgageProvidersOptions()}
              value={field.value || ""}
              variant={
                formik.errors.mortgageProvider &&
                formik.touched.mortgageProvider
                  ? "error"
                  : "enabled"
              }
            />
          );
        }}
      </Field>
      <div className="h-[16px]">
        {formik.errors.mortgageProvider && formik.touched.mortgageProvider && (
          <ErrorText>{formik.errors.mortgageProvider}</ErrorText>
        )}
      </div>
    </FieldSet>
  );
};
