import { Address } from "../frontend/src/admin/pages/UserDetails/types";
import {
  AdditionalPersonValues,
  FormattedFields,
} from "../frontend/src/components/AdditionalPerson/AdditionalPerson.types";
import { Roles } from "./types";

export const formatCurrency = (
  amount: number,
  isPounds = false,
  excludeZero = false,
): string => {
  const a = isPounds ? amount : amount / 100;
  return new Intl.NumberFormat("en-GB", {
    style: "currency",
    currency: "GBP",
    minimumFractionDigits: excludeZero ? 0 : 2,
  }).format(a);
};

export const capitalizeFirstLetter = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1);

export const toTitleCase = (str: string) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const formatPostcode = (postcode: string) => {
  // Convert to uppercase and remove whitespace
  postcode = postcode.toUpperCase().replace(/\s/g, "");

  // If the postcode length is longer than 3, insert space
  if (postcode.length > 3) {
    postcode = `${postcode.substring(
      0,
      postcode.length - 3,
    )} ${postcode.substring(postcode.length - 3)}`;
  }

  return postcode;
};

export const formatCamelCaseString = (inputString: string) =>
  inputString
    .replace(/([a-z])([A-Z])/g, "$1 $2")
    .replace(/_/g, " ")
    .split(" ")
    .map(capitalizeFirstLetter)
    .join(" ");

const getQuestionAndAnswer = (values: Partial<AdditionalPersonValues>) => {
  return Object.entries(values).map(([key, value]) => {
    const question = formatCamelCaseString(key);
    const answer = String(value);
    return { question, answer };
  });
};

export const formatFields = (
  values: Partial<AdditionalPersonValues>,
): FormattedFields => {
  const fields = getQuestionAndAnswer(values);

  const initialAccumulator: FormattedFields = {
    legalName: "",
    amount: values.amount ? values.amount : "0",
    relationship: values.relationship || "Other",
    otherFields: [],
  };

  return fields.reduce((acc, field) => {
    const { question, answer } = field;
    const lowerCaseQuestion = question.toLowerCase();

    if (lowerCaseQuestion === "amount") {
      acc.amount = formatCurrency(parseFloat(answer.replace(/,/g, "") + "00"));
    } else if (
      ["email", "first name", "middle name", "last name"].includes(
        lowerCaseQuestion,
      )
    ) {
      if (lowerCaseQuestion === "email") {
        acc.otherFields.push({ question: "Email", answer });
      } else {
        acc.legalName += acc.legalName
          ? ` ${capitalizeFirstLetter(answer)}`
          : capitalizeFirstLetter(answer);
      }
    } else {
      acc.otherFields.push({
        question: capitalizeFirstLetter(question),
        answer: capitalizeFirstLetter(answer),
      });
    }
    return acc;
  }, initialAccumulator);
};

export const formatDate = (dateString: string | Date) => {
  if (!dateString) {
    dateString = new Date().toISOString();
  }
  const date = new Date(dateString);
  const formattedDate = date.toLocaleDateString("en-GB", {
    month: "long",
  });

  const day = date.getDate() + 1;
  const suffix = getDaySuffix(day);
  return `${day}${suffix} ${formattedDate}`;
};

export const formatLegalStartDate = (dateString: string | Date) => {
  if (!dateString) {
    dateString = new Date().toISOString();
  }
  const date = new Date(dateString);
  const formattedDate = date.toLocaleDateString("en-GB", {
    month: "long",
    timeZone: "UTC",
  });

  const day = date.getUTCDate();
  const suffix = getDaySuffix(day);
  return `${day}${suffix} ${formattedDate}`;
};

export const getDaySuffix = (day: number) => {
  if (day >= 11 && day <= 13) {
    return "th";
  }
  switch (day % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};

export const isSeller = (role: Roles) => role === Roles.Seller;
export const isBuyer = (role: Roles) => role === Roles.Buyer;

export const getAddressAsString = (address: Address, isHeadline = false) => {
  return isHeadline
    ? `${address.line_1}, ${address.line_2 ? `${address.line_2}, ` : ""}${address.postcode}`
    : `${address.line_1}${address.line_2 ? `, ${address.line_2}` : ""}${address.line_3 ? `, ${address.line_3}` : ""}, ${address.post_town}, ${address.county ? `${address.county}, ` : ""}${address.postcode}`;
};

export const formatDateWithNumbers = (dateString: string) => {
  if (!dateString) {
    dateString = new Date().toISOString();
  }
  const date = new Date(dateString);
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};
