import { useEffect, useState } from "react";

import Typography from "@/foundation/Typography/Typography";
import SolicitorCard from "@/components/SolicitorCard/SolicitorCard";
import { solicitorCards, successCards } from "./nextSteps";
import { FeatureCard } from "@/components/FeatureCard/FeatureCard";
import { ApiController } from "../../service/ApiController";
import { usePurchaseIntentFormStore } from "../../store/PurchaseIntentForm/purchaseIntentForm";
import { useTransactionFormStore } from "../../store/TransactionForm/transactionForm";
import { formatLegalStartDate } from "@shared/utils";
import {
  transformTransactionPropertyState,
  transformTransactionSellerState,
} from "@transformers";

export const SuccessContainer = () => {
  const { purchaseIntent } = usePurchaseIntentFormStore();
  const { transaction, updateTransaction } = useTransactionFormStore();
  const [legalStartDate, setLegalStartDate] = useState<string>("");

  const findTransaction = async () => {
    try {
      const savedTransaction =
        await ApiController.findTransactionByPurchaseIntentId(
          purchaseIntent._id,
        );
      const primarySeller = transformTransactionSellerState(
        savedTransaction.transaction.sellers,
      );
      const transformedProperty = transformTransactionPropertyState(
        savedTransaction.transaction,
      );
      updateTransaction({
        ...savedTransaction.transaction,
        primarySeller: primarySeller,
        property: transformedProperty,
      });
      if (transaction.date_solicitor_groups_assigned?.date_legal_started) {
        setLegalStartDate(
          formatLegalStartDate(
            transaction.date_solicitor_groups_assigned.date_legal_started,
          ),
        );
      }
    } catch (error) {
      throw new Error(
        `Failed to find transaction by purchase intent id: ${error}`,
      );
    }
  };

  useEffect(() => {
    findTransaction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex flex-col items-start">
      <Typography
        type="h2"
        variant="h4"
        weight="bold"
        className="mb-[32px] pt-[48px] text-brand-heavy-teal-100"
      >
        You will receive a confirmation shortly. Please read it as it contains
        important information and next steps.
      </Typography>

      <div className="flex flex-col md:flex-row md:gap-[48px]">
        <div className="flex flex-col">
          <Typography
            type="h2"
            variant="h4"
            weight="bold"
            className="mb-[16px] text-brand-heavy-teal-100"
          >
            Next steps for us:
          </Typography>

          {solicitorCards.map((card) => {
            card.startDate = legalStartDate;
            return <SolicitorCard card={card} key={card.id} />;
          })}
        </div>

        <div className="border-l border-brand-heavy-teal-25 flex-shrink-0" />

        <div className="flex flex-col">
          <div className="flex items-center mb-[8px]">
            <Typography
              type="h2"
              variant="h4"
              weight="bold"
              className="mb-[8px] text-brand-heavy-teal-100"
            >
              Next steps for you:
            </Typography>
          </div>

          <div className="flex flex-col">
            {successCards.map((card) => (
              <FeatureCard
                key={card.id}
                description={card.description}
                iconName="ID"
                title={card.name}
                customClass="max-w-[380px]"
                cardColour="bg-brand-warmth-100"
                orientation="portrait"
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
