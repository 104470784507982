import Typography from "@/foundation/Typography/Typography";

interface UspCardProps {
  title: string;
  description: string;
  icon: string;
}

export const UspCard = ({ title, description, icon }: UspCardProps) => (
  <div className="bg-brand-heavy-teal-100 rounded-[16px] mb-[16px] mt-[50px] flex flex-col justify-between mx-auto">
    <div className="px-[32px] py-[24px]">
      <img
        width={150}
        src={icon}
        alt="icon"
        className="-mt-[75px] mx-auto mb-[24px]"
      />

      <Typography
        className="font-display text-ui-white-100 text-center"
        type="h2"
        variant="h2"
        weight="semi-bold"
      >
        {title}
      </Typography>
    </div>

    <div className="bg-brand-teal-100 p-[24px] rounded-b-[16px]">
      <Typography
        className="font-display text-brand-electric-100 text-center mb-[8px]"
        type="h3"
        variant="lg"
        weight="semi-bold"
      >
        Did you know?
      </Typography>

      <Typography
        className=" text-brand-heavy-teal-25 text-center"
        type="p"
        variant="base"
        weight="regular"
      >
        {description}
      </Typography>
    </div>
  </div>
);
