import { ComponentProps, useRef, useState } from "react";
import { statusConfig } from "./statusConfig";

export type TextAreaProps = ComponentProps<"textarea"> & {
  variant?: "enabled" | "disabled" | "success" | "error";
  name: string;
  label?: string;
  className?: string;
};

export const TextArea = ({
  variant = "enabled",
  name,
  label = "Text Area Label",
  className,
  ...props
}: TextAreaProps) => {
  const [isOnFocus, setIsOnFocus] = useState(false);
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const isInputEmpty = props.value === "";

  const textareaClasses = `h-[120px] w-full pt-[18px] pb-[4px] pl-[24px] pr-[14px] shadow-questionType rounded-lg border-2 hover:border-ui-black-25 flex flex-col text-base text-ui-black-75 leading-none focus:border-brand-heavy-teal-100 focus:outline-none focus:shadow-outline
      `;

  const statusClasses = statusConfig[variant] || "";

  const handleFocus = () => setIsOnFocus(true);
  const handleBlur = () => setIsOnFocus(false);

  return (
    <div className="relative w-full md:max-w-[500px]">
      <label
        className={`absolute top-[28px]
        px-[14px] pb-[8px] leading-default duration-300 transition-transform transform cursor-text ${
          !isInputEmpty || isOnFocus
            ? "hidden"
            : "text-ui-black-75 -translate-y-1/2"
        } `}
        htmlFor={name}
        onClick={() => {
          if (inputRef.current) {
            inputRef.current.focus();
          }
        }}
      >
        {label}
      </label>
      <textarea
        {...props}
        disabled={variant === "disabled"}
        name={name}
        className={`${textareaClasses} ${statusClasses} ${className}`}
        ref={inputRef}
        onFocus={handleFocus}
        onBlur={handleBlur}
      ></textarea>
    </div>
  );
};
