import { useEffect, useState } from "react";

import { Card } from "@/components/Card/Card";
import { Table, TableProps } from "../Table/Table";
import { EstateAgent } from "../../../types";

interface EstateAgentValuersBlockProps {
  valuers: EstateAgent["valuers"];
}

type TableAndCopyOptions = TableProps & { includeCopyButton?: boolean };

export const EstateAgentValuersBlock = ({
  valuers,
}: EstateAgentValuersBlockProps) => {
  const [fields, setFields] = useState<TableAndCopyOptions[]>([]);

  const primaryValuer = valuers.find((valuer) => valuer.primary_valuer);
  const secondaryValuers = valuers.filter((valuer) => !valuer.primary_valuer);

  useEffect(() => {
    const primaryValuerFields = [
      {
        label: "Primary Valuer Name",
        value: `${primaryValuer?.first_name} ${primaryValuer?.last_name}`,
      },
      {
        label: "Primary Valuer Email",
        value: primaryValuer?.email,
      },
      {
        label: "Primary Valuer Phone Number",
        value: primaryValuer?.phone_number,
      },
    ];

    const secondaryValuerFields = secondaryValuers.flatMap((valuer, index) => [
      {
        label: `Secondary Valuer ${index + 1} Name`,
        value: `${valuer.first_name} ${valuer.last_name}`,
      },
      {
        label: `Secondary Valuer ${index + 1} Email`,
        value: valuer.email,
      },
      {
        label: `Secondary Valuer ${index + 1} Phone Number`,
        value: valuer.phone_number,
      },
    ]);

    setFields([...primaryValuerFields, ...secondaryValuerFields]);
  }, [valuers, primaryValuer, secondaryValuers]);

  return (
    <>
      <Card
        title="Valuers"
        titleBackgroundColor="brand-heavy-warmth-25"
        titleColor="brand-heavy-teal-100"
      >
        <>
          {fields.map(({ value, label, includeCopyButton }, index) => (
            <div key={label}>
              <Table
                key={value as string}
                label={label}
                value={value}
                includeCopyButton={includeCopyButton}
              />
              {secondaryValuers.length
                ? index === 2 && (
                    <hr className="text-brand-heavy-warmth-75 my-[20px]" />
                  )
                : null}
            </div>
          ))}
        </>
      </Card>
    </>
  );
};
