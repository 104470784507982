import { Card } from "@/components/Card/Card";
import {
  AdminTransaction,
  LawyerGroups,
} from "../../pages/TransactionDetails/types";
import { Table, TableProps } from "../Table/Table";
import { useEffect, useState } from "react";
import { getAddressAsString } from "@shared/utils";
import { AssignSolicitorsBlock } from "../AssignSolicitors/AssignSolicitorsBlock";
import Typography from "@/foundation/Typography/Typography";
import { isTransactionPaid } from "../../../utils/utils";
import Icons from "@/foundation/Icons/Icons";
import { Button } from "@/components/Button/Button";
import { getLawyerGroupName } from "../AssignSolicitors/utils";
import { LawyerGroup } from "../../../types";

interface SolicitorTabProps {
  lawyerGroup?: LawyerGroups;
  transaction: AdminTransaction;
  setTransaction: (transaction: AdminTransaction) => void;
}

const SolicitorDetails = ({
  lawyerGroup,
  keyPrefix,
}: {
  lawyerGroup?: LawyerGroup;
  keyPrefix: string;
}) => {
  const [fields, setFields] = useState<TableProps[]>([]);

  useEffect(() => {
    setFields([
      {
        label: "Group Name",
        value: (lawyerGroup && getLawyerGroupName(lawyerGroup)) || "",
      },
      {
        label: "Law Firm Name",
        value: lawyerGroup?.law_firm.name || "",
      },
      {
        label: "Law Firm Address",
        value:
          (lawyerGroup && getAddressAsString(lawyerGroup?.law_firm.address)) ||
          "",
      },
      {
        label: "Law Firm Email",
        value: lawyerGroup?.law_firm.email || "",
      },
      {
        label: "Law Firm Phone Number",
        value: lawyerGroup?.law_firm.phone_number || "",
      },
      {
        label: "Solicitor Name",
        value: lawyerGroup?.solicitor
          ? `${lawyerGroup.solicitor.first_name} ${lawyerGroup.solicitor.last_name}`
          : "",
      },
      {
        label: "Solicitor Email",
        value: lawyerGroup?.solicitor.email || "",
      },
      {
        label: "Solicitor Phone Number",
        value: lawyerGroup?.solicitor.lawyer_profile?.phone_number || "",
      },
      {
        label: "Legal Assistant Names",
        value:
          lawyerGroup?.legal_assistants
            ?.map((x) => `${x.first_name} ${x.last_name}`)
            .join(", ") || "",
      },
      {
        label: "Legal Assistant Emails",
        value:
          lawyerGroup?.legal_assistants?.map((x) => x.email).join(", ") || "",
      },
      {
        label: "Legal Assistant Phone Number",
        value:
          lawyerGroup?.legal_assistants
            ?.map((x) => x.lawyer_profile?.phone_number)
            .join(", ") || "",
      },
    ]);
  }, [lawyerGroup]);

  return (
    <>
      {fields.map(({ value, label }) => (
        <Table key={`${keyPrefix}-${label}`} label={label} value={value} />
      ))}
    </>
  );
};

export const SolicitorTab = ({
  lawyerGroup,
  transaction,
  setTransaction,
}: SolicitorTabProps) => {
  const [transactionPaid, setTransactionPaid] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sendSolicitorEmail, setSendSolicitorEmail] = useState(false);

  useEffect(() => {
    async function getTransactionPaid() {
      const isPaid = await isTransactionPaid(transaction._id);
      setTransactionPaid(!!isPaid);
    }

    getTransactionPaid();
  }, [transaction._id]);

  return (
    <div className="flex flex-col space-y-5">
      {!lawyerGroup && (
        <Card>
          <div className="flex flex-col items-center">
            <Typography variant="lg" weight="semi-bold" className="mb-[16px]">
              There are no solicitors assigned to this transaction.
            </Typography>

            {!transactionPaid && (
              <div className="flex justify-center items-center mb-[16px] p-[16px] bg-ui-warning-100 rounded-xl">
                <div className="shrink-0 mr-[12px]">
                  <Icons iconName="Warning" size="24" />
                </div>
                <Typography variant="sm" weight="semi-bold">
                  Please note, this transaction is unpaid
                </Typography>
              </div>
            )}

            <div className="flex gap-[16px]">
              <Button
                type="button"
                onClick={() => {
                  setSendSolicitorEmail(true);
                  setIsModalOpen(true);
                }}
                variant="primary"
                size="full"
              >
                Assign solicitors and send emails
              </Button>

              <Button
                type="button"
                onClick={() => {
                  setSendSolicitorEmail(false);
                  setIsModalOpen(true);
                }}
                variant="primary"
                size="full"
              >
                Assign solicitors with no emails
              </Button>
            </div>
            {isModalOpen && (
              <AssignSolicitorsBlock
                transaction={transaction}
                setTransaction={(transaction) => {
                  setTransaction(transaction);
                  setIsModalOpen(false);
                }}
                onCloseModal={() => setIsModalOpen(false)}
                sendSolicitorEmail={sendSolicitorEmail}
              />
            )}
          </div>
        </Card>
      )}

      {lawyerGroup?.seller && (
        <Card
          title="Seller Solicitor"
          titleBackgroundColor="brand-heavy-warmth-25"
          titleColor="brand-heavy-teal-100"
        >
          <SolicitorDetails
            lawyerGroup={transaction.lawyer_group?.seller}
            keyPrefix="seller-solicitor"
          />
        </Card>
      )}

      {lawyerGroup?.buyer && (
        <Card
          title="Buyer Solicitor"
          titleBackgroundColor="brand-heavy-warmth-25"
          titleColor="brand-heavy-teal-100"
        >
          <SolicitorDetails
            lawyerGroup={transaction.lawyer_group?.buyer}
            keyPrefix="buyer-solicitor"
          />
        </Card>
      )}
    </div>
  );
};
