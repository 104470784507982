import { create } from "zustand";

export type State = {
  isFormikReady: boolean;
  setIsFormikReady: (isFormikReady: boolean) => void;
  submitForm: boolean;
  setSubmitForm: (submitForm: boolean) => void;
  submitModalForm: boolean;
  setSubmitModalForm: (submitModalForm: boolean) => void;
};

export const useFormikStore = create<State>((set) => ({
  isFormikReady: false,
  setIsFormikReady: (isFormikReady) => set({ isFormikReady }),
  submitForm: false,
  setSubmitForm: (submitForm) => set({ submitForm }),
  submitModalForm: false,
  setSubmitModalForm: (submitModalForm) => set({ submitModalForm }),
}));
