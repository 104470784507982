import { useEffect, useState } from "react";
import { Field, FieldProps, Form, Formik } from "formik";
import { withZodSchema } from "formik-validator-zod";
import { z } from "zod";

import Typography from "@/foundation/Typography/Typography";
import { Modal } from "../../../components/Modal/Modal";
import { AdminTransaction } from "../../pages/TransactionDetails/types";
import { ApiController } from "../../../service/ApiController";
import { ConfirmCard } from "../AssignSolicitors/ConfirmCard";
import ErrorText from "@/foundation/Typography/ErrorText";
import { Select } from "@/components/Input/Select";
import Icons from "@/foundation/Icons/Icons";
import { EstateAgent } from "../../../types";
interface AssignEstateAgentBlockProps {
  transactionId: string;
  estateAgentId?: string;
  setTransaction: (transaction: AdminTransaction) => void;
  onCloseModal: () => void;
}

export const AssignEstateAgentBlock = ({
  transactionId,
  estateAgentId,
  setTransaction,
  onCloseModal,
}: AssignEstateAgentBlockProps) => {
  const [displayConfirmCard, setDisplayConfirmCard] = useState(false);
  const [isInputDisabled, setIsInputDisabled] = useState(false);
  const [estateAgents, setEstateAgents] = useState<EstateAgent[]>([]);
  const [errorFetching, setErrorFetching] = useState("");

  const [error, setError] = useState("");

  useEffect(() => {
    async function fetchEstateAgents() {
      try {
        const { items } = await ApiController.findAllEstateAgents({});

        setEstateAgents(items);
      } catch (error) {
        if (error instanceof Error) {
          setErrorFetching(error.message);
        }
      }
    }

    fetchEstateAgents();
  }, []);

  const getEstateAgent = (
    estateAgentIdSelected: string,
  ): EstateAgent | null => {
    return (
      estateAgents.find(
        (estateAgent) => estateAgent._id === estateAgentIdSelected,
      ) || null
    );
  };

  const handleConfirmAssignEstateAgents = async (
    estateAgentIdSelected: string,
  ) => {
    setIsInputDisabled(true);

    try {
      const estateAgent = getEstateAgent(estateAgentIdSelected);

      if (estateAgent) {
        // Add estate agent to transaction in DB
        const updatedTransaction =
          await ApiController.updateEstateAgentTransaction(
            transactionId,
            estateAgent._id,
          );

        setTransaction(updatedTransaction);
        setDisplayConfirmCard(false);
      }
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
      }
    }

    setIsInputDisabled(false);
  };

  const getEstateAgentOptions = () => {
    return [
      { label: "Select your option", value: "", disabled: true },
      ...estateAgents
        .map((x) => ({
          label: `${x.brand} - ${x.office.name}`,
          value: x._id,
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    ];
  };

  const getVariant = (error: string | undefined, isDisabled: boolean) => {
    if (error) {
      return "error";
    } else if (isDisabled) {
      return "disabled";
    } else {
      return "enabled";
    }
  };

  const isEmailForPrimaryValuer = (
    estateAgentIdSelectedId: string,
  ): boolean => {
    const estateAgent = getEstateAgent(estateAgentIdSelectedId);

    if (estateAgent) {
      const primaryValuer = estateAgent.valuers.find(
        (valuer) => valuer.primary_valuer,
      );

      return !!primaryValuer && !!primaryValuer.email;
    }
    return false;
  };

  const EstateAgentForm = z.object({
    estate_agent: z.string().min(1, { message: "Estate agent is required" }),
  });

  return (
    <Formik
      initialValues={{
        estate_agent: estateAgentId || "",
      }}
      validate={withZodSchema(EstateAgentForm)}
      onSubmit={(values) =>
        handleConfirmAssignEstateAgents(values.estate_agent)
      }
    >
      {({ handleSubmit, errors, values }) => {
        return (
          <Form>
            <Modal
              title="Estate Agent"
              cancelButtonFunction={() => onCloseModal()}
              saveButtonFunction={() => setDisplayConfirmCard(true)}
              hideSaveButton={displayConfirmCard || errorFetching !== ""}
            >
              <>
                <Typography
                  type="h3"
                  variant="h3"
                  weight="bold"
                  className="text-brand-heavy-teal-100 mb-[16px] mt-[40px]"
                >
                  Assign Estate Agent
                </Typography>
                {errorFetching ? (
                  <ErrorText>{errorFetching}</ErrorText>
                ) : (
                  <div
                    className="flex flex-col w-[60%]"
                    data-testid="assign-estate-agent"
                  >
                    <Field name="estate_agent">
                      {({ field }: FieldProps) => {
                        return (
                          <Select
                            {...field}
                            name="estate_agent"
                            id="estate_agent"
                            options={getEstateAgentOptions()}
                            variant={getVariant(
                              errors["estate_agent"],
                              isInputDisabled || displayConfirmCard,
                            )}
                          />
                        );
                      }}
                    </Field>
                    <div className="mt-[16px]">
                      <ErrorText>{errors.estate_agent}</ErrorText>
                      <ErrorText>{error}</ErrorText>
                    </div>
                  </div>
                )}
              </>
              {displayConfirmCard && (
                <ConfirmCard
                  setDisplayConfirmCard={setDisplayConfirmCard}
                  handleConfirmAssign={handleSubmit}
                  type="estate agent"
                  extraInfo={
                    !isEmailForPrimaryValuer(values.estate_agent) ? (
                      <div className="flex justify-center items-center mb-[16px] p-[16px] bg-ui-warning-100 rounded-xl">
                        <div className="shrink-0 mr-[12px]">
                          <Icons iconName="Warning" size="24" />
                        </div>
                        <Typography variant="sm" weight="semi-bold">
                          Please note, there is no email for the primary valuer,
                          an introduction email will not be sent.
                        </Typography>
                      </div>
                    ) : undefined
                  }
                />
              )}
            </Modal>
          </Form>
        );
      }}
    </Formik>
  );
};
