import * as Sentry from "@sentry/react";
import { api } from "../../../service/ApiController";
import { AdminConfig } from "../../types/types";

export const findAdminConfig = async (): Promise<AdminConfig> => {
  try {
    const response = await api.get(`/admin-config`);

    return response.data as AdminConfig;
  } catch (error) {
    Sentry.captureException(error);

    throw new Error("Unable to get admin config. Please try again.");
  }
};
