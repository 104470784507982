import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { AdminTransaction } from "./types";
import { TransactionInfo } from "../../components/TransactionDetailsTabContent";
import { ApiController } from "../../../service/ApiController";
import Typography from "@/foundation/Typography/Typography";
import { AdminTitle } from "../../components/AdminPageTemplate/AdminTitle";
import { Tabs } from "../../components/TransactionDetailsTabContent/types";
import { RenderTabContent } from "../../components/TransactionDetailsTabContent/RenderTabContent";
import Icons from "@/foundation/Icons/Icons";
import { Lozenge } from "@/components/Lozenge/Lozenge";
import { format } from "date-fns";
import { isTransactionPaid } from "../../../utils/utils";
import { FullPageLoader } from "../../../pages/FullPageLoader/FullPageLoader";

interface Tab {
  value: Tabs;
  label: string;
  count?: number;
  alert?: boolean;
}

const TransactionDetailsTabs = ({
  tabs,
  currentTab,
  setCurrentTab,
}: {
  tabs: Tab[];
  currentTab: Tabs;
  setCurrentTab: (tab: Tabs) => void;
}) => (
  <div className="w-full flex my-[20px] border-b overflow-x-scroll">
    {tabs.map((tab) => (
      <button
        key={tab.value}
        onClick={() => setCurrentTab(tab.value)}
        className={`mr-4 p-[16px] rounded-t-lg flex items-center
                  ${
                    tab.value === currentTab
                      ? "bg-brand-heavy-teal-100 text-brand-heavy-warmth-100"
                      : ""
                  }`}
      >
        <Typography type="p" variant="lg" weight="bold">
          {tab.label}
        </Typography>

        <div className="ml-[8px]">
          {tab.count !== undefined && (
            <div
              className={`flex justify-center items-center rounded-full h-[20px] w-[20px]  ${
                tab.value === currentTab
                  ? "bg-brand-heavy-warmth-100 text-brand-heavy-teal-100"
                  : "bg-brand-heavy-teal-100 text-brand-heavy-warmth-100"
              }`}
            >
              <Typography type="p" variant="xs" weight="bold" className="m-0">
                {tab.count}
              </Typography>
            </div>
          )}
        </div>

        {tab.alert && (
          <div className="ml-[8px]">
            <Icons iconName="Alert" colour="error" size="18" />
          </div>
        )}
      </button>
    ))}
  </div>
);

export const TransactionDetails = () => {
  const location = useLocation();
  const transactionId = location.pathname.split("/")[3];

  const [currentTab, setCurrentTab] = useState<Tabs>("property");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const [transaction, setTransaction] = useState<AdminTransaction>();
  const [transactionPaid, setTransactionPaid] = useState<boolean | undefined>(
    undefined,
  );
  const [tabs, setTabs] = useState<Tab[]>([]);

  useEffect(() => {
    async function getTransaction() {
      setError("");

      try {
        const { transaction: transactionRes } =
          await ApiController.findTransaction(transactionId);

        setTransaction(transactionRes);

        setTabs([
          { value: "property", label: "Property" },
          {
            value: "sellers",
            label: "Sellers",
            count: transactionRes?.sellers.length || 0,
          },
          {
            value: "purchaseIntents",
            label: "Purchase Intents",
            count: transactionRes?.purchase_intent.length || 0,
          },
          { value: "orders", label: "Orders" },
          { value: "thirdfort", label: "Thirdfort" },
          {
            value: "solicitors",
            label: "Solicitors",
            alert: !transactionRes.lawyer_group,
          },
          {
            value: "estateAgent",
            label: "Estate Agent",
            alert: !transactionRes.estate_agent,
          },
          { value: "searches", label: "Searches" },
          { value: "events", label: "Events" },
        ]);
      } catch (error) {
        if (error instanceof Error) {
          console.error(error.message);
          setError("There was an error while fetching the transaction details");
        }
      }
    }

    async function getTransactionPaid() {
      const isPaid = await isTransactionPaid(transactionId);
      setTransactionPaid(isPaid);
    }

    setLoading(true);
    getTransaction();
    getTransactionPaid();
    setLoading(false);
  }, [transactionId]);

  return (
    <>
      <AdminTitle title="Transaction">
        {transaction && !loading && !error && (
          <div className="flex items-center justify-end mb-[16px] space-x-3">
            <Lozenge>
              {`Created On:`}
              <span className="pl-[4px] font-medium">
                {format(new Date(transaction.createdAt), "dd/MM/yyyy")}
              </span>
            </Lozenge>

            {transactionPaid !== undefined && (
              <Lozenge
                fontColor="text-ui-white-100"
                backgroundColor={
                  transactionPaid ? "bg-ui-success-100" : "bg-ui-error-100"
                }
              >
                {transactionPaid ? "Paid" : "Unpaid"}
              </Lozenge>
            )}
          </div>
        )}
      </AdminTitle>

      {loading && <FullPageLoader />}

      {error && (
        <Typography variant="lg" weight="bold" type="p">
          {error}
        </Typography>
      )}

      {transaction && !loading && !error && (
        <>
          <TransactionInfo
            transaction={transaction}
            onUpdateTransaction={(newTransaction) =>
              setTransaction(newTransaction)
            }
          />

          <TransactionDetailsTabs
            tabs={tabs}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
          />

          <div className="flex flex-col space-y-5">
            <RenderTabContent
              currentTab={currentTab}
              transaction={transaction}
              onTransactionUpdated={(newTransaction) =>
                setTransaction(newTransaction)
              }
            />
          </div>
        </>
      )}
    </>
  );
};
