import Typography from "@/foundation/Typography/Typography";

interface HeaderProps {
  children?: React.ReactNode;
  title: string;
}

export const AdminTitle = ({ title, children }: HeaderProps) => (
  <div className="w-full md:flex justify-between pt-[20px] md:pt-[40px]">
    <Typography
      type="h1"
      variant="h3"
      weight="bold"
      className="mb-[32px] text-brand-heavy-teal-100 font-display"
      data-testid="header-title"
    >
      {title}
    </Typography>

    {children}
  </div>
);
