import { Container } from "../Container/Container";
import Typography from "@/foundation/Typography/Typography";

interface HeaderProps {
  children?: React.ReactNode;
  title: string;
}

export const Header = ({ title, children }: HeaderProps) => (
  <div className="w-full z-10 flex flex-col pt-[20px] md:pt-[40px]">
    <Container>
      <Typography
        type="h1"
        variant="h1"
        weight="bold"
        className="mb-[32px] text-brand-heavy-teal-100 font-display"
        data-testid="header-title"
      >
        {title}
      </Typography>

      {children}

      <hr className="border-t-2 border-brand-heavy-warmth-100 mt-[16px] md:mt-[40px]" />
    </Container>
  </div>
);
