import BackgroundImage from "../../assets/background-house-cutout.png";

type BackgroundProps = {
  children?: React.ReactNode;
  className?: string;
};

export const Background = ({ children, className }: BackgroundProps) => (
  <div
    className={`bg-brand-heavy-warmth-75 ${className}`}
    style={{
      backgroundImage: `url(${BackgroundImage})`,
      backgroundPosition: "right -50px top 150px",
      backgroundRepeat: "no-repeat",
      backgroundSize: "622px 624px",
      backgroundAttachment: "fixed",
    }}
  >
    {children}
  </div>
);
