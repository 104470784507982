import { Navigate } from "react-router-dom";

import { useAuthStore } from "../../store/auth/auth";

interface ProtectedRouteProps {
  children: React.ReactNode;
}

export const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
  const { userData } = useAuthStore();

  const isAllowed = userData?.active;

  // If the user is not logged in, redirect to the sign-in page
  if (!isAllowed) {
    return <Navigate to={`/sign-in`} replace />;
  }

  return children;
};
