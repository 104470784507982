import Typography from "../../foundation/Typography/Typography";
import Icons from "../../foundation/Icons/Icons";

import { Product } from "@shared/types";
import { formatCurrency } from "@shared/utils";

export interface PriceCardProps {
  product: Product;
}

export const PriceCard = ({ product }: PriceCardProps) => {
  const { name, description, image, features, price, hasBanner } = product;

  return (
    <div
      className={`relative bg-ui-white-100 shadow-questionType rounded-xl max-w-[555px] mb-[30px] border border-ui-black-10`}
    >
      <div className="bg-ui-white-100 flex flex-col rounded-xl ">
        <div className="h-[64px] flex justify-between items-center bg-brand-heavy-warmth-50 rounded-t-xl px-[32px]">
          <div>
            <Typography
              type="h4"
              variant="xl"
              weight="bold"
              className="text-brand-heavy-teal-100"
            >
              {name}
            </Typography>
          </div>

          <div>
            <div>
              <Typography
                type="p"
                variant="lg"
                weight="bold"
                className="text-brand-heavy-teal-100"
              >
                {formatCurrency(price)}
              </Typography>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col items-start p-[24px]">
        <div className="flex items-center pr-[24px]">
          <img
            src={image || "https://via.placeholder.com/80x80"}
            width="80px"
            height="80px"
            className="mr-[20px]"
          />

          <Typography
            type="p"
            variant="base"
            weight="regular"
            className="font-normal"
          >
            {description}
          </Typography>
        </div>

        <div>
          <hr className="mt-[22px] mb-[16px] border-brand-heavy-teal-25" />

          <div className="flex items-start">
            {features.map((feature, index) => (
              <div key={index} className="mr-[8px]">
                <Icons iconName="GreenTick" size="24px" />

                <Typography
                  type="p"
                  variant="sm"
                  weight="regular"
                  className="font-normal mt-[2px]"
                >
                  {feature}
                </Typography>
              </div>
            ))}
          </div>
        </div>
      </div>

      {hasBanner && (
        <div className="border border-brand-electric-100 rounded-b-md py-[12px] px-[16px] flex justify-center items-center bg-brand-electric-100">
          <div className="drop-shadow-icon border-brand-heavy-teal-100 border rounded-full h-[40px] w-[40px] bg-brand-heavy-teal-100 flex items-center justify-center mr-[16px]">
            <Icons iconName="Lightning" size="24" colour="electric" />
          </div>

          <Typography
            type="p"
            variant="lg"
            weight="regular"
            className="text-brand-heavy-teal-100"
          >
            Starting the process now <strong>saves over 6 weeks!</strong>
          </Typography>
        </div>
      )}
    </div>
  );
};

export default PriceCard;
