import * as Sentry from "@sentry/react";

import { api } from ".";
import { TransactionResponse } from "@shared/types";

export const findTransactionsByPurchaseIntentUserId = async (
  userId: string,
): Promise<TransactionResponse[]> => {
  try {
    const response = await api.get(
      `/transaction/purchase-intent/user/${userId}`,
    );
    return response.data;
  } catch (error: unknown) {
    Sentry.captureException(error);

    if (error instanceof Error) {
      throw new Error(
        `Find latest transaction by purchase intent user id failed: ${error.message}`,
      );
    } else {
      throw new Error(
        "Find latest transaction by purchase intent user id failed for an unknown reason",
      );
    }
  }
};
