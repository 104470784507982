import { useEffect, useState } from "react";

import ErrorText from "@/foundation/Typography/ErrorText";
import { Modal } from "../../../components/Modal/Modal";
import { ApiController } from "../../../service/ApiController";
import { AdminTransaction } from "../../pages/TransactionDetails/types";

interface TagsModalProps {
  onClose: () => void;
  onSavedTags: (transaction: AdminTransaction) => void;
  selectedTags: string[];
  transactionId: string;
}

interface Tag {
  name: string;
  selected: boolean;
}

export const TagsModal = ({
  onClose,
  onSavedTags,
  selectedTags,
  transactionId,
}: TagsModalProps) => {
  const [tags, setTags] = useState<Tag[]>([]);
  const [error, setError] = useState("");

  useEffect(() => {
    async function fetchTags() {
      setError("");

      try {
        const configRes = await ApiController.findAdminConfig();

        const displayedTags = configRes.tags.map((tag) => ({
          name: tag,
          selected: selectedTags.includes(tag),
        }));

        setTags(displayedTags);
      } catch {
        setError("Failed to load tags");
      }
    }

    fetchTags();
  }, [selectedTags]);

  const getClassNames = (selected: boolean) => {
    return `py-[8px] px-[16px] m-[8px] rounded-full cursor-pointer ${
      selected
        ? "bg-brand-heavy-teal-100 text-ui-white-100"
        : "bg-ui-white-100 text-brand-heavy-teal-100"
    }`;
  };

  const onSaveTags = async () => {
    setError("");

    const selectedTags = tags
      .filter((tag) => tag.selected)
      .map((tag) => tag.name);

    try {
      const newTransaction = await ApiController.updateTransactionTags(
        transactionId,
        selectedTags,
      );
      onSavedTags(newTransaction);
    } catch (error) {
      setError("Failed to update tags");
    }
  };

  return (
    <Modal
      title="Add/Edit Tags"
      cancelButtonFunction={() => onClose()}
      saveButtonFunction={() => onSaveTags()}
    >
      <>
        {error && <ErrorText>{error}</ErrorText>}
        {tags.map(({ name, selected }) => (
          <button
            key={`tag-${name}`}
            className={getClassNames(selected)}
            onClick={() =>
              setTags(
                tags.map((tag) => {
                  return tag.name === name
                    ? { ...tag, selected: !tag.selected }
                    : tag;
                }),
              )
            }
          >
            {name}
          </button>
        ))}
      </>
    </Modal>
  );
};
