import Typography from "@/foundation/Typography/Typography";

export const TermsAndConditionsTextBuyer = () => (
  <>
    <div className="mb-[20px] mt-[40px]" id="1-about-our-terms">
      <Typography
        type="h3"
        weight="bold"
        variant="h4"
        className="text-brand-heavy-teal-100"
      >
        1 About our terms
      </Typography>
    </div>

    <div className="mb-[20px]">
      <Typography
        type="p"
        weight="regular"
        variant="base"
        className="text-brand-heavy-teal-100"
      >
        1.1 These terms and conditions of use (Terms) explain how you may use
        the Propelr Service and any platform made available to you from time to
        time by us in respect of the Propelr Service and any of its content
        (Platform).
      </Typography>
    </div>

    <div className="mb-[20px]">
      <Typography
        type="p"
        weight="regular"
        variant="base"
        className="text-brand-heavy-teal-100"
      >
        1.2 These Terms apply between Propelr Property Limited (we, us or our)
        and you, the person accessing or using the Propelr Service and/or
        Platform (you or your). The Propelr Service may be used by you for the
        purpose of selling a property (Seller) or buying a property (Buyer). For
        the purposes of these Terms the relevant property whose sale is being
        facilitated through the Platform shall be referred to as “the Property”
        and the sale or purchase of the Property shall be “the Transaction”
      </Typography>
    </div>

    <div className="mb-[20px]">
      <Typography
        type="p"
        weight="regular"
        variant="base"
        className="text-brand-heavy-teal-100"
      >
        1.3 The “Propelr Service” is the service offered by us in respect of
        facilitating residential conveyancing transactions, including use of the
        Platform where applicable.
      </Typography>
    </div>

    <div className="mb-[20px]">
      <Typography
        type="p"
        weight="regular"
        variant="base"
        className="text-brand-heavy-teal-100"
      >
        1.4 These terms are organised as follows:
      </Typography>
    </div>

    <div className="mb-[20px]">
      <Typography
        type="p"
        weight="regular"
        variant="base"
        className="text-brand-heavy-teal-100"
      >
        1.4.1 Part A – General Terms of Use
      </Typography>
    </div>

    <div className="mb-[20px]">
      <Typography
        type="p"
        weight="regular"
        variant="base"
        className="text-brand-heavy-teal-100"
      >
        1.4.2 Part B – Terms of Use for Buyers Specifically
      </Typography>
    </div>

    <div className="mb-[20px]">
      <Typography
        type="p"
        weight="regular"
        variant="base"
        className="text-brand-heavy-teal-100"
      >
        1.5 You should read these Terms carefully before using the Propelr
        Service. By using the Propelr Service or otherwise indicating your
        consent, you agree to be bound by Part A of these Terms. If you are
        using the Propelr Service as a Buyer, you also agree to be bound by Part
        B of these Terms. If you do not agree with any of these Terms, you
        should stop using the Propelr Service immediately.
      </Typography>
    </div>

    <div className="mb-[20px] mt-[40px]">
      <Typography
        type="h3"
        weight="bold"
        variant="h4"
        className="text-brand-heavy-teal-100"
      >
        Part A – General Terms of Use
      </Typography>
    </div>

    <div className="mb-[20px] mt-[40px]" id="2-about-us">
      <Typography
        type="h3"
        weight="bold"
        variant="h4"
        className="text-brand-heavy-teal-100"
      >
        2 About us
      </Typography>
    </div>

    <div className="mb-[20px]">
      <Typography
        type="p"
        weight="regular"
        variant="base"
        className="text-brand-heavy-teal-100"
      >
        2.1 We are Propelr Property Limited, a company registered in England and
        Wales under company registration number 14625731. Our registered office
        is at 71 Queen Victoria Street, London, United Kingdom, EC4V 4BE.
      </Typography>
    </div>

    <div className="mb-[20px]">
      <Typography
        type="p"
        weight="regular"
        variant="base"
        className="text-brand-heavy-teal-100"
      >
        2.2 If you have any questions about the Propelr Service, please contact
        us by: 2.2.1 sending an email to help@propelr.co.uk, or
      </Typography>
    </div>

    <div className="mb-[20px]">
      <Typography
        type="p"
        weight="regular"
        variant="base"
        className="text-brand-heavy-teal-100"
      >
        2.2.2 filling out and submitting the online form available at
        propelr.co.uk,
      </Typography>

      <div className="mb-[20px] mt-[40px]" id="3-using-the-propelr-service">
        <Typography
          type="h3"
          weight="bold"
          variant="h4"
          className="text-brand-heavy-teal-100"
        >
          3 Using the Propelr Service
        </Typography>
      </div>

      <Typography
        type="p"
        weight="regular"
        variant="base"
        className="text-brand-heavy-teal-100"
      >
        3.1 The Propelr Service is for your use only.
      </Typography>
    </div>

    <div className="mb-[20px]">
      <Typography
        type="p"
        weight="regular"
        variant="base"
        className="text-brand-heavy-teal-100"
      >
        3.2 The Propelr Service relates to properties in the United Kingdom. We
        do not represent that content available on or through the Propelr
        Service is appropriate for use or available in other locations.
      </Typography>
    </div>

    <div className="mb-[20px]">
      <Typography
        type="p"
        weight="regular"
        variant="base"
        className="text-brand-heavy-teal-100"
      >
        3.3 We try to make the Propelr Service and the Platform as accessible as
        possible. If you have any difficulties using the Propelr Service or the
        Platform, please contact us using the contact details at the top of this
        page.
      </Typography>
    </div>

    <div className="mb-[20px]">
      <Typography
        type="p"
        weight="regular"
        variant="base"
        className="text-brand-heavy-teal-100"
      >
        3.4 As a condition of your use of the Propelr Service, you agree not to:
      </Typography>
    </div>

    <div className="mb-[20px]">
      <Typography
        type="p"
        weight="regular"
        variant="base"
        className="text-brand-heavy-teal-100"
      >
        3.4.1 misuse or attack our Platform by knowingly introducing viruses,
        trojans, worms, logic bombs or any other material which is malicious or
        technologically harmful (such as by way of a denial-of service attack),
        or
      </Typography>
    </div>

    <div className="mb-[20px]">
      <Typography
        type="p"
        weight="regular"
        variant="base"
        className="text-brand-heavy-teal-100"
      >
        3.4.2 attempt to gain unauthorised access to our Platform, the server on
        which our Platform is stored or any server, computer or database
        connected to our Platform.
      </Typography>
    </div>

    <div className="mb-[20px]">
      <Typography
        type="p"
        weight="regular"
        variant="base"
        className="text-brand-heavy-teal-100"
      >
        3.5 We may prevent or suspend your access to the Propelr Service and/or
        the Platform if you do not comply with these Terms or any applicable
        law.
      </Typography>
    </div>

    <div className="mb-[20px] mt-[40px]" id="4-cooperation-with-solicitors">
      <Typography
        type="h3"
        weight="bold"
        variant="h4"
        className="text-brand-heavy-teal-100"
      >
        4 Cooperation with Solicitors
      </Typography>
    </div>

    <div className="mb-[20px]">
      <Typography
        type="p"
        weight="regular"
        variant="base"
        className="text-brand-heavy-teal-100"
      >
        4.1 When you join the Propelr Service as a Seller, we will provide you
        with a choice of solicitors from our panel. You may select which
        solicitor you wish to represent you throughout the Transaction. If you
        have no preference, we will assign a solicitor to you.
      </Typography>
    </div>

    <div className="mb-[20px]">
      <Typography
        type="p"
        weight="regular"
        variant="base"
        className="text-brand-heavy-teal-100"
      >
        4.2 When you join the Propelr Service as a Buyer, we will assign a
        solicitor to represent you throughout the Transaction.
      </Typography>
    </div>

    <div className="mb-[20px]">
      <Typography
        type="p"
        weight="regular"
        variant="base"
        className="text-brand-heavy-teal-100"
      >
        4.3 If you use the Propelr Service, you agree to provide any information
        requested by us, the Platform or your assigned solicitor within a
        reasonable timeframe and without undue delay.
      </Typography>
    </div>

    <div className="mb-[20px]">
      <Typography
        type="p"
        weight="regular"
        variant="base"
        className="text-brand-heavy-teal-100"
      >
        4.4 If at any time you would like more information about why you have
        been asked to provide further details or information then you may
        contact us for further details regarding the request, as set out in
        clause 2.2 above.
      </Typography>
    </div>

    <div className="mb-[20px]">
      <Typography
        type="p"
        weight="regular"
        variant="base"
        className="text-brand-heavy-teal-100"
      >
        4.5 Throughout the Transaction, you shall cooperate fully and liaise
        promptly with us and your assigned solicitor. If you are unable to
        comply with this clause 4 for any reason, you shall notify us of this
        reason in writing. If we are not notified of such reason, or are not
        reasonably satisfied by the reason provided, any undue delay shall
        constitute a breach of this clause and these Terms.
      </Typography>
    </div>

    <div
      className="mb-[20px] mt-[40px]"
      id="5-registration-and-password-security"
    >
      <Typography
        type="h3"
        weight="bold"
        variant="h4"
        className="text-brand-heavy-teal-100"
      >
        5 Registration and password security
      </Typography>
    </div>

    <div className="mb-[20px]">
      <Typography
        type="p"
        weight="regular"
        variant="base"
        className="text-brand-heavy-teal-100"
      >
        5.1 Use of the Propelr Service shall require registration and payment.
      </Typography>
    </div>

    <div className="mb-[20px]">
      <Typography
        type="p"
        weight="regular"
        variant="base"
        className="text-brand-heavy-teal-100"
      >
        5.2 We are not obliged to permit anyone to register with the Propelr
        Service and we may refuse, terminate or suspend registration to anyone
        at any time.
      </Typography>
    </div>

    <div className="mb-[20px]">
      <Typography
        type="p"
        weight="regular"
        variant="base"
        className="text-brand-heavy-teal-100"
      >
        5.3 You are responsible for making sure that your password and any other
        account details for the Platform are kept secure and confidential.
      </Typography>
    </div>

    <div className="mb-[20px]">
      <Typography
        type="p"
        weight="regular"
        variant="base"
        className="text-brand-heavy-teal-100"
      >
        5.4 If we have reason to believe there is likely to be a breach of
        security or misuse of the Platform through your account or the use of
        your password, we may notify you and require you to change your
        password, or we may suspend or terminate your account.
      </Typography>
    </div>

    <div className="mb-[20px]">
      <Typography
        type="p"
        weight="regular"
        variant="base"
        className="text-brand-heavy-teal-100"
      >
        5.5 Any personal information you provide to us as part of the
        registration process will be processed in accordance with our Privacy
        Policy available at propelr.co.uk/privacy.
      </Typography>
    </div>

    <div className="mb-[20px] mt-[40px]" id="6-infringing-content">
      <Typography
        type="h3"
        weight="bold"
        variant="h4"
        className="text-brand-heavy-teal-100"
      >
        6 Infringing content
      </Typography>
    </div>

    <div className="mb-[20px]">
      <Typography
        type="p"
        weight="regular"
        variant="base"
        className="text-brand-heavy-teal-100"
      >
        6.1 We reserve the right to:
      </Typography>
    </div>

    <div className="mb-[20px]">
      <Typography
        type="p"
        weight="regular"
        variant="base"
        className="text-brand-heavy-teal-100"
      >
        6.1.1 terminate your account if we have evidence that it is being used
        in an inappropriate manner or in breach of these Terms; and
      </Typography>
    </div>

    <div className="mb-[20px]">
      <Typography
        type="p"
        weight="regular"
        variant="base"
        className="text-brand-heavy-teal-100"
      >
        6.1.2 identify and remove any content that is inappropriate, defamatory,
        infringes intellectual property rights or is otherwise in breach of our
        Acceptable Use Policy if we are notified, but we cannot be responsible
        if you have failed to provide us with the relevant information.
      </Typography>
    </div>

    <div className="mb-[20px]">
      <Typography
        type="p"
        weight="regular"
        variant="base"
        className="text-brand-heavy-teal-100"
      >
        6.2 If you believe that any content which is distributed or published by
        the Propelr Service or the Platform is inappropriate, defamatory or
        infringing on intellectual property rights, you should contact us
        immediately using the contact details at the top of this page.
      </Typography>
    </div>

    <div
      className="mb-[20px] mt-[40px]"
      id="7-your-privacy-and-personal-information"
    >
      <Typography
        type="h3"
        weight="bold"
        variant="h4"
        className="text-brand-heavy-teal-100"
      >
        7 Your privacy and personal information
      </Typography>
    </div>

    <div className="mb-[20px]">
      <Typography
        type="p"
        weight="regular"
        variant="base"
        className="text-brand-heavy-teal-100"
      >
        Your privacy and personal information are important to us. Any personal
        information that you provide to us will be dealt with in line with our
        Privacy Policy available at propelr.co.uk/privacy, which explains what
        personal information we collect from you, how and why we collect, store,
        use and share such information, your rights in relation to your personal
        information and how to contact us and supervisory authorities in the
        event you have a query or complaint about the use of your personal
        information.
      </Typography>
    </div>

    <div
      className="mb-[20px] mt-[40px]"
      id="8-ownership-use-and-intellectual-property-rights"
    >
      <Typography
        type="h3"
        weight="bold"
        variant="h4"
        className="text-brand-heavy-teal-100"
      >
        8 Ownership, use and intellectual property rights
      </Typography>
    </div>

    <div className="mb-[20px]">
      <Typography
        type="p"
        weight="regular"
        variant="base"
        className="text-brand-heavy-teal-100"
      >
        8.1 The intellectual property rights in the Propelr Service and in any
        text, images, video, audio or other multimedia content, software or
        other information or material prepared by us or accessible from the
        Platform (Platform Content) are owned by us and our licensors.
      </Typography>
    </div>

    <div className="mb-[20px]">
      <Typography
        type="p"
        weight="regular"
        variant="base"
        className="text-brand-heavy-teal-100"
      >
        8.2 We and our licensors reserve all our intellectual property rights
        (including, but not limited to, all copyright, trade marks, domain
        names, design rights, database rights, patents and all other
        intellectual property rights of any kind) whether registered or
        unregistered anywhere in the world. This means, for example, that we
        remain owners of them and are free to use them as we see fit.
      </Typography>
    </div>

    <div className="mb-[20px]">
      <Typography
        type="p"
        weight="regular"
        variant="base"
        className="text-brand-heavy-teal-100"
      >
        8.3 Nothing in these Terms grants you any legal rights in the Platform
        or the Platform Content other than as necessary for you to access it.
        You agree not to adjust, try to circumvent or delete any notices
        contained on the Platform or the Platform Content (including any
        intellectual property notices) and in particular, in any digital rights
        or other security technology embedded or contained within the Platform
        or the Platform Content.
      </Typography>
    </div>

    <div className="mb-[20px]">
      <Typography
        type="p"
        weight="regular"
        variant="base"
        className="text-brand-heavy-teal-100"
      >
        Trademarks: the word ‘Propelr’, the propeller logo and various
        combinations thereof are our trademarks. Other third party trademarks
        used on the site from time to time remain trademarks of their respective
        owners. Other trademarks and trade names may also be used on the
        Platform or in the Platform Content. Use by you of any trademarks on the
        Platform or in the Platform Content is strictly prohibited unless you
        have our prior written permission.
      </Typography>
    </div>

    <div
      className="mb-[20px] mt-[40px]"
      id="9-submitting-information-to-the-propelr-service"
    >
      <Typography
        type="h3"
        weight="bold"
        variant="h4"
        className="text-brand-heavy-teal-100"
      >
        9 Submitting information to the Propelr Service
      </Typography>
    </div>

    <div className="mb-[20px]">
      <Typography
        type="p"
        weight="regular"
        variant="base"
        className="text-brand-heavy-teal-100"
      >
        9.1 Whenever you provide information, documents or content, including
        information about the Property and photographs of the Property (User
        Materials) to the Propelr Service, you must ensure that the content is
        accurate and complete.
      </Typography>
    </div>

    <div className="mb-[20px]">
      <Typography
        type="p"
        weight="regular"
        variant="base"
        className="text-brand-heavy-teal-100"
      >
        9.2 You warrant that any such contribution does comply with those
        standards, and you will be liable to us and indemnify us for any breach
        of that warranty. This means you will be responsible for any loss or
        damage we suffer as a result of your breach of warranty.
      </Typography>
    </div>

    <div className="mb-[20px]">
      <Typography
        type="p"
        weight="regular"
        variant="base"
        className="text-brand-heavy-teal-100"
      >
        9.3 You retain all of your ownership rights in User Materials you
        provide, but grant us the right to use, store and copy the User
        Materials and to distribute and make them available to necessary third
        parties for the purpose of providing you the services and for the
        purpose of the Transaction. You agree that we may use any User Materials
        that relate to the Property in accordance with clause 10 below.
      </Typography>
    </div>

    <div
      className="mb-[20px] mt-[40px]"
      id="10-retention-of-property-information"
    >
      <Typography
        type="h3"
        weight="bold"
        variant="h4"
        className="text-brand-heavy-teal-100"
      >
        10 Retention of Property information
      </Typography>
    </div>

    <div className="mb-[20px]">
      <Typography
        type="p"
        weight="regular"
        variant="base"
        className="text-brand-heavy-teal-100"
      >
        10.1 Through the course of the Transaction, we and your appointed
        solicitor will gather information about the Property. This may include
        information uploaded by you in respect of the Property itself and
        information about any mortgage you may enter, including the name of
        lender and type of mortgage.
      </Typography>
    </div>

    <div className="mb-[20px]">
      <Typography
        type="p"
        weight="regular"
        variant="base"
        className="text-brand-heavy-teal-100"
      >
        10.2 You consent to us retaining this information for the purposes of
        any future transactions or otherwise for a period 10 years. This
        information will be used on an anonymised basis and at a level of detail
        that is appropriate for its use. We will not share any personal data
        with third parties unless it is necessary and we have your consent.
      </Typography>
    </div>

    <div
      className="mb-[20px] mt-[40px]"
      id="11-accuracy-of-information-and-availability-of-the-service"
    >
      <Typography
        type="h3"
        weight="bold"
        variant="h4"
        className="text-brand-heavy-teal-100"
      >
        11 Accuracy of information and availability of the service
      </Typography>
    </div>

    <div className="mb-[20px]">
      <Typography
        type="p"
        weight="regular"
        variant="base"
        className="text-brand-heavy-teal-100"
      >
        11.1 We try to make sure that the Propelr Service is accurate,
        up-to-date and that the Platform is free from bugs, but we cannot
        promise that it will be. Any reliance that you may place on the
        information provided by the Propelr Service is at your own risk.
      </Typography>
    </div>

    <div className="mb-[20px]">
      <Typography
        type="p"
        weight="regular"
        variant="base"
        className="text-brand-heavy-teal-100"
      >
        11.2 We may suspend or terminate access or operation of the Propelr
        Service at any time as we see fit.
      </Typography>
    </div>

    <div className="mb-[20px]">
      <Typography
        type="p"
        weight="regular"
        variant="base"
        className="text-brand-heavy-teal-100"
      >
        11.3 While we try to make sure that the Propelr Service is available for
        your use, we do not promise that the Propelr Service will be available
        at all times or that your use of the Platform will be uninterrupted.
      </Typography>
    </div>

    <div
      className="mb-[20px] mt-[40px]"
      id="12-hyperlinks-and-third-party-sites"
    >
      <Typography
        type="h3"
        weight="bold"
        variant="h4"
        className="text-brand-heavy-teal-100"
      >
        12 Hyperlinks and third party sites
      </Typography>
    </div>

    <div className="mb-[20px]">
      <Typography
        type="p"
        weight="regular"
        variant="base"
        className="text-brand-heavy-teal-100"
      >
        The Platform may contain hyperlinks or references to third party
        websites other than the Platform. Any such hyperlinks or references are
        provided for your convenience only. We have no control over third party
        websites and accept no legal responsibility for any content, material or
        information contained in them. The display of any hyperlink and
        reference to any third party website does not mean that we endorse that
        third party’s website, products or services. Your use of a third party
        site may be governed by the terms and conditions of that third-party
        site and is at your own risk.
      </Typography>
    </div>

    <div className="mb-[20px] mt-[40px]" id="13-our-responsibility-to-you">
      <Typography
        type="h3"
        weight="bold"
        variant="h4"
        className="text-brand-heavy-teal-100"
      >
        13 Our responsibility to you
      </Typography>
    </div>

    <div className="mb-[20px]">
      <Typography
        type="p"
        weight="regular"
        variant="base"
        className="text-brand-heavy-teal-100"
      >
        13.1 If we breach these Terms or are negligent, we are liable to you for
        foreseeable loss or damage that you suffer as a result. By ‘foreseeable’
        we mean that, at the time these Terms were formed, it was
      </Typography>
    </div>

    <div className="mb-[20px]">
      <Typography
        type="p"
        weight="regular"
        variant="base"
        className="text-brand-heavy-teal-100"
      >
        either clear that such loss or damage would occur or you and we both
        knew that it might reasonably occur, as a result of something we did (or
        failed to do).
      </Typography>
    </div>

    <div className="mb-[20px]">
      <Typography
        type="p"
        weight="regular"
        variant="base"
        className="text-brand-heavy-teal-100"
      >
        13.2 Without affecting any other clause in this Agreement, our liability
        under or in connection with clause 13.1 shall be limited to £10,000 for
        each and every claim. This limit shall apply however that liability
        arises, including, without limitation, a liability arising by breach of
        contract, arising by tort (including, without limitation, the tort of
        negligence) or arising by breach of statutory duty.
      </Typography>
    </div>

    <div className="mb-[20px]">
      <Typography
        type="p"
        weight="regular"
        variant="base"
        className="text-brand-heavy-teal-100"
      >
        13.3 We are not liable to you for any loss or damage that was not
        foreseeable, any loss or damage not caused by our breach or negligence,
        or any business loss or damage.
      </Typography>
    </div>

    <div className="mb-[20px]">
      <Typography
        type="p"
        weight="regular"
        variant="base"
        className="text-brand-heavy-teal-100"
      >
        13.4 We are not liable to you for any loss or damage as a result of the
        valuation of the Property.
      </Typography>
    </div>

    <div className="mb-[20px]">
      <Typography
        type="p"
        weight="regular"
        variant="base"
        className="text-brand-heavy-teal-100"
      >
        13.5 Nothing in these terms excludes or limits our liability for any
        death or personal injury caused by our negligence, liability for fraud
        or fraudulent misrepresentation, or any other liability that the law
        does not allow us to exclude or limit.
      </Typography>
    </div>

    <div className="mb-[20px] mt-[40px]" id="14-events-beyond-our-control">
      <Typography
        type="h3"
        weight="bold"
        variant="h4"
        className="text-brand-heavy-teal-100"
      >
        14 Events beyond our control
      </Typography>
    </div>

    <div className="mb-[20px]">
      <Typography
        type="p"
        weight="regular"
        variant="base"
        className="text-brand-heavy-teal-100"
      >
        We are not liable to you if we fail to comply with these Terms because
        of circumstances beyond our reasonable control.
      </Typography>
    </div>

    <div className="mb-[20px] mt-[40px]" id="15-no-third-party-rights">
      <Typography
        type="h3"
        weight="bold"
        variant="h4"
        className="text-brand-heavy-teal-100"
      >
        15 No third party rights
      </Typography>
    </div>

    <div className="mb-[20px]">
      <Typography
        type="p"
        weight="regular"
        variant="base"
        className="text-brand-heavy-teal-100"
      >
        No one other than us or you has any right to enforce any of these Terms.
      </Typography>
    </div>

    <div className="mb-[20px] mt-[40px]" id="16-variation">
      <Typography
        type="h3"
        weight="bold"
        variant="h4"
        className="text-brand-heavy-teal-100"
      >
        16 Variation
      </Typography>
    </div>

    <div className="mb-[20px]">
      <Typography
        type="p"
        weight="regular"
        variant="base"
        className="text-brand-heavy-teal-100"
      >
        16.1 No changes to these Terms are valid or have any effect unless
        agreed by us in writing or made in accordance with this clause 16.
      </Typography>
    </div>

    <div className="mb-[20px]">
      <Typography
        type="p"
        weight="regular"
        variant="base"
        className="text-brand-heavy-teal-100"
      >
        16.2 We reserve the right to vary these Terms from time to time. Our
        updated Terms will be displayed on the Platform and by continuing to use
        and access the Propelr Service following such changes, you agree to be
        bound by any variation made by us. It is your responsibility to check
        these Terms from time to time to verify such variations.
      </Typography>
    </div>

    <div className="mb-[20px] mt-[40px]" id="17-complaints">
      <Typography
        type="h3"
        weight="bold"
        variant="h4"
        className="text-brand-heavy-teal-100"
      >
        17 Complaints
      </Typography>
    </div>

    <div className="mb-[20px]">
      <Typography
        type="p"
        weight="regular"
        variant="base"
        className="text-brand-heavy-teal-100"
      >
        17.1 We will try to resolve any disputes with you quickly and
        efficiently. If you are unhappy with us, please contact us as soon as
        possible using the contact details set out at the top of this page.
      </Typography>
    </div>

    <div className="mb-[20px]">
      <Typography
        type="p"
        weight="regular"
        variant="base"
        className="text-brand-heavy-teal-100"
      >
        17.2 The laws of England and Wales apply to these Terms.
      </Typography>
    </div>

    <div className="mb-[20px]">
      <Typography
        type="p"
        weight="regular"
        variant="base"
        className="text-brand-heavy-teal-100"
      >
        17.3 Any disputes (including non-contractual disputes or claims) arising
        out of or in connection with these Terms will be subject to exclusive
        jurisdiction of the courts of England and Wales.
      </Typography>
    </div>

    <div className="mb-[20px]">
      <Typography
        type="h3"
        weight="bold"
        variant="h4"
        className="text-brand-heavy-teal-100"
      >
        Part B – Terms of Use for Buyers Specifically
      </Typography>
    </div>

    <div
      className="mb-[20px] mt-[40px]"
      id="18-registration-process-for-first-time-users"
    >
      <Typography
        type="h3"
        weight="bold"
        variant="h4"
        className="text-brand-heavy-teal-100"
      >
        18 Registration Process for first-time users
      </Typography>
    </div>

    <div className="mb-[20px]">
      <Typography
        type="p"
        weight="regular"
        variant="base"
        className="text-brand-heavy-teal-100"
      >
        18.1 If you are a Buyer who has made an offer on a Property which has
        been accepted and you have not used the Propelr Service prior to the
        relevant Transaction, you shall be registered to use the Propelr Service
        according to the following process;
      </Typography>
    </div>

    <div className="mb-[20px]">
      <Typography
        type="p"
        weight="regular"
        variant="base"
        className="text-brand-heavy-teal-100"
      >
        18.1.1 You will receive an email from us, containing an information pack
        and registration instructions. You will also receive a subsequent email
        with information on how to send various documents securely to us.
      </Typography>
    </div>

    <div className="mb-[20px]">
      <Typography
        type="p"
        weight="regular"
        variant="base"
        className="text-brand-heavy-teal-100"
      >
        18.1.2 You will follow the instructions provided and register for the
        Propelr Service, providing a username and password for your account on
        the Platform if requested. You will be asked to provide any necessary ID
        documents and other relevant information;
      </Typography>
    </div>

    <div className="mb-[20px]">
      <Typography
        type="p"
        weight="regular"
        variant="base"
        className="text-brand-heavy-teal-100"
      >
        18.1.3 The ID documents that you provide will be reviewed by Thirdfort
        or another verified third party. Processing of those documents will be
        in accordance with that third party’s terms of service. Your ID
        documents will be shared with your assigned solicitor. They will not be
        shared with any other third party.
      </Typography>
    </div>

    <div className="mb-[20px]">
      <Typography
        type="p"
        weight="regular"
        variant="base"
        className="text-brand-heavy-teal-100"
      >
        18.1.4 You will follow the instructions you have received to provide any
        PDF documents requested;
      </Typography>
    </div>

    <div className="mb-[20px]">
      <Typography
        type="p"
        weight="regular"
        variant="base"
        className="text-brand-heavy-teal-100"
      >
        18.1.5 We will carry out all necessary checks of the information and PDF
        documents provided and request any further information we require as
        necessary;
      </Typography>
    </div>

    <div className="mb-[20px]">
      <Typography
        type="p"
        weight="regular"
        variant="base"
        className="text-brand-heavy-teal-100"
      >
        18.1.6 Once all necessary checks have been completed, your matter will
        be sent to your assigned solicitor.
      </Typography>
    </div>

    <div className="mb-[20px]">
      <Typography
        type="p"
        weight="regular"
        variant="base"
        className="text-brand-heavy-teal-100"
      >
        18.1.7 Completion of documents checks to our satisfaction does not mean
        that checks have been completed to the standards required by your
        assigned solicitor to comply with Know Your Customer, Anti-Money
        Laundering and other requirements. Your assigned solicitor will confirm
        once checks have been completed to their satisfaction.
      </Typography>
    </div>

    <div className="mb-[20px] mt-[40px]" id="19-information-to-be-provided">
      <Typography
        type="h3"
        weight="bold"
        variant="h4"
        className="text-brand-heavy-teal-100"
      >
        19 Information to be provided
      </Typography>
    </div>

    <div className="mb-[20px]">
      <Typography
        type="p"
        weight="regular"
        variant="base"
        className="text-brand-heavy-teal-100"
      >
        19.1 If you are a Buyer who has registered for the Propelr Service, you
        will be required to provide certain information. This information may
        include, but is not limited to, any of the following:
      </Typography>
    </div>

    <div className="mb-[20px]">
      <Typography
        type="p"
        weight="regular"
        variant="base"
        className="text-brand-heavy-teal-100"
      >
        19.1.1 Details of your sources of financing for the Transaction;
      </Typography>
    </div>

    <div className="mb-[20px]">
      <Typography
        type="p"
        weight="regular"
        variant="base"
        className="text-brand-heavy-teal-100"
      >
        19.1.2 Information relating to any mortgage that will be entered into to
        finance the purchase of the Property; and
      </Typography>
    </div>

    <div className="mb-[20px]">
      <Typography
        type="p"
        weight="regular"
        variant="base"
        className="text-brand-heavy-teal-100"
      >
        19.1.3 Information relating to any mortgage that will be redeemed on a
        connected sale, such as the lender and mortgage account number.
      </Typography>

      <div className="mb-[20px] mt-[40px]" id="20-payment-of-costs">
        <Typography
          type="h3"
          weight="bold"
          variant="h4"
          className="text-brand-heavy-teal-100"
        >
          20 Payment of costs
        </Typography>
      </div>

      <Typography
        type="p"
        weight="regular"
        variant="base"
        className="text-brand-heavy-teal-100"
      >
        20.1 Unless otherwise agreed in writing, if you are a Buyer, you will be
        responsible for paying the following completion monies to your assigned
        solicitor:
      </Typography>
    </div>

    <div className="mb-[20px]">
      <Typography
        type="p"
        weight="regular"
        variant="base"
        className="text-brand-heavy-teal-100"
      >
        20.1.1 The purchase price of the Property;
      </Typography>
    </div>

    <div className="mb-[20px]">
      <Typography
        type="p"
        weight="regular"
        variant="base"
        className="text-brand-heavy-teal-100"
      >
        20.1.2 Any Stamp Duty Land Tax (SDLT) or Land Transaction Tax (LTT) due
        for the purchase of the Property;
      </Typography>
    </div>

    <div className="mb-[20px]">
      <Typography
        type="p"
        weight="regular"
        variant="base"
        className="text-brand-heavy-teal-100"
      >
        20.1.3 Any additional disbursement costs incurred by your assigned
        solicitor for work in connection with the transaction that your assigned
        solicitor will advise;
      </Typography>
    </div>

    <div className="mb-[20px]">
      <Typography
        type="p"
        weight="regular"
        variant="base"
        className="text-brand-heavy-teal-100"
      >
        20.1.4 Any supplementary legal costs incurred by your assigned
        solicitor, if you have been advised that these will be due during the
        Transaction;
      </Typography>
    </div>

    <div className="mb-[20px]">
      <Typography
        type="p"
        weight="regular"
        variant="base"
        className="text-brand-heavy-teal-100"
      >
        20.1.5 Any fees for post-completion work, such as Land Registry
        applications; and
      </Typography>
    </div>

    <div className="mb-[20px]">
      <Typography
        type="p"
        weight="regular"
        variant="base"
        className="text-brand-heavy-teal-100"
      >
        20.1.6 Any applicable VAT payable on any of the above payments.
      </Typography>
    </div>

    <div className="mb-[20px]">
      <Typography
        type="p"
        weight="regular"
        variant="base"
        className="text-brand-heavy-teal-100"
      >
        20.2 Any of the above costs incurred by a solicitor shall be payable to
        that solicitor.
      </Typography>
    </div>

    <div className="mb-[20px]">
      <Typography
        type="p"
        weight="regular"
        variant="base"
        className="text-brand-heavy-teal-100"
      >
        20.3 When the Transaction is completed, the legal costs for your
        assigned solicitor, plus any agent fees if applicable, will be deducted
        from the completion monies received from you before the remainder is
        paid to the Seller.
      </Typography>
    </div>

    <div className="mb-[20px]">
      <Typography
        type="p"
        weight="regular"
        variant="base"
        className="text-brand-heavy-teal-100"
      >
        20.4 If you choose to instruct your own solicitor for the Transaction
        rather than using the solicitor we assigned to you, the legal costs of
        your own solicitor will also be deductible from the completion monies.
        This will be in addition to any legal costs payable for work carried out
        by the solicitor we assigned to you, if any.
      </Typography>
    </div>

    <div className="mb-[20px]">
      <Typography
        type="p"
        weight="regular"
        variant="base"
        className="text-brand-heavy-teal-100"
      >
        20.5 All payments made under this clause 23 are non-refundable and
        exclusive of VAT.
      </Typography>

      <div
        className="mb-[20px] mt-[40px]"
        id="21-registration-process-for-first-time-users"
      >
        <Typography
          type="h3"
          weight="bold"
          variant="h4"
          className="text-brand-heavy-teal-100"
        >
          21 Retention and transfer of information
        </Typography>
      </div>

      <Typography
        type="p"
        weight="regular"
        variant="base"
        className="text-brand-heavy-teal-100"
      >
        21.1 Should you wish to withdraw from the Transaction and the Propelr
        Service prior to completing the purchase, you agree that any Platform
        Content relating to the Property prepared for your benefit remains in
        our exclusive possession. Furthermore, you agree that such Platform
        Content may be provided to any subsequent potential purchaser of the
        Property at any time. You shall retain no rights over such Platform
        Content following your withdrawal from the Propelr Service and the
        transaction. You will cooperate with your assigned solicitor to a
        reasonable extent in order to facilitate the handover of any information
        they require to fulfil this clause promptly and without undue delay.
      </Typography>
    </div>

    <div className="mb-[20px]">
      <Typography
        type="p"
        weight="regular"
        variant="base"
        className="text-brand-heavy-teal-100"
      >
        21.2 We shall only retain Platform Content that relates specifically to
        the Property. Any personal data relating to you will not be shared with
        any third parties, in accordance with our Privacy Policy available at
        propelr.co.uk/privacy.
      </Typography>
    </div>
  </>
);
