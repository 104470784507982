import { useParams, useLocation } from "react-router-dom";

import { PageNotFound } from "../../pages/PageNotFound/PageNotFound";
import { AdminProtectedRoute } from "../../components/ProtectedRoute/AdminProtectedRoute";
import { Users } from "../pages/Users/Users";
import { UserDetails } from "../pages/UserDetails/UserDetails";
import { Transactions } from "../pages/Transactions/Transactions";
import { TransactionDetails } from "../pages/TransactionDetails/TransactionDetails";
import { AdminPageTemplate } from "../components/AdminPageTemplate/AdminPageTemplate";
import { AdminDashboard } from "../pages/AdminDashboard/AdminDashboard";
import { Config } from "../pages/Config/Config";
import { EstateAgents } from "../pages/EstateAgents/EstateAgents";
import { EstateAgentDetails } from "../pages/EstateAgentDetails/EstateAgentDetails";

export const AdminRouter = () => {
  const { page, id } = useParams();
  const { pathname } = useLocation();
  const isEditRoute = pathname.includes("edit");

  let routePath;

  if (page === "users") {
    routePath = isEditRoute ? `users/edit/${id}` : id ? `users/${id}` : page;
  } else if (page === "transactions") {
    routePath = isEditRoute
      ? `transactions/edit/${id}`
      : id
        ? `transactions/${id}`
        : page;
  } else if (page === "estate-agents") {
    routePath = id ? `estate-agents/${id}` : page;
  } else {
    routePath = page;
  }

  switch (routePath) {
    case "users":
      return (
        <AdminProtectedRoute>
          <AdminPageTemplate>
            <Users />
          </AdminPageTemplate>
        </AdminProtectedRoute>
      );
    case `users/${id}`:
      return (
        <AdminProtectedRoute>
          <AdminPageTemplate>
            <UserDetails />
          </AdminPageTemplate>
        </AdminProtectedRoute>
      );
    case "transactions":
      return (
        <AdminProtectedRoute>
          <AdminPageTemplate>
            <Transactions />
          </AdminPageTemplate>
        </AdminProtectedRoute>
      );
    case `transactions/${id}`:
      return (
        <AdminProtectedRoute>
          <AdminPageTemplate>
            <TransactionDetails />
          </AdminPageTemplate>
        </AdminProtectedRoute>
      );
    case "estate-agents":
      return (
        <AdminProtectedRoute>
          <AdminPageTemplate>
            <EstateAgents />
          </AdminPageTemplate>
        </AdminProtectedRoute>
      );
    case `estate-agents/${id}`:
      return (
        <AdminProtectedRoute>
          <AdminPageTemplate>
            <EstateAgentDetails />
          </AdminPageTemplate>
        </AdminProtectedRoute>
      );
    case "config":
      return (
        <AdminProtectedRoute>
          <AdminPageTemplate>
            <Config />
          </AdminPageTemplate>
        </AdminProtectedRoute>
      );
    case undefined:
      return (
        <AdminProtectedRoute>
          <AdminPageTemplate>
            <AdminDashboard />
          </AdminPageTemplate>
        </AdminProtectedRoute>
      );
    default:
      return <PageNotFound />;
  }
};
