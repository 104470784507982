import { ChangeEvent, ComponentProps, useRef, useState } from "react";
import { statusConfig } from "./statusConfig";

export type DropdownProps = ComponentProps<"select"> & {
  variant?: "enabled" | "disabled" | "success" | "error";
  name: string;
  options: {
    label: string;
    id: string;
    isLineBreak?: boolean;
  }[];
  placeholder?: string;
  onChange?: (value: string) => void;
  onFocusFunction?: () => void;
  onBlurFunction?: () => void;
  className?: string;
  initialValue?: string;
};

export const Dropdown = ({
  variant = "enabled",
  name,
  options = [
    { label: "option1", id: "1" },
    { label: "option2", id: "2" },
  ],
  placeholder = "Please select an option",
  onChange,
  onFocusFunction,
  onBlurFunction,
  className,
  initialValue,
  ...props
}: DropdownProps) => {
  const inputRef = useRef<HTMLSelectElement>(null);
  const [value, setValue] = useState(initialValue || "");

  const handleInputChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = e.target.value;
    setValue(selectedValue);
    onChange && onChange(selectedValue);
  };

  const handleOnFocus = () => {
    onFocusFunction && onFocusFunction();
  };

  const handleOnBlur = () => {
    onBlurFunction && onBlurFunction();
  };

  const inputClasses =
    "appearance-none cursor-pointer bg-no-repeat bg-right bg-contain h-[64px] w-full md:w-[320px] pl-7 pt-[22px] pl-[24px] pr-[48px] shadow-questionType rounded-lg border-2 hover:border-ui-black-25 flex flex-col text-base text-ui-black-75 leading-none focus:outline-none focus:shadow-outline";

  const statusClasses = statusConfig[variant] || "";

  return (
    <div className="w-full relative">
      <select
        name={name}
        id={name}
        disabled={variant === "disabled"}
        className={`${inputClasses} ${statusClasses} ${className}`}
        ref={inputRef}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        onChange={handleInputChange}
        style={{
          backgroundImage: `url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M0%207.63099L2.52261%205L11.9734%2014.504L21.4223%205L24%207.57769L11.9734%2019.6593L0%207.63099Z%22%20fill%3D%22%23131313%22%2F%3E%3C%2Fsvg%3E")`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "right 1.3rem top 50%",
          backgroundSize: "1rem auto",
        }}
        value={value}
        {...props}
      >
        {value === "" && (
          <option value="" disabled>
            {placeholder}
          </option>
        )}
        {options.map((option) => (
          <option key={option.id} value={option.label}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};
