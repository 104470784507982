import { api } from ".";

export const findLatestTransactionByUserId = async (
  userId: string,
) /* TODO: get type */ => {
  try {
    const response = await api.get(`/transaction/latest/${userId}`);
    return response.data;
  } catch (error) {
    throw new Error("Find latest transaction by user id failed");
  }
};
