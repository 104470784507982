// This function transforms the Transaction from the database into
// the state value used in the Transaction local storage object

import {
  transformTransactionPropertyState,
  transformTransactionSellerState,
} from "./index";
import { TransactionFormState } from "../store/TransactionForm/transactionForm";
import { TransactionResponse } from "@shared/types";

export const transformTransactionForLocalStorage = (
  transaction: TransactionResponse,
): Partial<TransactionFormState> => {
  return {
    ...transaction,
    primarySeller: transformTransactionSellerState(transaction.sellers),
    property: transformTransactionPropertyState(transaction),
  };
};
