import { AdminTransactionsForInvoicing } from "../../../pages/TransactionDetails/types";
import {
  CsvGroupedRecord,
  CsvRecord,
  YearAndMonth,
} from "../../../types/types";
import { getAddressAsString, formatDateWithNumbers } from "@shared/utils";
import { getLastDateOfMonth } from "./dateUtils";

export const createCsvRecord = (
  transaction: AdminTransactionsForInvoicing,
  invoicingAmount: string,
): CsvRecord => ({
  propertyAddress: getAddressAsString(transaction.property_id.address, true),
  date: formatDateWithNumbers(
    new Date(
      transaction.date_solicitor_groups_assigned.assigned_at,
    ).toISOString(),
  ),
  invoicingAmount: `£${invoicingAmount}`,
});

const createGroupCsv = (group: CsvGroupedRecord): string => {
  const header = createGroupHeader(group.lawyerGroup);
  const records = group.records.map(createCsvRecordLine).join("\n");
  const totalLine = createGroupTotalLine(group.total);

  return `${header}\n${records}\n${totalLine}\n`;
};

const createGroupHeader = (lawyerGroup: string): string =>
  `"${lawyerGroup}",,,`;

const createCsvRecordLine = (record: CsvRecord): string =>
  `"${record.propertyAddress}","${record.date}","${record.invoicingAmount}"`;

const createGroupTotalLine = (total: number): string =>
  `,,,£${total.toFixed(2)}`;

const createCsvFooter = (selectedPeriod: YearAndMonth): string => {
  const lastDate = getLastDateOfMonth(selectedPeriod);
  return `Payment terms: "3 days after Completion of the transaction."\nInvoice date: ${lastDate}\n`;
};

export const generateCsvString = (
  groupedRecords: CsvGroupedRecord[],
  selectedPeriod: YearAndMonth,
): string => {
  const csvContent = groupedRecords.map(createGroupCsv).join("\n");
  const footer = createCsvFooter(selectedPeriod);
  return `${csvContent}\n${footer}`;
};

export const createBlob = (
  csvString: string,
  selectedPeriod: YearAndMonth,
  errorCallback: (error: Error) => void,
): void => {
  try {
    const utf8Bom = "\uFEFF";
    const blob = new Blob([utf8Bom + csvString], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `Invoice Transactions ${[selectedPeriod.month]} ${selectedPeriod.year}.csv`;
    document.body.appendChild(link);
    link.click();
    link.remove();
    URL.revokeObjectURL(url);
  } catch (error) {
    errorCallback(error as Error);
  }
};
