import Add from "./SVG/Add.svg?react";
import Agreements from "./SVG/Agreements.svg?react";
import Alert from "./SVG/Alert.svg?react";
import ArrowDown from "./SVG/ArrowDown.svg?react";
import ArrowRight from "./SVG/ArrowRight.svg?react";
import ArrowUp from "./SVG/ArrowUp.svg?react";
import Boundaries from "./SVG/Boundaries.svg?react";
import Checkmark from "./SVG/Checkmark.svg?react";
import Close from "./SVG/Close.svg?react";
import Copy from "./SVG/Copy.svg?react";
import Currency from "./SVG/Currency.svg?react";
import Dashboard from "./SVG/Dashboard.svg?react";
import Dispute from "./SVG/Dispute.svg?react";
import DoubleArrow from "./SVG/DoubleArrow.svg?react";
import EmailSent from "./SVG/EmailSent.svg?react";
import Environmental from "./SVG/Environmental.svg?react";
import EstateAgentAssigned from "./SVG/EstateAgentAssigned.svg?react";
import Filter from "./SVG/Filter.svg?react";
import Flag from "./SVG/Flag.svg?react";
import FormWebsite from "./SVG/FormWebsite.svg?react";
import Freehold from "./SVG/Freehold.svg?react";
import FreeholdWhite from "./SVG/FreeholdWhite.svg?react";
import GreenTick from "./SVG/GreenTick.svg?react";
import Home from "./SVG/Home.svg?react";
import ID from "./SVG/ID.svg?react";
import IndividualOwner from "./SVG/IndividualOwner.svg?react";
import IndividualOwnerWhite from "./SVG/IndividualOwnerWhite.svg?react";
import Info from "./SVG/Info.svg?react";
import Insurance from "./SVG/Insurance.svg?react";
import Leasehold from "./SVG/Leasehold.svg?react";
import LeaseholdWhite from "./SVG/LeaseholdWhite.svg?react";
import Lightning from "./SVG/Lightning.svg?react";
import MatchSolicitor from "./SVG/MatchSolicitor.svg?react";
import Notices from "./SVG/Notices.svg?react";
import Occupiers from "./SVG/Occupiers.svg?react";
import OtherCharges from "./SVG/OtherCharges.svg?react";
import Parking from "./SVG/Parking.svg?react";
import PaymentSuccessful from "./SVG/PaymentSuccessful.svg?react";
import Placeholder from "./SVG/Placeholder.svg?react";
import Planning from "./SVG/Planning.svg?react";
import Plus from "./SVG/Plus.svg?react";
import PlusWhite from "./SVG/PlusWhite.svg?react";
import PropertyReport from "./SVG/PropertyReport.svg?react";
import PurchaseIntentCreated from "./SVG/PurchaseIntentCreated.svg?react";
import RaisingQuestion from "./SVG/RaisingQuestion.svg?react";
import Representative from "./SVG/Representative.svg?react";
import RepresentativeWhite from "./SVG/RepresentativeWhite.svg?react";
import Save from "./SVG/Save.svg?react";
import Search from "./SVG/Search.svg?react";
import Searches from "./SVG/Searches.svg?react";
import Services from "./SVG/Services.svg?react";
import Settings from "./SVG/Settings.svg?react";
import ShareOfFreehold from "./SVG/ShareOfFreehold.svg?react";
import ShareOfFreeholdWhite from "./SVG/ShareOfFreeholdWhite.svg?react";
import Sign from "./SVG/Sign.svg?react";
import SignOut from "./SVG/SignOut.svg?react";
import SolicitorAssigned from "./SVG/SolicitorAssigned.svg?react";
import Stress from "./SVG/Stress.svg?react";
import Switch from "./SVG/Switch.svg?react";
import TermsAccepted from "./SVG/TermsAccepted.svg?react";
import Transaction from "./SVG/Transaction.svg?react";
import TransactionCreated from "./SVG/TransactionCreated.svg?react";
import TrustPilotStar from "./SVG/TrustPilotStar.svg?react";
import Upload from "./SVG/Upload.svg?react";
import UserRegistered from "./SVG/UserRegistered.svg?react";
import Utilities from "./SVG/Utilities.svg?react";
import Warning from "./SVG/Warning.svg?react";
import Warranty from "./SVG/Warranty.svg?react";

const iconComponents = {
  Add,
  Agreements,
  Alert,
  ArrowDown,
  ArrowRight,
  ArrowUp,
  Boundaries,
  Checkmark,
  Close,
  Copy,
  Currency,
  Dashboard,
  Dispute,
  DoubleArrow,
  EmailSent,
  Environmental,
  EstateAgentAssigned,
  Filter,
  Flag,
  FormWebsite,
  Freehold,
  FreeholdWhite,
  GreenTick,
  Home,
  ID,
  IndividualOwner,
  IndividualOwnerWhite,
  Info,
  Insurance,
  Leasehold,
  LeaseholdWhite,
  Lightning,
  MatchSolicitor,
  Notices,
  Occupiers,
  OtherCharges,
  Parking,
  PaymentSuccessful,
  Placeholder,
  Planning,
  Plus,
  PlusWhite,
  PropertyReport,
  PurchaseIntentCreated,
  RaisingQuestion,
  Representative,
  RepresentativeWhite,
  Save,
  Search,
  Searches,
  Services,
  Settings,
  ShareOfFreehold,
  ShareOfFreeholdWhite,
  Sign,
  SignOut,
  SolicitorAssigned,
  Stress,
  Switch,
  TermsAccepted,
  Transaction,
  TransactionCreated,
  TrustPilotStar,
  Upload,
  UserRegistered,
  Utilities,
  Warning,
  Warranty,
};

export type TextInputIconProps =
  | "Add"
  | "Agreements"
  | "Alert"
  | "ArrowDown"
  | "ArrowRight"
  | "ArrowUp"
  | "Boundaries"
  | "Checkmark"
  | "Close"
  | "Copy"
  | "Currency"
  | "Dashboard"
  | "Dispute"
  | "DoubleArrow"
  | "EmailSent"
  | "Environmental"
  | "EstateAgentAssigned"
  | "Filter"
  | "Flag"
  | "FormWebsite"
  | "Freehold"
  | "FreeholdWhite"
  | "GreenTick"
  | "Home"
  | "ID"
  | "IndividualOwner"
  | "IndividualOwnerWhite"
  | "Info"
  | "Insurance"
  | "Leasehold"
  | "LeaseholdWhite"
  | "Lightning"
  | "MatchSolicitor"
  | "Notices"
  | "Occupiers"
  | "OtherCharges"
  | "Parking"
  | "PaymentSuccessful"
  | "Placeholder"
  | "Planning"
  | "Plus"
  | "PlusWhite"
  | "PropertyReport"
  | "PurchaseIntentCreated"
  | "RaisingQuestion"
  | "Representative"
  | "RepresentativeWhite"
  | "Save"
  | "Search"
  | "Searches"
  | "Services"
  | "Settings"
  | "ShareOfFreehold"
  | "ShareOfFreeholdWhite"
  | "Sign"
  | "SignOut"
  | "SolicitorAssigned"
  | "Stress"
  | "Switch"
  | "TermsAccepted"
  | "Transaction"
  | "TransactionCreated"
  | "TrustPilotStar"
  | "Upload"
  | "UserRegistered"
  | "Utilities"
  | "Warning"
  | "Warranty";

export type IconsProps = {
  colour?:
    | "enabled"
    | "disabled"
    | "success"
    | "error"
    | "complement"
    | "electric"
    | "highlight"
    | "warm"
    | string;
  size?: string;
  onClickFunction?: () => void;
  iconName: TextInputIconProps;
};

const Icons = ({
  colour,
  iconName,
  onClickFunction,
  size = "16",
  ...props
}: IconsProps) => {
  const IconComponent = iconComponents[iconName];

  if (!IconComponent) {
    return null;
  }

  let colourFill: string;

  switch (colour) {
    case "enabled":
      colourFill = "#000000";
      break;
    case "disabled":
      colourFill = "#B04141";
      break;
    case "success":
      colourFill = "#41B057";
      break;
    case "error":
      colourFill = "#B04141";
      break;
    case "complement":
      colourFill = "#FFFFFF";
      break;
    case "electric":
      colourFill = "#C1FF5C";
      break;
    case "highlight":
      colourFill = "#F9D34F";
      break;
    case "warm":
      colourFill = "#FD9B54";
      break;
    default:
      colourFill = colour || "#01323C";
      break;
  }

  return (
    <IconComponent
      width={size}
      height={size}
      fill={colourFill}
      stroke={iconName === "Close" ? colourFill : ""}
      onClick={onClickFunction}
      {...props}
    />
  );
};

export default Icons;
