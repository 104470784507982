import { LinkWrapperProps } from "./LinkWrapper.types";

export const LinkWrapper = ({
  button = false,
  children,
  className,
  ...props
}: LinkWrapperProps) => {
  const buttonStyles = `${className} text-base rounded-lg px-[32px] w-96 h-12 flex leading-default items-center justify-center focus:outline-none whitespace-nowrap bg-brand-heavy-teal-100 text-ui-white-100 hover:bg-brand-heavy-teal-75 cursor-pointer`;

  const linkStyles = `${className} text-inherit underline cursor-pointer`;

  return (
    <span className={button ? buttonStyles : linkStyles} {...props}>
      {children}
    </span>
  );
};
