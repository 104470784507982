import { api, transformTransactionForApi } from ".";
import { TransactionResponse } from "@shared/types";
import { TransactionFormState } from "../../store/TransactionForm/transactionForm";

export const createTransaction = async (
  transaction: TransactionFormState,
): Promise<{ transaction: TransactionResponse }> => {
  try {
    const response = await api.post(
      "/transaction",
      transformTransactionForApi(transaction),
    );
    return response.data;
  } catch (error) {
    throw new Error("Create transaction failed");
  }
};
