import { DateInputValue } from "./types";

export const transformDateForDateInput = (
  date: Date | undefined,
): DateInputValue => {
  if (!date) {
    return { day: undefined, month: undefined, year: undefined };
  }

  const dayValue = date.getDate();
  const monthValue = date.getMonth() + 1;

  return {
    day: dayValue < 10 ? `0${dayValue}` : dayValue.toString(),
    month: monthValue < 10 ? `0${monthValue}` : monthValue.toString(),
    year: date.getFullYear().toString(),
  };
};

export const dateFormInputToIsoDate = (
  dateInput: DateInputValue,
): string | undefined => {
  const { day, month, year } = dateInput;

  if (!day || !month || !year) {
    return undefined;
  }

  return new Date(`${month}-${day}-${year}`).toISOString();
};
