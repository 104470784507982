import Typography from "@/foundation/Typography/Typography";
import { useTabletMediaQuery } from "../../../mediaQueries";

interface StepProps {
  title: string;
  content: string;
  imageUrl: string;
}

export const Step = ({ title, content, imageUrl }: StepProps) => {
  const isTablet = useTabletMediaQuery();

  return (
    <div
      className={`px-[16px] w-full max-w-[300px] mb-[60px] flex flex-col ${isTablet ? "items-start" : "items-center"}`}
    >
      <div className="mb-[16px] w-[88px]">
        <img src={imageUrl} />
      </div>

      <div className="">
        <Typography
          className={`text-brand-heavy-teal-100 mb-[8px] ${isTablet ? "text-left" : "text-center"}`}
          type="h3"
          variant="lg"
          weight="bold"
        >
          {title}
        </Typography>

        <Typography
          className={`text-brand-heavy-teal-100 mb-[16px] ${isTablet ? "text-left" : "text-center"}`}
          type="p"
          variant="base"
          weight="regular"
        >
          {content}
        </Typography>
      </div>
    </div>
  );
};
