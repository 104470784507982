import {
  FormikErrors,
  FormikTouched,
  useField,
  useFormikContext,
} from "formik";
import { DateInputPart } from "./DateInputPart";
import ErrorText from "@/foundation/Typography/ErrorText";
import { FieldSet } from "../../Form/FieldSet/FieldSet";
import { DateInputValue } from "./types";

export const DateInput = ({
  namespace,
  label,
}: {
  namespace: string;
  label: string;
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, meta] = useField(namespace);
  const { errors, touched } = useFormikContext();

  const datesTouched: DateInputValue = touched[
    namespace as keyof FormikTouched<unknown>
  ] as DateInputValue;

  const displayErrors = () => {
    if (touched[namespace as keyof FormikTouched<unknown>]) {
      const dayTouchedOrHasValue = datesTouched?.day || !!meta.initialValue.day;

      const monthTouchedOrHasValue =
        datesTouched?.month || !!meta.initialValue.month;

      const yearTouchedOrHasValue =
        datesTouched?.year || !!meta.initialValue.year;

      return (
        (dayTouchedOrHasValue &&
          errors[(namespace + ".day") as keyof FormikErrors<unknown>]) ||
        (monthTouchedOrHasValue &&
          errors[(namespace + ".month") as keyof FormikErrors<unknown>]) ||
        (yearTouchedOrHasValue &&
          errors[(namespace + ".year") as keyof FormikErrors<unknown>]) ||
        (dayTouchedOrHasValue &&
          monthTouchedOrHasValue &&
          yearTouchedOrHasValue &&
          errors[namespace as keyof FormikErrors<unknown>])
      );
    }
    return "";
  };

  return (
    <FieldSet legend={label}>
      <div className="flex space-x-[16px]">
        <DateInputPart
          name={namespace + ".day"}
          placeholder="DD"
          variant={displayErrors() ? "error" : "enabled"}
        />
        <DateInputPart
          name={namespace + ".month"}
          placeholder="MM"
          variant={displayErrors() ? "error" : "enabled"}
        />
        <DateInputPart
          name={namespace + ".year"}
          placeholder="YYYY"
          variant={displayErrors() ? "error" : "enabled"}
          isYear
        />
      </div>

      <div className="h-[16px]">
        <ErrorText>{displayErrors()}</ErrorText>
      </div>
    </FieldSet>
  );
};
