import { Link } from "react-router-dom";
import { Buyer, Giftors, Seller } from "../pages/TransactionDetails/types";
import { Table, TableProps } from "./Table/Table";
import { UserInformation } from "./UserInformation/UserInformation";
import Typography from "@/foundation/Typography/Typography";
import { useEffect, useState } from "react";
import { formatCurrency } from "@shared/utils";

interface BuyerSellerBlockProps {
  buyerSeller: Seller | Buyer;
  isSeller?: boolean;
}

const GiftorBlock = ({ giftor }: { giftor: Giftors }) => {
  const [data, setData] = useState<TableProps[]>([]);

  useEffect(() => {
    setData([
      { label: "First Name", value: giftor.first_name },
      { label: "Last Name", value: giftor.last_name },
      { label: "Email", value: giftor.email },
      { label: "Phone Number", value: giftor.phone_number },
      { label: "Gift Amount", value: formatCurrency(giftor.amount, true) },
      { label: "Relationship", value: giftor.relationship },
    ]);
  }, [giftor]);

  return (
    <>
      {data.map(({ value, label }) => (
        <div key={`giftors-${label}`}>
          <Table value={value} label={label} />
        </div>
      ))}
    </>
  );
};

export const BuyerSellerBlock = ({
  buyerSeller,
  isSeller = false,
}: BuyerSellerBlockProps) => {
  const { user, primary_customer, customer_type } = buyerSeller;
  const giftors = "giftors" in buyerSeller ? buyerSeller.giftors : [];
  return (
    <UserInformation
      title={`${user.first_name} ${user.last_name}`}
      user={user}
      preContent={
        <>
          <Table
            key={`seller-primary-customer`}
            label={"Primary Customer"}
            value={primary_customer.toString()}
          />
          {isSeller && (
            <Table
              key={`seller-customer-type`}
              label={"Customer Type"}
              value={
                customer_type === "individual_owner"
                  ? "Seller / Owner"
                  : "Representative"
              }
            />
          )}
          <hr className="text-brand-heavy-warmth-75 my-[20px]" />
        </>
      }
      postContent={
        <>
          {user._id && (
            <Link to={`/admin/users/${user._id}`}>
              <Typography
                weight="semi-bold"
                className="text-brand-heavy-teal-100 underline hover:text-brand-heavy-teal-75 cursor-pointer mt-[24px]"
              >
                View more user details →
              </Typography>
            </Link>
          )}

          {giftors && giftors.length > 0 && (
            <>
              <hr className="text-brand-heavy-warmth-75 my-[20px]" />
              {giftors.map((giftor, index) => (
                <div key={`giftor-${index}`}>
                  <Typography
                    type="p"
                    variant="lg"
                    weight="bold"
                    className="text-brand-heavy-teal-100 w-[505px] my-[32px]"
                  >{`Giftor ${index + 1}`}</Typography>
                  <GiftorBlock giftor={giftor} key={`giftor-${index}`} />
                </div>
              ))}
            </>
          )}
        </>
      }
    />
  );
};
