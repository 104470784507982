import { useEffect, useState } from "react";
import { Link as ScrollToLink } from "react-scroll";

import { Container } from "../../../components/Container/Container";
import Icon from "@/foundation/Icons/Icons";
import Typography from "@/foundation/Typography/Typography";
import {
  useTabletMediaQuery,
  useDesktopMediaQuery,
  useWideDesktopMediaQuery,
} from "../../../mediaQueries";

import {
  WideDesktopHeroImage,
  DesktopHeroImage,
  TabletHeroImage,
  MobileHeroImage,
} from "../../../assets/marketing";

export const Hero = () => {
  const isTablet = useTabletMediaQuery();
  const isDesktop = useDesktopMediaQuery();
  const isWideDesktop = useWideDesktopMediaQuery();
  const [heroImageUrl, setHeroImageUrl] = useState<string>("");

  useEffect(() => {
    if (isWideDesktop) {
      setHeroImageUrl(WideDesktopHeroImage);
    } else if (isDesktop) {
      setHeroImageUrl(DesktopHeroImage);
    } else if (isTablet) {
      setHeroImageUrl(TabletHeroImage);
    } else {
      setHeroImageUrl(MobileHeroImage);
    }
  }, [isWideDesktop, isDesktop, isTablet]);

  const marketingButtonStyles =
    "text-base rounded-lg px-[32px] h-12 w-full max-w-[300px] text-brand-heavy-teal-100 bg-brand-electric-100 hover:bg-brand-heavy-teal-75 flex leading-default items-center justify-center focus:outline-none cursor-pointer";

  return (
    <div className="relative top-0 bg-ui-black-100 h-[720px] md:h-[800px]">
      <img
        src={
          isDesktop
            ? DesktopHeroImage
            : isTablet
              ? TabletHeroImage
              : MobileHeroImage
        }
        alt="Hero"
        className="absolute bottom-0 w-full"
      />
      <img src={heroImageUrl} alt="Hero" className="absolute bottom-0 w-full" />

      <Container maxWidth={1440}>
        <div className="absolute pt-[140px] px-[24px] md:px-[40px] md:pt-[160px] lg:px-[140px] lg:pt-[200px]">
          <Typography
            className="font-display text-ui-white-100 mb-[32px] max-w-[650px]"
            type="h1"
            variant={
              isDesktop ? "marketingTitle" : isTablet ? "marketingTitle" : "h2"
            }
            weight="semi-bold"
          >
            The only quick & easy way to sell your home
          </Typography>

          <ul className="mb-[100px] md:mb-[60px] lg:b-[40px]">
            <li className="flex">
              <Icon iconName="Checkmark" colour="warm" size="24" />
              <Typography
                type="p"
                variant="lg"
                weight="regular"
                className="text-ui-white-100 ml-[16px] mb-[24px]"
              >
                Attract the most motivated buyers
              </Typography>
            </li>

            <li className="flex">
              <Icon iconName="Checkmark" colour="warm" size="24" />
              <Typography
                type="p"
                variant="lg"
                weight="regular"
                className="text-ui-white-100 ml-[16px] mb-[24px]"
              >
                Eliminate fall-throughs and heartache
              </Typography>
            </li>

            <li className="flex">
              <Icon iconName="Checkmark" colour="warm" size="24" />
              <Typography
                type="p"
                variant="lg"
                weight="regular"
                className="text-ui-white-100 ml-[16px] mb-[24px]"
              >
                Help your agent get you the best price
              </Typography>
            </li>
          </ul>

          <ScrollToLink
            to="contact"
            smooth
            duration={1000}
            className={marketingButtonStyles}
          >
            Sell my home the Propelr way
          </ScrollToLink>
        </div>
      </Container>
    </div>
  );
};
