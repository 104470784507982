import { useEffect, useState } from "react";

import { Button } from "@/components/Button/Button";
import TextInput from "@/components/Input/TextInput";
import ErrorText from "@/foundation/Typography/ErrorText";
import { invalidPostcodeErrorMessage } from "./errorMessages";
import { isValidPostcode } from "./utils";
import { formatPostcode } from "@shared/utils";

interface PostcodeInputProps {
  handlePostcodeLookup: (postcode: string) => void;
  isBuyer?: boolean;
}

export const PostcodeInput = ({
  handlePostcodeLookup,
  isBuyer,
}: PostcodeInputProps) => {
  const [inputPostcode, setInputPostcode] = useState("");
  const [error, setError] = useState<string>("");
  const [hasSearched, setHasSearched] = useState(false);

  const onBeginHandlePostcodeLookup = () => {
    setError("");
    setHasSearched(true);

    if (isValidPostcode(inputPostcode)) {
      handlePostcodeLookup(inputPostcode);
    } else {
      setError(invalidPostcodeErrorMessage);
    }
  };

  useEffect(() => {
    setError("");
  }, [inputPostcode]);

  return (
    <>
      <div className="w-full flex">
        <div className="w-full flex flex-col gap-[16px] md:flex-row md:flex-wrap">
          <TextInput
            showIcon={true}
            icon="Search"
            className="md:w-[320px]"
            name="postcode"
            variant={error ? "error" : "enabled"}
            label="Search by postcode"
            onChange={(newVal) =>
              setInputPostcode(formatPostcode(newVal.target.value))
            }
            onBlur={() => {
              if (isBuyer && !inputPostcode) {
                setError("Required");
              }
              if (isBuyer && inputPostcode && !hasSearched) {
                setError("Please search for an address");
              }
            }}
            value={inputPostcode}
          />

          <Button
            size="2xl"
            variant="primary"
            disabled={!inputPostcode.trim()}
            type="button"
            onClick={onBeginHandlePostcodeLookup}
            className="w-full md:w-[200px] h-[64px] md:mb-[0px] mb-[16px]"
          >
            Find my address
          </Button>
        </div>
      </div>

      {error && <ErrorText>{error}</ErrorText>}
    </>
  );
};
