export const buyerItems = {
  firstLine: [
    {
      id: 1,
      icon: "Transaction",
      subtitle: "Free legals and searches worth £2,500+",
      description: "",
    },
    {
      id: 2,
      icon: "Stress",
      subtitle: "Buy with confidence with award-winning solicitors",
      description: "",
    },
  ],
  secondLine: [
    {
      id: 3,
      subtitle: "Remarkably fast",
      description:
        "Fantastic service. As soon as I put the offer in, it was evident that the legal groundwork had already been done. The Propelr team was very responsive, and the process itself was remarkably fast. I am very pleased and highly recommend Propelr to anyone in search of a smooth and hassle-free property experience.",
    },
    {
      id: 4,
      subtitle: "A brilliant service",
      description:
        "A brilliant service. Exchanging contracts was extremely quick and efficient. Two weeks from start to finish.",
    },
  ],
};

export const sellerItems = {
  firstLine: [
    {
      id: 1,
      icon: "Lightning",
      subtitle: "Sell 80% faster than the market",
      description: "",
    },
    {
      id: 2,
      icon: "Stress",
      subtitle: "Sell without fear of fall throughs",
      description: "",
    },
  ],
  secondLine: [
    {
      id: 3,
      subtitle: "Propelr's a no brainer",
      description:
        "From the moment we instructed Propelr to exchange of contracts we couldn't believe how seamless the process was.",
    },
    {
      id: 4,
      subtitle: "A brilliant service",
      description:
        "Having all of the survey information, searches and title information pre-prepared made exchanging contracts extremely quick and efficient. Two weeks from start to finish.",
    },
  ],
};
