import Typography from "../../foundation/Typography/Typography";
import { CheckboxProps } from "../Checkbox/Checkbox.types";

const Checkbox = ({
  label,
  variant = "enabled",
  name,
  isChecked = false,
  handleCheckedInput,
  ...props
}: CheckboxProps) => {
  const style =
    "flex min-w-[80px] px-[24px] py-[32px] leading-default cursor-pointer items-center justify-center text-base font-normal border-2 rounded-lg focus:outline-none hover:border-brand-heavy-teal-100";
  const checkedStyle =
    "bg-brand-heavy-teal-100 text-ui-white-100 border-brand-heavy-teal-100";
  return (
    <label
      className={`${style} ${isChecked ? checkedStyle : "bg-ui-white-100 border-ui-white-100"}`}
    >
      <div className="relative h-[40px]">
        <input
          type="checkbox"
          name={name}
          disabled={variant === "disabled"}
          checked={isChecked}
          className="appearance-none"
          onChange={handleCheckedInput}
          {...props}
        />
      </div>

      <Typography
        type="p"
        variant="base"
        weight="regular"
        className={`${isChecked ? "text-ui-white-100" : "text-brand-heavy-teal-100"}`}
      >
        {label}
      </Typography>
    </label>
  );
};

export default Checkbox;
