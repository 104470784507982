import { Field, FieldProps } from "formik";

import { TextArea } from "@/components/Input/TextArea";
import Typography from "@/foundation/Typography/Typography";
import { Roles } from "@shared/types";

interface CustomiseEmailProps {
  isDisabled: boolean;
}
export const CustomiseEmail = ({ isDisabled }: CustomiseEmailProps) => (
  <>
    <Typography
      type="h3"
      variant="h3"
      weight="bold"
      className="text-brand-heavy-teal-100 mb-[16px] mt-[40px]"
    >
      Customise email to solicitors
    </Typography>
    {[Roles.Seller, Roles.Buyer].map((role) => (
      <div key={role}>
        <Typography
          type="h2"
          variant="xl"
          weight="bold"
          className="text-brand-heavy-teal-100 my-[16px]"
        >
          {`Comments to ${role}'s Solicitor`}
        </Typography>
        <Typography
          type="p"
          variant="base"
          weight="regular"
          className="text-brand-heavy-teal-100 mb-[16px]"
        >
          These will not appear to the customer.
        </Typography>
        <Field name={`${role.toLowerCase()}_solicitor_comments`}>
          {({ field }: FieldProps) => (
            <TextArea
              {...field}
              id={`${role.toLowerCase()}_solicitor_comments`}
              name={`${role.toLowerCase()}_solicitor_comments`}
              label="Add comments"
              disabled={isDisabled}
              variant={isDisabled ? "disabled" : "enabled"}
            />
          )}
        </Field>
      </div>
    ))}
  </>
);
