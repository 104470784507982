import * as Sentry from "@sentry/react";

import { api } from ".";

export const findProperties = async (postcode: string) => {
  try {
    const response = await api.get(`/property/${postcode}`);
    return response.data;
  } catch (error) {
    Sentry.captureException(error);

    throw new Error("Property not found");
  }
};
