import ErrorText from "@/foundation/Typography/ErrorText";
import Typography from "@/foundation/Typography/Typography";
import { useLoginStore } from "../../store/Login/login";
import { SignUpType } from "@schema";

interface SignUpSuccessProps {
  handleSignUp: (values: SignUpType) => void;
  setLessThan5Min: (value: boolean) => void;
  lessThan5Min: boolean;
}

export const SignUpSuccess = ({
  handleSignUp,
  lessThan5Min,
  setLessThan5Min,
}: SignUpSuccessProps) => {
  const { firstName, middleName, lastName, email } = useLoginStore();

  const handleDelay = () => {
    const currentTime = Date.now();
    const lastClickTime = localStorage.getItem("lastClickTime");

    if (lastClickTime && currentTime - Number(lastClickTime) >= 1 * 60 * 1000) {
      handleSignUp({ firstName, middleName, lastName, email });
      localStorage.setItem("lastClickTime", String(currentTime));
      setLessThan5Min(false);
    } else {
      setLessThan5Min(true);
    }
  };

  return (
    <div className="flex flex-col items-center text-center">
      <Typography
        type="h1"
        variant="h2"
        weight="regular"
        className="font-display"
      >
        Success!
      </Typography>

      <Typography
        type="h2"
        variant="h3"
        weight="regular"
        className="font-display mt-[32px]"
      >
        We&apos;ve emailed you a magic link to sign-in.
      </Typography>

      <Typography
        type="p"
        variant="xl"
        weight="regular"
        className="text-ui-heavy-teal-75 mt-8"
      >
        It will arrive within 5 minutes.
      </Typography>

      <div className="text-ui-black-50 flex flex-col items-center mt-[16px]">
        <div className="text-center mb-[8px]">
          <Typography type="p" variant="sm" weight="regular">
            <span className="mr-[4px]">
              Didn&apos;t receive an email? Check your spam.
            </span>

            <button className="underline" onClick={handleDelay}>
              Send email again
            </button>
          </Typography>
        </div>

        {lessThan5Min && (
          <ErrorText>
            Please wait at least 5 minutes before trying again.
          </ErrorText>
        )}
      </div>
    </div>
  );
};
