import Typography from "@/foundation/Typography/Typography";
import { WelcomeCard } from "@/components/WelcomeCard/WelcomeCard";

export const SellerWelcomeMessage = () => (
  <div className="flex flex-col items-start mt-[48px]">
    <Typography
      type="h2"
      variant="h3"
      weight="bold"
      className="text-brand-heavy-teal-100 font-display max-w-[750px] mb-[24px]"
    >
      By choosing Propelr you can sell your property quickly, easily and
      confidently
    </Typography>

    <div className="md:grid md:grid-cols-2 lg:md:grid-cols-3 gap-[20px]">
      <WelcomeCard
        description="Traditional, non-Propelr sales take up to 6 months from offer to exchange. Propelr is 80-95% faster than the UK average. Even if you are not in a rush at the moment, your buyers usually are and the longer the process takes the higher the chance of a fall through or a price renegotiation."
        title="Cut up to 6 months from your property sale"
        iconName="Searches"
      />

      <WelcomeCard
        description="Almost 40% of typical, non-Propelr sales fall through, often months into the process. Propelr protects you against wasting time and money by eliminating fall throughs entirely."
        title="Prevent last minute fall-throughs"
        iconName="RaisingQuestion"
      />

      <WelcomeCard
        description="Most conveyancers are uncommunicative and unreliable, while cheap quotes hide surprise extras. With Propelr, the legal process is personal, with transparent pricing."
        title="Enjoy award-winning conveyancing"
        iconName="PropertyReport"
      />
    </div>

    <Typography
      type="p"
      variant="lg"
      weight="regular"
      className="text-brand-heavy-teal-75 mb-[24px] max-w-[780px]"
    >
      Over the following few pages we&apos;re going to collect some information
      about you and your sale which will help your solicitor onboard you faster.
      This should take no more than 5 minutes.
    </Typography>

    <Typography
      type="p"
      variant="lg"
      weight="regular"
      className="text-brand-heavy-teal-75"
    >
      Click Start to begin.
    </Typography>
  </div>
);
