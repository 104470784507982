import Step1 from "../../../assets/steps/step1.svg";
import Step2 from "../../../assets/steps/step2.svg";
import Step3 from "../../../assets/steps/step3.svg";

export const steps = [
  {
    imageUrl: Step1,
    title: "Sign up with Propelr",
    content:
      "Use the form below to get going. We'll ask you some questions about yourself and your property.",
  },
  {
    imageUrl: Step2,
    title: "Onboard with your solicitor",
    content:
      "Kick off your legals with a solicitor from Propelr's panel of award winning conveyancing firms.",
  },
  {
    imageUrl: Step3,
    title: "Exchange and complete",
    content:
      "Review your solicitor's advice. Find the right buyer. Get ready to exchange.",
  },
];
