import { useNavigate } from "react-router-dom";

import { Nav } from "../../components/Nav/Nav";
import Typography from "@/foundation/Typography/Typography";
import { Container } from "../../components/Container/Container";
import { Button } from "@/components/Button/Button";

import BackgroundImage from "../../assets/layer2.png";

export const PageNotFound = () => {
  const navigate = useNavigate();

  return (
    <div
      className="bg-brand-heavy-warmth-75 flex flex-col"
      style={{
        backgroundImage: `url(${BackgroundImage})`,
        backgroundPosition: "center 190px",
        backgroundRepeat: "no-repeat",
        backgroundSize: "622px 624px",
        backgroundAttachment: "fixed",
        minHeight: "100vh",
      }}
    >
      <Nav />
      <Container>
        <div className="flex flex-col my-64 h-max items-center">
          <Typography
            type="h1"
            variant="title"
            weight="bold"
            className=" text-brand-heavy-teal-100 font-display text-center mb-[24px]"
            data-testid="header-title"
          >
            404! Page not found
          </Typography>

          <Typography
            type="p"
            variant="lg"
            weight="bold"
            className=" text-brand-heavy-teal-100 mb-[36px]"
          >
            We can&apos;t find that page. Please check the URL or go back to the
            homepage and try again.
          </Typography>

          <Button
            type="button"
            size="xl"
            variant="primary"
            onClick={() => navigate("/")}
          >
            Go to homepage
          </Button>
        </div>
      </Container>
    </div>
  );
};
