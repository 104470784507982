import { ComponentProps, useRef } from "react";

import Icons from "../../foundation/Icons/Icons";
import { statusConfig } from "./statusConfig";

export type CurrencyInputProps = ComponentProps<"input"> & {
  className?: string;
  handleChange?: (value: string) => void;
  name: string;
  value: string | null;
  minimumValue: string;
  variant?: "enabled" | "disabled" | "success" | "error";
  placeholder?: string;
};

const addCommasForThousands = (value: string | number | null): string => {
  if (!value) {
    return "";
  }

  if (typeof value === "number") {
    return value.toLocaleString("en-GB");
  } else {
    const numericValue = parseFloat(value.replace(/[^\d.]/g, ""));
    return numericValue.toLocaleString("en-GB");
  }
};

export const CurrencyInput = ({
  className,
  name,
  minimumValue,
  value = minimumValue,
  variant = "enabled",
  placeholder,
  ...props
}: CurrencyInputProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const inputClasses =
    "w-[100%] h-[64px] pt-[11px] pb-[11px] pr-[14px] shadow-questionType rounded-lg border-2 hover:border-ui-black-25 flex flex-col text-base text-ui-black-75 leading-none focus:outline-none focus:shadow-outline pl-[52px]";

  const statusClasses = statusConfig[variant] || "";

  return (
    <div className="relative w-full md:w-[320px]">
      <input
        name={name}
        disabled={variant === "disabled"}
        value={addCommasForThousands(value)}
        className={`${inputClasses} ${statusClasses} ${className && className}`}
        ref={inputRef}
        type="text"
        onKeyDown={(e) => {
          if (
            !(
              e.key === "Backspace" ||
              e.key === "Tab" ||
              e.key === "ArrowLeft" ||
              e.key === "ArrowRight"
            ) &&
            (e.key < "0" || e.key > "9")
          ) {
            e.preventDefault();
          }
        }}
        placeholder={placeholder}
        {...props}
      />

      <div className="absolute top-[8px] left-[4px] m-[16px]">
        <Icons iconName="Currency" colour={variant} />
      </div>
    </div>
  );
};
