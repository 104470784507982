import { useEffect, useState } from "react";

import { Button } from "@/components/Button/Button";
import { Card } from "@/components/Card/Card";
import Typography from "@/foundation/Typography/Typography";

import { OrderSearchModal } from "../Modals/OrderSearchModal";
import { Address } from "../../pages/UserDetails/types";
import { AdminTransaction } from "../../pages/TransactionDetails/types";
import { ApiController } from "../../../service/ApiController";

interface SearchesTabProps {
  propertyAddress: Address;
  transactionId: string;
  onSearchesOrdered: (transaction: AdminTransaction) => void;
  lawyerGroupPresentOnTransaction?: boolean;
}

export const SearchesTab = ({
  propertyAddress,
  transactionId,
  lawyerGroupPresentOnTransaction,
  onSearchesOrdered,
}: SearchesTabProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchOrdered, setIsSearchOrdered] = useState(false);
  const [isTransactionPaid, setIsTransactionPaid] = useState(false);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetchEvents() {
      setLoading(true);
      try {
        const transactionEvents =
          await ApiController.fetchTransactionEvent(transactionId);

        setIsSearchOrdered(
          transactionEvents.some(
            (event) => event.event_name === "search_ordered",
          ),
        );

        setIsTransactionPaid(
          transactionEvents.some(
            (event) => event.event_name === "payment_successful",
          ),
        );
      } catch (error) {
        console.log(JSON.stringify(error));
      }

      setLoading(false);
    }

    fetchEvents();
  }, [transactionId]);

  return (
    <>
      <Card>
        <div className="flex flex-col items-center">
          <Typography variant="lg" weight="semi-bold" className="mb-[16px]">
            {searchOrdered
              ? "Searches have been ordered for this transaction"
              : "No search has been ordered yet."}
          </Typography>
          <div className="mt-[24px]">
            {!searchOrdered && (
              <Button
                variant="primary"
                onClick={() => setIsModalOpen(true)}
                disabled={
                  loading ||
                  !lawyerGroupPresentOnTransaction ||
                  !isTransactionPaid
                }
              >
                Order Search
              </Button>
            )}
          </div>
        </div>
      </Card>
      {isModalOpen && (
        <OrderSearchModal
          onClose={() => setIsModalOpen(false)}
          propertyAddress={propertyAddress}
          transactionId={transactionId}
          onSearchesOrdered={onSearchesOrdered}
        />
      )}
    </>
  );
};
