import {
  useEffect,
  createContext,
  useContext,
  useRef,
  useCallback,
  useMemo,
} from "react";
import { createAuthStore } from "./auth";

export type AuthContextType = {
  authStore: typeof createAuthStore;
  reinitializeAuth: () => void;
} | null;

export const AuthContext = createContext<AuthContextType>(null);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const authStoreRef = useRef(createAuthStore);

  // Expose a method to reinitialize the auth store, which can be called after login
  const reinitializeAuth = useCallback(() => {
    authStoreRef.current.getState().actions.initialize();
  }, []);

  useEffect(() => {
    authStoreRef.current.getState().actions.initialize();
  }, [authStoreRef]);

  // Pass the reinitializeAuth method through context so it can be called after user login
  const contextValue = useMemo(
    () => ({
      authStore: authStoreRef.current,
      reinitializeAuth,
    }),
    [reinitializeAuth],
  );

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
