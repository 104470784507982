import { NavigateFunction } from "react-router-dom";

import { ApiController } from "../service/ApiController";
import { Actions } from "../store/auth/auth";
import { AuthContextType } from "../store/auth/AuthContext";

export const handleSignOut = async (
  navigate: NavigateFunction,
  authStore: Actions,
  authContext: AuthContextType,
): Promise<void> => {
  // Destroy the session on the backend
  await ApiController.signOut();

  // Reset the auth store and reinitialize the auth context to clear the user data
  authStore.reset();
  authContext?.reinitializeAuth();

  // Redirect the user to the sign-in
  navigate("/sign-in?sign-out=true", { replace: true });
};
