import * as Sentry from "@sentry/react";

import { api } from "../../../service/ApiController";
import { operations } from "@shared/types.d";

type EstateAgentsApiResponse =
  operations["EstateAgentController_getEstateAgentById"]["responses"]["200"]["content"]["application/json"];

export const findEstateAgent = async (
  _id: string,
): Promise<EstateAgentsApiResponse> => {
  try {
    const response = await api.get(`/estate-agent/${_id}`);
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    throw new Error("There was an error finding estate agent");
  }
};
