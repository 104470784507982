import { ComponentProps } from "react";
import Icons from "../../foundation/Icons/Icons";
import Typography from "../../foundation/Typography/Typography";

export type StepperProps = ComponentProps<"div"> & {
  variant?: "success" | "active" | "static";
  time?: string;
  details?: string;
};

const statusConfig = {
  success: " bg-brand-heavy-teal-100",
  active: " bg-brand-heavy-teal-100",
  static: " bg-transparent",
};

const Stepper = ({
  time = "1 min",
  details = "Personal details",
  variant = "active",
  ...props
}: StepperProps) => {
  const stepperClass =
    "w-[32px] h-[32px] border-brand-heavy-teal-100 border border-1 rounded-full flex flex-col justify-center items-center shrink-0";

  const statusClasses = statusConfig[variant] || "";

  return (
    <div className="relative flex items-center">
      <div {...props} className={`${statusClasses} ${stepperClass}`}>
        {variant === "success" && (
          <Icons iconName="Checkmark" colour="complement" />
        )}
      </div>

      <div className="flex flex-col ml-2 text-ui-black-100">
        <Typography type="p" variant="xs" weight="bold">
          {time}
        </Typography>

        <Typography type="p" variant="lg" weight="regular">
          {details}
        </Typography>
      </div>
    </div>
  );
};

export default Stepper;
