import SolicitorAvatar from "../../assets/solicitorAvatar1.png";
import LegalAssistantAvatar from "../../assets/legalAssistantAvatar1.png";
import LLGLogo from "../../assets/LLGLogo.svg";
import IdCheckImage from "../../assets/idCheck.svg";
import SignImage from "../../assets/sign.svg";

export const solicitorCards = [
  {
    id: 1,
    name: "Introduce you to your dedicated legal team",
    description: "Nabil of Leadenhall Law Group.",
    solicitorName: "They",
    legalAssistantName: "Lisa Richardson",
    solicitorBio: "have been working on",
    startDate: "",
    line_1: "Reviewing the searches, and title",
    line_2: "Raising enquiries with the sellers solicitor",
    line_3: "Preparing the report on the property for you",
    price: "£1,300",
    avatar_1: SolicitorAvatar,
    avatar_2: LegalAssistantAvatar,
    logo: LLGLogo,
  },
];

export const successCards = [
  {
    id: 1,
    name: "Complete ID check",
    description:
      "You'll shortly receive a text message from Thirdfort. Please follow the instructions to complete your ID checks.",
    image: IdCheckImage,
  },
  {
    id: 2,
    name: "Sign engagement letter",
    description:
      "Your solicitor will be in touch shortly to introduce themselves and onboard you.",
    image: SignImage,
  },
];
