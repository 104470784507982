import { Card, RelationshipToSale, Tenure } from "@shared/types";

export type LegalNameValues = {
  firstName: string;
  middleName: string;
  lastName: string;
};

export type ContactDetailsValues = {
  email: string;
  mobileNumber: number;
};

export const relationshipToSaleOptions = ["individual_owner", "representative"];

export const relationshipToSaleCard: Card<RelationshipToSale>[] = [
  {
    option: RelationshipToSale.IndividualOwner,
    label: "I am the seller",
    message: "A legal & beneficial owner",
    icon: "IndividualOwner",
    whiteIcon: "IndividualOwnerWhite",
  },
  {
    option: RelationshipToSale.Representative,
    label: "I am acting on behalf of the seller",
    message: "A representative, attorney or trustee",
    icon: "Representative",
    whiteIcon: "RepresentativeWhite",
  },
];

export const typeOfPropertyCard: Card<Tenure>[] = [
  {
    option: Tenure.Freehold,
    label: "Freehold",
    message: "Often the case with detached and semi-detached properties",
    icon: "Freehold",
    whiteIcon: "FreeholdWhite",
  },
  {
    option: Tenure.Leasehold,
    label: "Leasehold",
    message: "Often the case with flats and maisonettes",
    icon: "Leasehold",
    whiteIcon: "LeaseholdWhite",
  },
  {
    option: Tenure.ShareOfFreehold,
    label: "Share of Freehold",
    message: "Applies to some flats and maisonettes",
    icon: "ShareOfFreehold",
    whiteIcon: "ShareOfFreeholdWhite",
  },
  {
    option: Tenure.Other,
    label: "Other / Not Sure",
    message: "",
  },
];

export const typeOfPropertyOptions: string[] = Object.values(Tenure).map(
  (v) => v,
);

export const relationshipOptions = [
  { label: "Select your option", value: "", disabled: true },
  { label: "Parent", value: "Parent" },
  { label: "Other Family", value: "Other Family" },
  { label: "Other", value: "Other" },
];

export const topFiveMortgageProvidersOptions = [
  "Lloyds Bank",
  "NatWest (National Westminster Bank)",
  "Nationwide Building Society",
  "Santander UK",
  "Barclays Bank",
  "HSBC",
];

export const mortgageProvidersOptions = [
  "Not Sure Yet",
  "Accord Buy to Let",
  "Accord Mortgages",
  "Adam & Company",
  "Adam & Company International",
  "Ahli United Bank (UK)",
  "Aldermore Bank",
  "Allied Irish Bank (GB), a trading name of AIB Group (UK)",
  "Atom Bank",
  "Aviva Equity Release UK",
  "Bank of China",
  "Bank of Ireland",
  "Bank of Scotland",
  "Barclays Bank",
  "Barnsley Building Soc, a trading name Yorkshire Building Soc",
  "Birmingham Midshires",
  "Bluestone Mortgages",
  "Bradford & Bingley Limited",
  "Britannia, a trading name of The Co-operative Bank",
  "Buckinghamshire Building Society",
  "Chelsea Building Society (a trading name of Yorkshire BS)",
  "CHL Mortgages",
  "Clydesdale Bank",
  "Co-operative Bank",
  "Coutts",
  "Coventry Building Society",
  "Cynergy Bank",
  "Danske Bank",
  "Darlington Building Society",
  "DB UK Bank",
  "Dudley Building Society",
  "Ecology Building Society",
  "Family Building Soc (a trading name of National Counties BS)",
  "First Direct",
  "Fleet Mortgages",
  "Foundation Home Loans",
  "Furness Building Society",
  "GE Money Home Lending",
  "Generation Home",
  "Godiva Mortgages",
  "Habito",
  "Halifax",
  "Hampden & Co.",
  "Handelsbanken",
  "Harpenden Building Society",
  "Hinckley and Rugby Building Society",
  "Hodge",
  "Hodge Equity Release",
  "Holmesdale Building Society",
  "HSBC",
  "Intelligent Finance",
  "Investec Bank",
  "ITL Mortgages",
  "JPMorgan Chase Bank, N.A.",
  "Kensington Mortgage Company",
  "Kent Reliance (a trading name of OneSavings Bank)",
  "Keystone Property Finance",
  "Landbay Partners Limited",
  "Landmark Mortgages Limited",
  "Leeds Building Society",
  "Legal & General Home Finance",
  "LendInvest",
  "LiveMore Capital",
  "Lloyds Bank",
  "M&S Bank",
  "Magellan Homeloans",
  "Manchester Building Society",
  "Market Harborough Building Society",
  "Masthaven Bank",
  "Metro Bank",
  "Molo Finance",
  "Monmouthshire Building Society",
  "Mortgage Agency Services",
  "Mortgage Express",
  "MPowered Mortgages",
  "National Counties Building Society",
  "NatWest (National Westminster Bank)",
  "Nationwide Building Society",
  "Nedbank Private Wealth",
  "New Street Mortgages",
  "NRAM",
  "Paragon",
  "Paratus AMC",
  "Parity Trust",
  "Pepper Money",
  "Platform (a trading name of The Co-operative Bank)",
  "Precise Mortgages (Charter Court Financial Services)",
  "Principality Building Society",
  "Reliance Bank",
  "Rooftop Mortgages",
  "Royal Bank of Scotland",
  "Saffron Building Society",
  "Sainsbury’s Bank",
  "Santander UK",
  "Scottish Building Society",
  "Scottish Widows Bank",
  "Secure Trust Bank",
  "Skipton Building Society",
  "St James Place Bank",
  "State Bank of India UK",
  "Swansea Building Society",
  "The Mortgage Business",
  "The Mortgage Lender Limited",
  "The Mortgage Works",
  "The Tipton & Coseley Building Society",
  "Together Personal Finance Limited",
  "Topaz Finance",
  "TSB Bank",
  "Ulster Bank",
  "Vida Homeloans",
  "Virgin Money",
  "Whistletree (a trading name of TSB Bank)",
  "Yorkshire Bank Home Loans",
  "Yorkshire Building Society",
  "Zephyr Homeloans",
  "Other",
] as const;

export type mortgageProvidersEnum = (typeof mortgageProvidersOptions)[number];

export const fundingOptions = [
  { value: "savingsFromEmployment", label: "Savings from employment" },
  { value: "investmentPortfolio", label: "Investment portfolio" },
  { value: "inheritance", label: "Inheritance" },
  { value: "divorceSettlement", label: "Divorce settlement" },
  { value: "pensionLumpSumWithdrawal", label: "Pension lump sum withdrawal" },
  { value: "saleOfShares", label: "Sale of shares" },
  { value: "directorsLoansDividends", label: "Directors loans / dividends" },
  { value: "otherFunding", label: "Other" },
];
