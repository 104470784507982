import {
  BuyerPropertyDetailsState,
  GiftorSchemaState,
  PersonalDetailsWithAddressState,
  SellerPropertyDetailsState,
} from "../schema";

export const initialSellerPropertyDetailsStateObj: SellerPropertyDetailsState =
  {
    askingPrice: "",
    hasMortgage: null,
    onwardPurchase: null,
    customerType: null,
    tenureOption: null,
    tenureDetails: "",
    propertyAddress: {
      line_1: "",
      line_2: "",
      line_3: "",
      post_town: "",
      country: "",
      postcode: "",
      county: "",
      uprn: "",
      district: "",
      building_name: "",
      building_number: "",
      sub_building_name: "",
      premise: "",
      organisation_name: "",
      po_box: "",
    },
    additionalSellers: [],
    isAnotherSellerSelected: null,
  };

export const initialBuyerPropertyDetailsStateObj: BuyerPropertyDetailsState = {
  _id: "",
  property_id: "",
  agreedPrice: "",
  mortgageRequiredForPurchase: null,
  mortgageProvider: undefined,
  purchaseDependentOnSale: null,
  funding: {
    gifts: null,
    savingsFromEmployment: false,
    investmentPortfolio: false,
    remortgage: false,
    inheritance: false,
    divorceSettlement: false,
    pensionLumpSumWithdrawal: false,
    saleOfShares: false,
    directorsLoansDividends: false,
    otherFunding: false,
    otherFundingOption: "",
    otherFundingComments: "",
  },
  ownershipType: null,
  firstTimeBuyer: null,
  customerType: "individual_owner",
  additionalBuyers: [],
  giftors: [],
};

export const initialPersonalDetailsStateObj: PersonalDetailsWithAddressState = {
  personId: "",
  firstName: "",
  middleName: "",
  lastName: "",
  email: "",
  mobileNumber: "",
  termsAndConditions: false,
  primaryCustomer: false,
  correspondenceAddress: {
    line_1: "",
    line_2: "",
    line_3: "",
    post_town: "",
    country: "",
    postcode: "",
  },
};

export const initialGiftorStateObj: GiftorSchemaState = {
  personId: "",
  amount: "",
  firstName: "",
  middleName: "",
  lastName: "",
  email: "",
  mobileNumber: "",
  relationship: undefined,
  correspondenceAddress: {
    line_1: "",
    line_2: "",
    line_3: "",
    post_town: "",
    country: "",
    postcode: "",
  },
};
