import { Product } from "@shared/types";
import SearchesImage from "../../assets/products/search-pack-fee.png";
import PropelrFeeImage from "../../assets/products/propelr-product-fee.png";

export const products: Product[] = [
  {
    name: "Propelr fee",
    description:
      "This is the fee for the Propelr service and support team that supercharge your home sale. ",
    price: 24000,
    image: PropelrFeeImage,
    features: [
      "Get your property to exchange ready.",
      "Save up to six months on your transaction.",
      "Eliminate the chances of fall throughs.",
    ],
    quantity: 1,
    hasBanner: false,
    incVat: true,
  },
  {
    name: "Search pack",
    description:
      "A search pack is a bundle of documents that provides detailed information on your property to the future buyer. We will raise searches on your property immediately, speeding up the process by over 6 weeks!",
    price: 40000,
    image: SearchesImage,
    features: [
      "Essential to the legal process and ordered immediately to save time.",
      "Reusable with a new buyer If for any reason the buyer pulls out.",
      "If the search pack expires, Propelr will reorder a new pack free of charge.",
    ],
    quantity: 1,
    hasBanner: true,
    incVat: false,
  },
];
