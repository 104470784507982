import { AxiosError } from "axios";

export const handleAxiosError = (error: AxiosError) => {
  if (error.response) {
    console.error("API Error Response Data:", error.response.data);
    console.error("API Error Response Status:", error.response.status);
    console.error("API Error Response Headers:", error.response.headers);
  } else if (error.request) {
    console.error("API No Response:", error.request);
  } else {
    console.error("API Error Message:", error.message);
  }

  throw error;
};
