import * as Sentry from "@sentry/react";

import { api } from ".";

export const fetchCurrentUser = async () => {
  try {
    const response = await api.get("/users/current");
    return response.data;
  } catch (error: unknown) {
    Sentry.captureException(error);

    if (error instanceof Error) {
      throw new Error(`Fetch current user failed: ${error.message}`);
    } else {
      throw new Error("Fetch current user failed");
    }
  }
};
