import { useEffect, useState } from "react";

import { Card } from "@/components/Card/Card";
import { Table, TableProps } from "../Table/Table";
import { Address } from "../../pages/UserDetails/types";

interface AddressBlockProps {
  address: Address;
}

export const AddressBlock = ({ address }: AddressBlockProps) => {
  const [addressDetails, setAddressDetails] = useState<TableProps[]>([]);

  useEffect(() => {
    setAddressDetails([
      { label: "Line 1", value: address?.line_1 },
      { label: "Line 2", value: address?.line_2 },
      { label: "Line 3", value: address?.line_3 },
      { label: "Post Town", value: address?.post_town },
      { label: "Country", value: address?.country },
      { label: "Postcode", value: address?.postcode },
      { label: "County", value: address?.county },
      { label: "UPRN", value: address?.uprn },
      { label: "District", value: address?.district },
      { label: "Building Number", value: address?.building_number },
      { label: "Sub Building Name", value: address?.sub_building_name },
      { label: "Building Name", value: address?.building_name },
      { label: "Premise", value: address?.premise },
      { label: "Organisation Name", value: address?.organisation_name },
      { label: "PO Box", value: address?.po_box },
    ]);
  }, [address]);

  return (
    <Card
      title="Address"
      titleBackgroundColor="brand-heavy-warmth-25"
      titleColor="brand-heavy-teal-100"
    >
      <>
        {addressDetails.map(({ label, value }) => (
          <Table key={`address-details-${label}`} label={label} value={value} />
        ))}
      </>
    </Card>
  );
};
