import { z } from "zod";

export const CustomMetadataSchema = z.object({
  USP1: z.string(),
  USP2: z.string(),
  USP3: z.string(),
});

export const PaymentIntentStatusSchema = z.enum([
  "succeeded",
  "processing",
  "requires_payment_method",
  "requires_confirmation",
  "requires_action",
  "requires_capture",
  "canceled",
]);

export const PaymentIntentResponseSchema = z.object({
  clientSecret: z.string(),
  totalAmount: z.number(),
});

export const ProductSchema = z.object({
  name: z.string(),
  description: z.string(),
  price: z.number(),
  image: z.string(),
  features: z.array(z.string()),
  quantity: z.number(),
  hasBanner: z.boolean(),
  incVat: z.boolean(),
});

export const TotalAmountSchema = z.number().nonnegative();
