import { useEffect } from "react";

export const useLastCustomerSignIn = (
  setIsRegistered: (isRegistered: boolean) => void,
  setLessThan5Min: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  useEffect(() => {
    const currentTime = Date.now();
    const lastCustomerSignIn = Number(
      localStorage.getItem("lastCustomerSignIn"),
    );

    if (currentTime - lastCustomerSignIn < 5 * 60 * 1000) {
      setIsRegistered(true);
      setLessThan5Min(true);
    } else {
      localStorage.setItem("lastCustomerSignIn", "0");
      setLessThan5Min(false);
    }
  }, [setIsRegistered, setLessThan5Min]);
};
