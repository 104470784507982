import { paths } from "@shared/types.d";
import { api } from ".";

export type FetchTransactionResponse =
  paths["/api/events/transaction/{id}"]["get"]["responses"]["200"]["content"]["application/json"];

export const fetchTransactionEvent = async (
  id: string,
): Promise<FetchTransactionResponse> => {
  try {
    const response = await api.get(`/events/transaction/${id}`);
    return response.data;
  } catch (error) {
    throw new Error("Error fetching transaction events");
  }
};
