import Typography from "@/foundation/Typography/Typography";
import { EMAIL_ADDRESS } from "../../../../constants";

interface ErrorMessageWithEmailProps {
  propertyErrorMessage: string;
}

export const ErrorMessageWithEmail = ({
  propertyErrorMessage,
}: ErrorMessageWithEmailProps) => (
  <div className="mb-[12px] text-ui-error-100">
    <Typography type="span" variant="sm" weight="regular">
      {propertyErrorMessage}{" "}
      <a href={`mailto:${EMAIL_ADDRESS.SUPPORT}`}>{EMAIL_ADDRESS.SUPPORT}.</a>
    </Typography>
  </div>
);
