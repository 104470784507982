import { api } from "../../../service/ApiController";
import {
  AdminTransaction,
  LawyerGroupIds,
} from "../../pages/TransactionDetails/types";

export const assignLawyerGroupTransaction = async (
  transactionId: string,
  lawyerGroupsIds: LawyerGroupIds,
  sendEmails: boolean,
  seller_comments?: string,
  buyer_comments?: string,
): Promise<AdminTransaction> => {
  try {
    const response = await api.post(
      `/transaction/${transactionId}/lawyer-group`,
      { lawyerGroupsIds, sendEmails, seller_comments, buyer_comments },
    );
    return response.data;
  } catch (error) {
    throw new Error(`Lawyer groups assignment failed: ${error}`);
  }
};
