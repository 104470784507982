import { api } from ".";

export const findAddress = async (postcode: string) => {
  try {
    const response = await api.get(`/postcode/${postcode}`);
    return response.data;
  } catch (error) {
    throw new Error("Address not found");
  }
};
