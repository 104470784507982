import { createContext, useContext } from "react";
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from "react-google-recaptcha-v3";

import { RECAPTCHA_SITE_KEY } from "../constants";

type RecaptchaContextType = {
  executeRecaptcha?: (action?: string) => Promise<string>;
};

const RecaptchaContext = createContext<RecaptchaContextType>({});

interface RecaptchaProps {
  children: React.ReactNode;
}

export const RecaptchaProvider = ({ children }: RecaptchaProps) => (
  <GoogleReCaptchaProvider
    reCaptchaKey={RECAPTCHA_SITE_KEY}
    container={{ parameters: { badge: "inline" } }}
  >
    {children}
  </GoogleReCaptchaProvider>
);

export const useRecaptcha = () => {
  return useContext(RecaptchaContext);
};

export const RecaptchaConsumer = ({ children }: RecaptchaProps) => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  return (
    <RecaptchaContext.Provider value={{ executeRecaptcha }}>
      {children}
    </RecaptchaContext.Provider>
  );
};
