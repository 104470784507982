import { useNavigate } from "react-router-dom";

import { TransactionCard } from "./TransactionCard";
import { EmptyTransactionCard } from "./EmptyTransactionCard";
import Typography from "@/foundation/Typography/Typography";
import Icons from "@/foundation/Icons/Icons";
import { Button } from "@/components/Button/Button";

import { TransactionResponse, Role } from "@shared/types";
import { useLoginStore } from "../../store/Login/login";
import { useTransactionFormStore } from "../../store/TransactionForm/transactionForm";
import { usePurchaseIntentFormStore } from "../../store/PurchaseIntentForm/purchaseIntentForm";

interface TransactionsListProps {
  transactions: TransactionResponse[];
  type: Role;
  handleResumeTransaction: (id: string) => void | undefined;
}

export const TransactionsList = ({
  transactions,
  type,
  handleResumeTransaction,
}: TransactionsListProps) => {
  const navigate = useNavigate();
  const { setRole } = useLoginStore();
  const { resetTransaction } = useTransactionFormStore();
  const { resetPurchaseIntent } = usePurchaseIntentFormStore();

  const handleAddTransaction = () => {
    if (type === "sell") {
      resetTransaction();
    } else {
      resetPurchaseIntent();
    }

    setRole(type);
    navigate(`/${type}/welcome`);
  };

  return (
    <div className="mt-[48px] w-full bg-ui-white-100 rounded-2xl p-[16px] mb-[48px]">
      <div className="flex justify-between items-center mb-[24px]">
        <Typography
          type="h2"
          variant="h3"
          weight="bold"
          className="text-brand-heavy-teal-100 pl-[8px]"
        >
          Your {type === "sell" ? "sales" : "purchases"}
        </Typography>

        <div className="md:w-full max-w-[180px]">
          <Button variant="primary" size="full" onClick={handleAddTransaction}>
            <div className="shrink-0">
              <Icons iconName="Plus" size="12" colour="complement" />
            </div>

            <div className="hidden md:inline-block">
              <Typography
                type="span"
                variant="sm"
                weight="bold"
                className="text-ui-white-100 pl-[8px]"
              >
                Add a {type === "sell" ? "sale" : "purchase"}
              </Typography>
            </div>
          </Button>
        </div>
      </div>

      <>
        {transactions?.map((t) => (
          <TransactionCard
            key={t._id}
            transaction={t}
            type={type}
            handleResumeTransaction={handleResumeTransaction}
          />
        ))}

        {transactions.length === 0 && <EmptyTransactionCard type={type} />}
      </>
    </div>
  );
};
