import Typography from "@/foundation/Typography/Typography";
import { Role } from "@shared/types";

interface EmptyTransactionCard {
  type: Role;
}

export const EmptyTransactionCard = ({ type }: EmptyTransactionCard) => (
  <div className="min-h-[104px] flex justify-between bg-brand-medium-sand-50 rounded-xl overflow-hidden border-2 border-dashed border-brand-heavy-teal-25 mb-[16px]">
    <div className="flex flex-row items-center p-[36px] w-full">
      <Typography
        type="h3"
        variant="lg"
        weight="semi-bold"
        className="text-brand-heavy-teal-100"
      >
        You have no {type === "sell" ? "sales" : "purchases"} on Propelr
      </Typography>
    </div>
  </div>
);
