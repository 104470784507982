import Typography from "@/foundation/Typography/Typography";

interface QuestionSetProps {
  question: string;
  answer: string;
}

export const QuestionSet = ({ question, answer }: QuestionSetProps) => (
  <div className="flex flex-col flex-wrap">
    <Typography
      type="h4"
      variant="base"
      weight="bold"
      className="text-brand-heavy-teal-100"
    >
      {question}
    </Typography>
    <Typography
      type="h3"
      variant="lg"
      weight="regular"
      className="text-brand-heavy-teal-100 mb-[8px]"
    >
      {answer}
    </Typography>
  </div>
);
