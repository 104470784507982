import { useParams } from "react-router-dom";

import { PageNotFound } from "../pages/PageNotFound/PageNotFound";
import { Payment } from "../pages/Payment/Payment";
import { PaymentSuccess } from "../pages/Payment/PaymentSuccess";
import { ProtectedRoute } from "../components/ProtectedRoute/ProtectedRoute";
import { TermsAndConditions } from "../pages/TermsAndConditions/TermsAndConditions";
import { Welcome } from "../pages/Welcome/Welcome";
import { SellerAboutYou } from "../pages/SellerAboutYou/SellerAboutYou";
import { SellerAboutTheProperty } from "../pages/SellerAboutTheProperty/SellerAboutTheProperty";

export const SellerRouter = () => {
  const { page } = useParams();

  switch (page) {
    case "payment-success":
      return (
        <ProtectedRoute>
          <PaymentSuccess />
        </ProtectedRoute>
      );
    case "payment":
      return (
        <ProtectedRoute>
          <Payment />
        </ProtectedRoute>
      );
    case "terms-and-conditions":
      return (
        <ProtectedRoute>
          <TermsAndConditions />
        </ProtectedRoute>
      );
    case "about-the-property":
      return (
        <ProtectedRoute>
          <SellerAboutTheProperty />
        </ProtectedRoute>
      );
    case "about-you":
      return (
        <ProtectedRoute>
          <SellerAboutYou />
        </ProtectedRoute>
      );
    case "welcome":
      return (
        <ProtectedRoute>
          <Welcome />
        </ProtectedRoute>
      );
    default:
      return <PageNotFound />;
  }
};
