import { SignUpForm } from ".";
import { LinkWrapper } from "@/components/LinkWrapper/LinkWrapper";
import Typography from "@/foundation/Typography/Typography";
import { EMAIL_ADDRESS } from "../../constants";
import { initialLoginState } from "../../store/Login/login";

import { SignUpType } from "@schema";

export type SignUpProps = {
  handleSignUp: (values: SignUpType) => void;
  errorMessage: string;
};

export const SignUp = ({ handleSignUp, errorMessage }: SignUpProps) => (
  <>
    <Typography
      className="font-display text-brand-heavy-teal-100 text-center"
      type="h2"
      variant="h2"
      weight="semi-bold"
      data-testid="signup-title"
    >
      Get started in less than 5 minutes
    </Typography>

    {errorMessage ? (
      <Typography
        type="p"
        variant="xl"
        weight="semi-bold"
        className="text-ui-error-100 text-center my-[16px]"
      >
        {errorMessage}
      </Typography>
    ) : (
      <SignUpForm onSubmit={handleSignUp} state={initialLoginState} />
    )}

    <Typography
      type="p"
      variant="sm"
      weight="regular"
      className="text-ui-heavy-teal-50"
    >
      To get help email{" "}
      <LinkWrapper>
        <a href={`mailto:${EMAIL_ADDRESS.SUPPORT}`}>{EMAIL_ADDRESS.SUPPORT}</a>
      </LinkWrapper>
    </Typography>
  </>
);
