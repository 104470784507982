interface Notification {
  params: string;
  message: string;
}

export const notifications: Notification[] = [
  {
    params: "sign-out",
    message: "You've been signed out.",
  },
  {
    params: "account",
    message: `It looks like you already have an account with us. Please sign in to continue.`,
  },
  {
    params: "preventEmail",
    message: `Warning! Sending the Magic Link email has been disabled.`,
  },
];
