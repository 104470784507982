import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card } from "@/components/Card/Card";
import { AdminTransaction } from "../../pages/TransactionDetails/types";
import { ApiController } from "../../../service/ApiController";
import { Table, TableProps } from "../Table/Table";
import { getFirstTwoFullnames } from "../../../utils/utils";
import { getAddressAsString } from "@shared/utils";
import { format } from "date-fns";

interface TransactionData {
  transactionId: string;
  data: TableProps[];
}

const getUnpaidTransactionsData = (
  transactions: AdminTransaction[],
): TransactionData[] => {
  return transactions.map((transaction) => {
    return {
      transactionId: transaction._id,
      data: [
        {
          label: "Seller Full Name(s)",
          value: getFirstTwoFullnames(transaction.sellers),
        },
        {
          label: "Address",
          value: getAddressAsString(transaction.property_id.address, true),
        },
        {
          label: "Created On",
          value: format(new Date(transaction.createdAt), "dd/MM/yyyy"),
        },
      ],
    };
  });
};

export const UnpaidTransactionsCard = () => {
  const navigate = useNavigate();

  const [unpaidTransactions, setUnpaidTransactions] = useState<
    TransactionData[]
  >([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function getAllTransactions() {
      try {
        const unpaidTransactionsRes = await ApiController.findAllTransactions({
          isPaid: false,
        });

        setUnpaidTransactions(getUnpaidTransactionsData(unpaidTransactionsRes));
      } catch (error) {
        if (error instanceof Error) {
          console.error(error.message);
        }
      }
    }

    setLoading(true);
    getAllTransactions();
    setLoading(false);
  }, []);

  return (
    <Card title="Unpaid Transactions">
      <div className="flex flex-col space-y-5">
        {!loading ? (
          unpaidTransactions.length > 0 ? (
            unpaidTransactions.map((transaction, index) => (
              <button
                key={`transaction-not-paid=${index}`}
                onClick={() =>
                  navigate(`/admin/transactions/${transaction.transactionId}`)
                }
                className="text-left"
              >
                <Card>
                  <>
                    {transaction.data.map(({ label, value }, index) => (
                      <Table
                        key={`purchase-intent-${index}-${label}`}
                        value={value}
                        label={label}
                      />
                    ))}
                  </>
                </Card>
              </button>
            ))
          ) : (
            <p className="text-center">No unpaid transactions :)</p>
          )
        ) : (
          <p>Loading...</p>
        )}
      </div>
    </Card>
  );
};
