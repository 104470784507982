import { useEffect, useState } from "react";

import { Card } from "@/components/Card/Card";
import Typography from "@/foundation/Typography/Typography";
import { Address, AdminUser } from "../../pages/UserDetails/types";
import { TableProps } from "../Table/Table";
import { Giftors } from "../../pages/TransactionDetails/types";
import { getAddressAsString, toTitleCase } from "@shared/utils";
import { CopyButton } from "../CopyButton/CopyButton";

interface ThirdfortBlockProps {
  title: string;
  address: Address;
  profile: AdminUser | Giftors;
  solicitorEmail: string;
  leftLabel?: string;
  userType?: "Seller" | "Buyer";
}

const formatThirdfortPhoneNumber = (
  phoneNumber: string | undefined,
): string => {
  if (!phoneNumber) return "";
  if (phoneNumber.startsWith("07")) return phoneNumber.replace(/^0/, "");
  return phoneNumber;
};

export const ThirdfortBlock = ({
  title,
  address,
  profile,
  solicitorEmail,
  leftLabel = "",
  userType = "Seller",
}: ThirdfortBlockProps) => {
  const [thirdfortDetails, setThirfortDetails] = useState<TableProps[]>([]);

  useEffect(() => {
    const propertyAddress = getAddressAsString(address);

    const { first_name, middle_name, last_name, phone_number, email } = profile;

    const fullName = toTitleCase(
      `${first_name}${middle_name ? ` ${middle_name}` : ""} ${last_name}`,
    );

    const referenceName = toTitleCase(`${first_name} ${last_name}`);
    const fileReference = `${address.line_1}${address.line_2 ? `, ${address.line_2}` : ""} - ${userType} - ${referenceName}`;
    const phoneNumber = formatThirdfortPhoneNumber(
      phone_number ||
        // phone number for a seller can come from either phone_number or profile.phone_number
        // temp fix until we algin the data to be the same structure
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        (profile.profile && profile.profile.phone_number) ||
        undefined,
    );

    setThirfortDetails([
      { label: "File Reference", value: fileReference },
      { label: "Full Name", value: fullName },
      { label: "Phone Number", value: phoneNumber },
      { label: "Email", value: email },
      { label: "Property Address", value: propertyAddress },
      { label: "Solicitor email", value: solicitorEmail },
    ]);
  }, [address, profile, solicitorEmail, userType]);

  return (
    <Card
      title={title}
      titleBackgroundColor="brand-heavy-warmth-25"
      titleColor="brand-heavy-teal-100"
      titleRightContent={
        <Typography className="text-brand-heavy-teal-50" variant="xs">
          {leftLabel}
        </Typography>
      }
    >
      <>
        {thirdfortDetails.map(({ label, value }) => (
          <div
            key={`thirdfort-seller-${label}`}
            className="flex items-center justify-between text-brand-heavy-teal-100 mb-[12px]"
          >
            <Typography className="shrink-0" weight="bold">
              {label}:
            </Typography>

            {value && (
              <CopyButton value={value} label={label}>
                <Typography className="mx-[12px]">{value}</Typography>
              </CopyButton>
            )}
          </div>
        ))}
      </>
    </Card>
  );
};
