import { useEffect, useState } from "react";

import { AdminTransaction } from "../../pages/TransactionDetails/types";
import { Card } from "@/components/Card/Card";
import { formatCurrency, getAddressAsString } from "@shared/utils";
import { Lozenge } from "@/components/Lozenge/Lozenge";
import { Table, TableProps } from "../Table/Table";
import { TagsModal } from "../Modals/TagsModal";

export interface TransactionInfoProps {
  transaction: AdminTransaction;
  onUpdateTransaction: (transaction: AdminTransaction) => void;
}

type TableAndCopyOptions = TableProps & { includeCopyButton?: boolean };

export const TransactionInfo = ({
  transaction,
  onUpdateTransaction,
}: TransactionInfoProps) => {
  const [fields, setFields] = useState<TableAndCopyOptions[]>([]);
  const [showEditTagsModal, setShowEditTagsModal] = useState(false);

  useEffect(() => {
    const { lawyer_group, estate_agent } = transaction;
    const { address, tenure } = transaction.property_id;
    const estateAgentString =
      estate_agent && `${estate_agent?.brand}-${estate_agent?.office.name}`;

    setFields([
      {
        label: "ID",
        value: transaction._id,
        includeCopyButton: true,
      },
      {
        label: "Property",
        value: getAddressAsString(address, true),
        includeCopyButton: true,
      },
      {
        label: "Tenure",
        value: tenure,
      },
      {
        label: "Asking Price",
        value: formatCurrency(transaction.asking_price, true, true),
      },
      {
        label: "Seller Solicitor",
        value: lawyer_group?.seller.name || "",
      },
      {
        label: "Buyer Solicitor",
        value: lawyer_group?.buyer.name || "",
      },
      {
        label: "Estate Agent",
        value: estateAgentString || "",
      },
    ]);
  }, [transaction]);

  return (
    <>
      <Card>
        <div className="md:grid lg:grid-rows-4 grid-rows-6 grid-flow-col">
          {fields.map(({ value, label, includeCopyButton }) => (
            <div key={label} className="flex jusify-top">
              <Table
                key={value as string}
                label={label}
                value={value}
                includeCopyButton={includeCopyButton}
              />
            </div>
          ))}
        </div>
      </Card>

      <div className="flex flex-wrap gap-[8px] flex-row mt-[16px]">
        {transaction.tags &&
          transaction.tags.map((tag) => (
            <div key={`tag-${tag}`} className="mb-[4px]">
              <Lozenge>{tag}</Lozenge>
            </div>
          ))}

        <button
          onClick={() => setShowEditTagsModal(true)}
          type="button"
          className="mb-[4px]"
        >
          <Lozenge
            backgroundColor="bg-brand-heavy-teal-100"
            fontColor="text-ui-white-100"
          >
            +/- Tags
          </Lozenge>
        </button>
      </div>

      {showEditTagsModal && (
        <TagsModal
          onClose={() => setShowEditTagsModal(false)}
          onSavedTags={(newTransaction) => {
            onUpdateTransaction(newTransaction);
            setShowEditTagsModal(false);
          }}
          selectedTags={transaction.tags || []}
          transactionId={transaction._id}
        />
      )}
    </>
  );
};
