import { useEffect, useState } from "react";

import { Card } from "@/components/Card/Card";
import { Table, TableProps } from "../Table/Table";
import { EstateAgent } from "../../../types";
interface EstateAgentBlockProps {
  estateAgent: EstateAgent;
}

type TableAndCopyOptions = TableProps & { includeCopyButton?: boolean };

export const EstateAgentBlock = ({ estateAgent }: EstateAgentBlockProps) => {
  const [fields, setFields] = useState<TableAndCopyOptions[]>([]);

  useEffect(() => {
    const { brand, office, _id } = estateAgent;

    setFields([
      {
        label: "ID",
        value: _id,
        includeCopyButton: true,
      },
      {
        label: "Office",
        value: `${brand} - ${office.name}`,
        includeCopyButton: true,
      },
    ]);
  }, [estateAgent]);

  return (
    <>
      <Card>
        <div className="md:grid lg:grid-rows-2 grid-rows-2 grid-flow-col">
          {fields.map(({ value, label, includeCopyButton }) => (
            <div key={label} className="flex jusify-top">
              <Table
                key={value as string}
                label={label}
                value={value}
                includeCopyButton={includeCopyButton}
              />
            </div>
          ))}
        </div>
      </Card>
    </>
  );
};
