import { useEffect, useState } from "react";

import { Card } from "@/components/Card/Card";
import { Table, TableProps } from "../Table/Table";
import { EstateAgent } from "../../../types";
interface EstateAgentNegotiatorsBlockProps {
  negotiators?: EstateAgent["negotiators"];
}

type TableAndCopyOptions = TableProps & { includeCopyButton?: boolean };

export const EstateAgentNegotiatorsBlock = ({
  negotiators,
}: EstateAgentNegotiatorsBlockProps) => {
  const [fields, setFields] = useState<TableAndCopyOptions[]>([]);

  useEffect(() => {
    const negotiatorsFields = negotiators?.flatMap((negotiators, index) => [
      {
        label: `Negotiator ${index + 1} Name`,
        value: `${negotiators.first_name} ${negotiators.last_name}`,
      },
      {
        label: `Negotiator ${index + 1} Email`,
        value: negotiators.email,
      },
      {
        label: `Negotiators ${index + 1} Phone Number`,
        value: negotiators.phone_number,
      },
    ]);

    setFields(negotiatorsFields || []);
  }, [negotiators]);

  return (
    <>
      {fields.length > 0 && (
        <Card
          title="Negotiators"
          titleBackgroundColor="brand-heavy-warmth-25"
          titleColor="brand-heavy-teal-100"
        >
          <>
            {fields.map(({ value, label, includeCopyButton }) => (
              <Table
                key={value as string}
                label={label}
                value={value}
                includeCopyButton={includeCopyButton}
              />
            ))}
          </>
        </Card>
      )}
    </>
  );
};
