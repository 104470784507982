import * as Sentry from "@sentry/react";

import { api } from ".";
import { AdminTransaction } from "../../admin/pages/TransactionDetails/types";

export const updateTransactionTags = async (
  transactionId: string,
  tags: string[],
): Promise<AdminTransaction> => {
  try {
    const response = await api.put(`/transaction/${transactionId}/tags`, tags);
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    throw new Error("Tag update failed, please try again.");
  }
};
