import * as Sentry from "@sentry/react";
import { api } from ".";
import {
  PurchaseIntent,
  PurchaseIntentSignificantDates,
} from "../../admin/pages/TransactionDetails/types";

export const updatePurchaseIntentSignificantDates = async (
  id: string,
  newDates: PurchaseIntentSignificantDates,
): Promise<PurchaseIntent> => {
  try {
    const response = await api.put(
      `/purchase-intent/significant-dates/${id}`,
      newDates,
    );

    return response.data;
  } catch (error) {
    Sentry.captureException(
      `Error updating significant dates: ${JSON.stringify(error)}`,
    );

    throw new Error(
      "There was a problem updating the significant dates. Please try again.",
    );
  }
};
