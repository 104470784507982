import { useEffect, useRef } from "react";

import { Card } from "@/components/Card/Card";
import { Select } from "@/components/Input/Select";
import Typography from "@/foundation/Typography/Typography";
import { brands } from "@shared/types";

export const brandFilter = {
  brand: brands,
};

export type BrandFilter = (typeof brands)[number] | undefined;

interface EstateAgentsFilterModalProps {
  setBrandFilterOption: (value: BrandFilter) => void;
  brandFilterOption: BrandFilter;
  brandCounts?: { brand: string; count: number }[];
  totalEstateAgents: number;
  onCloseModal: () => void;
}

export const EstateAgentsFilterModal = ({
  setBrandFilterOption,
  brandFilterOption,
  brandCounts,
  totalEstateAgents,
  onCloseModal,
}: EstateAgentsFilterModalProps) => {
  const estateAgentFilterModal = useRef<HTMLDivElement>(null);

  const getEstateAgentOptions = () => {
    const allBrandsOption = {
      label: `All (${totalEstateAgents})`,
      value: "all",
    };

    const brandOptions =
      brandCounts?.map(({ brand, count }) => ({
        label: `${brand} (${count})`,
        value: brand,
      })) || [];

    return [allBrandsOption, ...brandOptions].sort((a, b) =>
      a.label.localeCompare(b.label),
    );
  };

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    const newBrandFilter = value === "all" ? undefined : (value as BrandFilter);
    setBrandFilterOption(newBrandFilter);
  };

  useEffect(() => {
    const handler = (event: MouseEvent) => {
      if (!estateAgentFilterModal.current) {
        return;
      }

      if (!estateAgentFilterModal.current.contains(event.target as Node)) {
        onCloseModal();
      }
    };

    document.addEventListener("click", handler, true);
    return () => {
      document.removeEventListener("click", handler, true);
    };
  }, [onCloseModal]);

  return (
    <div ref={estateAgentFilterModal} className="w-[320px]">
      <Card>
        <>
          <Typography variant="h4" weight="bold" className="mb-[24px]">
            Estate Agents Filters
          </Typography>
          <Typography className="mb-[16px]">Brand:</Typography>
          <div className="flex space-x-5">
            <Select
              name="estate_agent"
              id="estate_agent"
              value={brandFilterOption || "all"}
              options={getEstateAgentOptions()}
              onChange={handleSelectChange}
              width="sm"
            />
          </div>
        </>
      </Card>
    </div>
  );
};
