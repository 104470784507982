import { useState } from "react";
import { AdminSideNavigation } from "./AdminSideNavigation";
interface AdminPageTemplateProps {
  children: React.ReactNode;
}

export const AdminPageTemplate = ({ children }: AdminPageTemplateProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="w-full min-h-full">
      <AdminSideNavigation isOpen={isOpen} setIsOpen={setIsOpen} />

      <div
        className={`w-full flex-grow px-[8px] md:pr-[40px] mb-40 ${isOpen ? "md:pl-[260px]" : "md:pl-[100px]"}`}
      >
        {children}
      </div>
    </div>
  );
};
