import { useEffect, useRef } from "react";
import { Card } from "@/components/Card/Card";
import Typography from "@/foundation/Typography/Typography";

export const activeFilter = {
  all: undefined,
  active: true,
  inactive: false,
};

export type ActiveFilter = (typeof activeFilter)[keyof typeof activeFilter];

interface UserFilterModalProps {
  activeFilterOption: ActiveFilter;
  setActiveFilterOption: (value: ActiveFilter) => void;
  onCloseModal: () => void;
}

export const UserFilterModal = ({
  activeFilterOption,
  setActiveFilterOption,
  onCloseModal,
}: UserFilterModalProps) => {
  const userFilterModal = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handler = (event: MouseEvent) => {
      if (!userFilterModal.current) {
        return;
      }

      if (!userFilterModal.current.contains(event.target as Node)) {
        onCloseModal();
      }
    };

    document.addEventListener("click", handler, true);
    return () => {
      document.removeEventListener("click", handler, true);
    };
  }, [onCloseModal]);

  return (
    <div ref={userFilterModal}>
      <Card>
        <>
          <Typography variant="h4" weight="bold" className="mb-[24px]">
            User Filters
          </Typography>
          <Typography className="mb-[16px]">Active:</Typography>
          <div className="flex space-x-5">
            {Object.entries(activeFilter).map(([key, value]) => {
              const entryKey = `paid-status-filter-${key}`;
              return (
                <div key={entryKey}>
                  <input
                    className="mr-[5px]"
                    type="radio"
                    value={entryKey}
                    name="paid-status-filter"
                    checked={activeFilterOption === value}
                    onChange={() => setActiveFilterOption(value)}
                  />
                  <label htmlFor={entryKey}>{key}</label>
                </div>
              );
            })}
          </div>
        </>
      </Card>
    </div>
  );
};
