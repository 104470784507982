import { WelcomeCard } from "@/components/WelcomeCard/WelcomeCard";
import Typography from "@/foundation/Typography/Typography";

export const BuyerWelcomeMessage = () => (
  <div className="flex flex-col items-start mt-[48px]">
    <Typography
      type="h2"
      variant="h3"
      weight="bold"
      className="text-brand-heavy-teal-100 font-display max-w-[750px]"
    >
      People who choose Propelr save an average of 15 weeks on their purchase
    </Typography>

    <Typography
      type="p"
      variant="lg"
      weight="regular"
      className="text-brand-heavy-teal-75 my-[32px]"
    >
      Behind the scenes your solicitor has been busy getting the legals sorted.
      All of the following are included in the Propelr service:
    </Typography>

    <div className="md:grid md:grid-cols-2 lg:md:grid-cols-3 gap-[20px]">
      <WelcomeCard
        description="Property searches are a bundle of documents that reveal information about the property you are buying. They include local authority searches, environmental searches, and water and drainage searches."
        title="Reviewing Searches"
        iconName="Searches"
      />

      <WelcomeCard
        description="Raising enquiries involves your solicitor questioning the seller's solicitor to uncover and clarify details concerning the property, such as boundaries, rights of way, and any disputes or restrictions that may affect the property."
        title="Raising Enquiries"
        iconName="RaisingQuestion"
      />

      <WelcomeCard
        description="The property report is legal advice comprising a summary of work that your solicitor has done to get the property into a state ready for you to buy. It includes a summary of the searches, enquiries, and anything you should be aware of regarding the property."
        title="Preparing the Property Report"
        iconName="PropertyReport"
      />
    </div>

    <Typography
      type="p"
      variant="lg"
      weight="regular"
      className="text-brand-heavy-teal-75 mt-[32px] mb-[24px] max-w-[780px]"
    >
      Over the following few pages we&apos;re going to collect some information
      about you and your purchase which will help your solicitor onboard you
      faster. This should take no more than 5 minutes.
    </Typography>

    <Typography
      type="p"
      variant="lg"
      weight="regular"
      className="text-brand-heavy-teal-75"
    >
      Click Start to begin.
    </Typography>
  </div>
);
