import * as Sentry from "@sentry/react";

import { api } from ".";

export const signOut = async (): Promise<void> => {
  try {
    await api.post("/authentication/sign-out");
  } catch (error: unknown) {
    Sentry.captureException(error);

    if (error instanceof Error) {
      throw new Error(`Sign out failed: ${error.message}`);
    } else {
      throw new Error("Sign out failed for an unknown reason");
    }
  }
};
