import { useEffect } from "react";

import { Button } from "@/components/Button/Button";
import Typography from "@/foundation/Typography/Typography";

type ModalProps = {
  children: React.ReactNode;
  title: string;
  saveButtonText?: string;
  cancelButtonFunction: () => void;
  saveButtonFunction: () => void;
  hideSaveButton?: boolean;
  saveButtonName?: string;
  isSaveButtonDisabled?: boolean;
};

export const Modal = ({
  children,
  title,
  saveButtonText = "Save",
  cancelButtonFunction,
  saveButtonFunction,
  hideSaveButton,
  isSaveButtonDisabled = false,
}: ModalProps) => {
  useEffect(() => {
    // Set overflow to hidden when modal is open:
    const originalOverflow = document.body.style.overflow;
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = originalOverflow;
    };
  }, []);

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      {/* Background Fade */}
      <div className="bg-[#004250CC] backdrop-blur w-full h-full absolute"></div>

      {/* Modal */}
      <div className="w-full md:w-[600px] fixed top-[40px] bottom-0 md:bottom-[40px] right-0 md:right-[40px] bg-brand-heavy-warmth-25 flex flex-col rounded-t-2xl md:rounded-2xl overflow-hidden">
        <div className="flex items-center justify-between relative h-[14px] w-full p-8 border-b border-ui-black-25 bg-brand-dark-warmth-10 rounded-t-2xl px-[32px]">
          <Typography
            type="h2"
            variant="xl"
            weight="bold"
            className="text-brand-heavy-teal-100"
          >
            {title}
          </Typography>

          <button
            className="underline cursor-pointer text-brand-heavy-teal-75"
            onClick={cancelButtonFunction}
            type="button"
          >
            <Typography
              variant="base"
              weight="regular"
              type="span"
              className="text-brand-heavy-teal-75"
            >
              Cancel
            </Typography>
          </button>
        </div>

        <div className="overflow-y-auto px-[32px] bg-brand-medium-sand-50 grow">
          <div className="mt-[24px]">{children}</div>

          <div className="flex mt-[24px] mb-[32px]">
            <Button
              variant="primary"
              type="button"
              onClick={saveButtonFunction}
              className={`mt-[16px] ${hideSaveButton && "hidden"}`}
              size="full"
              disabled={isSaveButtonDisabled}
            >
              {saveButtonText}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
