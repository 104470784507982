import * as Sentry from "@sentry/react";

export const initializeSentry = async () => {
  try {
    // IMPORTANT!! Without this import, the constants will be not be available and the IF ELSE statement won't rtigger on Staging or Production
    const { SENTRY_APP_ENV, SENTRY_DSN } = await import("./constants.ts");

    if (SENTRY_APP_ENV === "staging" || SENTRY_APP_ENV === "production") {
      Sentry.init({
        environment: SENTRY_APP_ENV,
        dsn: SENTRY_DSN,
        integrations: [
          Sentry.browserTracingIntegration(),
          Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
          }),
        ],
        tracesSampleRate: 1.0,
        tracePropagationTargets: [
          "localhost",
          "https://www.propelr.co.uk",
          "https://staging.propelr.co.uk",
        ],
        replaysSessionSampleRate: 0.5,
        replaysOnErrorSampleRate: 1.0,
        debug: true,
      });

      if (SENTRY_APP_ENV === "staging") {
        console.log("Sentry initialized on:", SENTRY_APP_ENV);
      }
    } else {
      console.log("SENTRY_APP_ENV is 'development'. Sentry not initialized.");
    }
  } catch (error) {
    console.error("Error loading SENTRY_APP_ENV:", error);
  }
};
