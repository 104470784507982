import { getAddressAsString } from "@shared/utils";
import {
  AdminTransaction,
  AdminTransactionsForInvoicing,
  LawyerGroups,
} from "../../../pages/TransactionDetails/types";
import {
  CsvGroupedRecord,
  CsvRecord,
  YearAndMonth,
} from "../../../types/types";
import { createCsvRecord } from "./csvUtils";
import { getYearAndMonth } from "./dateUtils";
import { LawyerGroup } from "../../../../types";

export const getTransactionsWithLawyerGroupAssignedOnMonth = (
  selectedPeriod: YearAndMonth,
  transactions: AdminTransaction[],
): AdminTransactionsForInvoicing[] => {
  return transactions.reduce(
    (acc: AdminTransactionsForInvoicing[], transaction) => {
      const { lawyer_group, property_id, date_solicitor_groups_assigned } =
        transaction;

      if (!date_solicitor_groups_assigned || !lawyer_group) return acc;

      const assignedDate = new Date(date_solicitor_groups_assigned.assigned_at);
      const { year, month } = getYearAndMonth(assignedDate);

      // Check if the transaction was assigned in the selected period
      if (year === selectedPeriod.year && month === selectedPeriod.month) {
        acc.push({
          property_id,
          date_solicitor_groups_assigned,
          lawyer_group,
        });
      }

      return acc;
    },
    [],
  );
};

export const groupTransactionsByLawyerGroup = (
  transactions: AdminTransactionsForInvoicing[],
  invoicingAmount: string,
): Record<string, { records: CsvRecord[]; total: number }> => {
  return transactions.reduce<
    Record<string, { records: CsvRecord[]; total: number }>
  >((acc, transaction) => {
    const lawyerGroups = getLawyerGroupNames(transaction.lawyer_group);

    lawyerGroups.forEach((lawyerGroup) => {
      if (!acc[lawyerGroup]) {
        acc[lawyerGroup] = { records: [], total: 0 };
      }

      const record = createCsvRecord(transaction, invoicingAmount);
      acc[lawyerGroup].records.push(record);
      acc[lawyerGroup].total += parseFloat(invoicingAmount);
    });

    return acc;
  }, {});
};

export const getLawyerGroupName = (lawyer_group: LawyerGroup): string => {
  let lawyerGroupName = `${lawyer_group.law_firm.name} - ${lawyer_group.law_firm.address.organisation_name}, ${getAddressAsString(lawyer_group.law_firm.address, true)}`;

  if (lawyer_group.invoicing_address) {
    lawyerGroupName = `${lawyer_group.invoicing_address.organisation_name} - ${getAddressAsString(lawyer_group.invoicing_address, true)}`;
  }

  return lawyerGroupName;
};

export const getLawyerGroupNames = (lawyer_group: LawyerGroups): string[] => {
  return [
    getLawyerGroupName(lawyer_group.buyer),
    getLawyerGroupName(lawyer_group.seller),
  ];
};

export const organiseRecordsByLawyerGroup = (
  transactions: AdminTransactionsForInvoicing[],
  invoicingAmount: string,
): CsvGroupedRecord[] => {
  const groupedRecords = groupTransactionsByLawyerGroup(
    transactions,
    invoicingAmount,
  );
  return mapGroupedRecordsToCsv(groupedRecords);
};

export const mapGroupedRecordsToCsv = (
  groupedRecords: Record<string, { records: CsvRecord[]; total: number }>,
): CsvGroupedRecord[] =>
  Object.entries(groupedRecords).map(([lawyerGroup, { records, total }]) => ({
    lawyerGroup,
    records: records.sort(
      (a: CsvRecord, b: CsvRecord) =>
        new Date(a.date).getTime() - new Date(b.date).getTime(),
    ),
    total,
  }));
