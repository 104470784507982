import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import * as Sentry from "@sentry/react";

import { ApiController } from "../../../service/ApiController";
import Typography from "@/foundation/Typography/Typography";
import { AdminTitle } from "../../components/AdminPageTemplate/AdminTitle";
import { EstateAgentBlock } from "../../components/EstateAgentDetailsBlocks/EstateAgentBlock";
import { EstateAgentValuersBlock } from "../../components/EstateAgentDetailsBlocks/EstateAgentValuersBlock";
import { EstateAgentOfficeBlock } from "../../components/EstateAgentDetailsBlocks/EstateAgentOfficeBlock";
import { EstateAgentNegotiatorsBlock } from "../../components/EstateAgentDetailsBlocks/EstateAgentNegotiatorsBlock";
import { FullPageLoader } from "../../../pages/FullPageLoader/FullPageLoader";
import { EstateAgent } from "../../../types";

export const EstateAgentDetails = () => {
  const location = useLocation();
  const estateAgentId = location.pathname.split("/")[3];

  const [estateAgent, setEstateAgent] = useState<EstateAgent>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    async function getEstateAgent() {
      setError("");

      try {
        const estateAgentRes =
          await ApiController.findEstateAgent(estateAgentId);
        setEstateAgent(estateAgentRes);
      } catch (error) {
        Sentry.captureException(error);
        setError("There was an error while fetching the estate agent details");
      }
    }

    setLoading(true);
    getEstateAgent();
    setLoading(false);
  }, [estateAgentId]);

  return (
    <>
      <AdminTitle title="Estate Agent" />

      {loading && <FullPageLoader />}

      {error && (
        <Typography variant="lg" weight="bold" type="p">
          {error}
        </Typography>
      )}

      {estateAgent && !loading && !error && (
        <div className="flex flex-col space-y-5">
          <EstateAgentBlock estateAgent={estateAgent} />
          <EstateAgentValuersBlock valuers={estateAgent.valuers} />
          <EstateAgentOfficeBlock office={estateAgent.office} />
          <EstateAgentNegotiatorsBlock negotiators={estateAgent.negotiators} />
        </div>
      )}
    </>
  );
};
