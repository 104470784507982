import * as Sentry from "@sentry/react";

import { paths } from "@shared/types.d";
import { api } from "../../../service/ApiController";
import { AdminTransaction } from "../../pages/TransactionDetails/types";

type OrderSearches = paths["/api/transaction/{id}/searches"]["put"];

type OrderSearchesRequestBody =
  OrderSearches["requestBody"]["content"]["application/json"];
//TODO: update type when matching
//type OrderSearchesResponse = OrderSearches["responses"]["200"]["content"]["application/json"];

export const orderSearches = async (
  transactionId: string,
  body: OrderSearchesRequestBody,
): Promise<AdminTransaction> => {
  try {
    const response = await api.put(
      `/transaction/${transactionId}/searches`,
      body,
    );
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    throw new Error("Error ordering searches");
  }
};
