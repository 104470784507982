import { EventsTimeline } from "../EventsTimeline/EventsTimeline";
import { AdminTransaction } from "../../pages/TransactionDetails/types";
import { Card } from "@/components/Card/Card";

interface EventsTimelineTabProps {
  transaction: AdminTransaction;
}

export const EventsTimelineTab = ({ transaction }: EventsTimelineTabProps) => (
  <Card
    title="Events Timeline"
    titleBackgroundColor="brand-heavy-warmth-25"
    titleColor="brand-heavy-teal-100"
  >
    <EventsTimeline transaction={transaction} />
  </Card>
);
