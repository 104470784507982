import { useState } from "react";
import Icons from "@/foundation/Icons/Icons";
import Typography from "@/foundation/Typography/Typography";

interface CopyButtonProps {
  children: React.ReactNode;
  value: string | undefined;
  label?: string | undefined;
}

export const CopyButton = ({
  children,
  value,
  label = "",
}: CopyButtonProps) => {
  const [isFlashing, setIsFlashing] = useState(false);
  const [messageIsVisible, setMessageIsVisible] = useState(false);

  const handleCopyClick = () => {
    navigator.clipboard.writeText(value ?? "");
    setIsFlashing(true);
    setMessageIsVisible(true);

    setTimeout(() => {
      setIsFlashing(false);
      setMessageIsVisible(false);
    }, 1000);
  };

  return (
    <button
      className={`text-left w-full flex rounded-full ${isFlashing ? "bg-brand-heavy-warmth-25" : ""}`}
      onClick={handleCopyClick}
    >
      <div className="flex justify-between w-full">
        {children}

        <div className="relative bottom-[-2px] ml-[6px] mr-[6px] opacity-50">
          <Icons iconName="Copy" />
        </div>
      </div>

      <div
        className={`${messageIsVisible ? "visible" : "hidden"} fixed top-[6px] right-[12px] z-50 bg-brand-heavy-warmth-25 p-[24px] rounded-2xl`}
      >
        <Typography
          weight="bold"
          variant="base"
          className="text-brand-heavy-teal-100"
        >
          Copied: {label}
        </Typography>
      </div>
    </button>
  );
};
