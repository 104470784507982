import { AdminTitle } from "../../components/AdminPageTemplate/AdminTitle";
import { UnpaidTransactionsCard } from "../../components/UnpaidTransactionsCard/UnpaidTransactionsCard";
import { InactiveUsersCard } from "../../components/InactiveUsersCard/InactiveUsersCard";

export const AdminDashboard = () => {
  return (
    <>
      <AdminTitle title="Admin Dashboard" />

      <div className="flex flex-col md:flex-row md:space-x-5">
        <div className="w-full mb-[24px]">
          <UnpaidTransactionsCard />
        </div>

        <div className="w-full mb-[24px]">
          <InactiveUsersCard />
        </div>
      </div>
    </>
  );
};
