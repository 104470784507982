import { useEffect, useState } from "react";

import { formatPostcode } from "@shared/utils";
import { Property } from "./types";
import { ApiController } from "../../../../service/ApiController";
import { typosErrorMessage } from "./errorMessages";
import { getAddressesAndPropertiesOptions } from "./utils";
import { ErrorMessageWithEmail } from "./ErrorMessageWithEmail";
import { PostcodeInput } from "./PostcodeInput";
import { Select } from "@/components/Input/Select";
import { Field, FieldProps, useFormikContext } from "formik";
import { BuyerPropertyDetailsState } from "../../../../schema";

export const AddBuyerPropertyAddress = () => {
  const { values, errors, touched, setFieldValue } =
    useFormikContext<BuyerPropertyDetailsState>();

  const [properties, setProperties] = useState<Property[]>([]);
  const [propertyOptions, setPropertyOptions] = useState<
    { value: string; label: string }[]
  >([]);
  const [propertyErrorMessage, setPropertyErrorMessage] = useState<string>("");

  const handleExistingProperties = async (postcode: string) => {
    setPropertyErrorMessage("");

    try {
      const existingProperties = await ApiController.findProperties(
        formatPostcode(postcode),
      );

      setProperties(existingProperties);

      if (existingProperties.length === 0) {
        setPropertyErrorMessage(typosErrorMessage);
      }
    } catch {
      setPropertyErrorMessage(typosErrorMessage);
    }
  };

  useEffect(() => {
    setPropertyOptions(getAddressesAndPropertiesOptions(properties));
  }, [properties]);

  useEffect(() => {
    const property = properties.find((x) => x._id === values.property_id);
    setFieldValue("propertyAddress", property?.address);
  }, [properties, values.property_id, setFieldValue]);

  return (
    <>
      <PostcodeInput handlePostcodeLookup={handleExistingProperties} isBuyer />

      {propertyErrorMessage !== typosErrorMessage &&
        (properties.length > 0 || values.property_id !== "") && (
          <div className="mt-[16px]">
            <Field name="property_id">
              {({ field }: FieldProps) => {
                return (
                  <Select
                    {...field}
                    options={[
                      {
                        label: "Please select your address",
                        value: "",
                        disabled: true,
                      },
                      ...propertyOptions,
                      {
                        label: "Property not listed",
                        value: "property_not_listed",
                      },
                    ]}
                    name="property_id"
                    id="property_id"
                    variant={
                      errors.property_id && touched.property_id
                        ? "error"
                        : "enabled"
                    }
                  />
                );
              }}
            </Field>
          </div>
        )}
      {propertyErrorMessage && !values.property_id && (
        <ErrorMessageWithEmail propertyErrorMessage={propertyErrorMessage} />
      )}
    </>
  );
};
