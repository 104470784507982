import Typography from "@/foundation/Typography/Typography";
import { useLoginStore } from "../../store/Login/login.ts";
import { TermsAndConditionsTextSeller } from "./TermsAndConditionsTextSeller.tsx";
import { TermsAndConditionsTextBuyer } from "./TermsAndConditionsTextBuyer.tsx";

export const TermsAndConditionsOfUse = () => {
  const { role } = useLoginStore();

  return (
    <div className="w-full pt-[48px] md:pl-[70px] md:pr-[100px]">
      <Typography
        type="h2"
        variant="h3"
        weight="bold"
        className="text-brand-heavy-teal-100 mb-6 font-display"
      >
        Our Terms and Conditions of Use
      </Typography>

      {role === "sell" ? (
        <TermsAndConditionsTextSeller />
      ) : (
        <TermsAndConditionsTextBuyer />
      )}
    </div>
  );
};
