import { useState, useEffect, useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { AdminSideNavigationLabel } from "./AdminSideNavigationLabel";
import { AuthContext } from "../../../store/auth/AuthContext";
import { handleSignOut } from "@utils";
import { useAuthStore } from "../../../store/auth/auth";
import Icons, { TextInputIconProps } from "@/foundation/Icons/Icons";
import Typography from "@/foundation/Typography/Typography";

import Logo from "../../../assets/logos/small-logo.svg";

interface AdminSideNavigationProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AdminSideNavigation = ({
  isOpen,
  setIsOpen,
}: AdminSideNavigationProps) => {
  const location = useLocation();
  const { userData, actions: authStoreActions } = useAuthStore();
  const authStoreContext = useContext(AuthContext);
  const navigate = useNavigate();
  const [isSignedIn, setIsSignedIn] = useState(false);

  useEffect(() => {
    if (userData?.active && userData?.email) {
      setIsSignedIn(true);
    }
  }, [userData, isSignedIn]);

  const menuItems = [
    { label: "Home", path: "/admin", icon: "Home" },
    { label: "Transactions", path: "/admin/transactions", icon: "Transaction" },
    { label: "Users", path: "/admin/users", icon: "Occupiers" },
    {
      label: "Estate Agents",
      path: "/admin/estate-agents",
      icon: "EstateAgentAssigned",
    },
  ];

  const handleOpenNav = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav
      className={`md:fixed flex md:flex-col justify-between bg-brand-heavy-warmth-100 md:pt-[20px] h-[60px] md:h-full md:min-h-screen w-full px-[8px] z-50 ${isOpen ? "md:w-[220px]" : "md:w-[60px]"}`}
    >
      <div
        className={`flex md:flex-col ${isOpen ? "items-start" : "items-center"}`}
      >
        <div className="w-full overflow-clip flex items-center mr-[8px] md:mr-0 md:mb-[12px]">
          <Link
            to="/admin"
            className="flex items-center justify-center bg-brand-electric-100 rounded-lg h-[44px] w-[44px] shrink-0"
          >
            <div className="relative left-[-1px]">
              <img src={Logo} width={24} />
            </div>
          </Link>

          <div>
            <Typography
              type="p"
              variant="base"
              weight="bold"
              className={`text-brand-heavy-teal-100 ml-[12px] ${isOpen ? "visible" : "hidden"}`}
            >
              Propelr
            </Typography>

            <Typography
              type="p"
              variant="xs"
              weight="regular"
              className={`text-brand-heavy-teal-100/50 ml-[12px] text-nowrap overflow-clip ${isOpen ? "visible" : "hidden"}`}
            >
              {userData?.email}
            </Typography>
          </div>
        </div>

        {menuItems.map(({ label, path, icon }) => (
          <div
            key={label}
            className={`relative my-[16px] md:my-[8px] py-[8px] px-[8px] rounded-lg ${isOpen ? "w-full pl-[12px]" : ""} ${location.pathname === path && "bg-brand-heavy-teal-5/50"}`}
          >
            <Link to={path}>
              <div className="flex items-center group relative">
                <Icons iconName={icon as TextInputIconProps} size="18" />

                {!isOpen && <AdminSideNavigationLabel label={label} />}

                <Typography
                  variant="sm"
                  weight="semi-bold"
                  className={`${isOpen ? "visible" : "hidden"} pl-[12px] text-brand-heavy-teal-100`}
                >
                  {label}
                </Typography>
              </div>
            </Link>
          </div>
        ))}
      </div>

      <div
        className={`flex md:flex-col ${isOpen ? "items-start" : "items-center"}`}
      >
        <div
          className={`relative my-[16px] md:my-[8px] py-[8px] px-[8px] rounded-lg ${isOpen ? "w-full pl-[12px]" : ""} ${location.pathname === "/admin/config" && "bg-brand-heavy-teal-5/50"}`}
        >
          <Link to="/admin/config">
            <div className="flex items-center group relative">
              <Icons iconName="Settings" size="18" />

              {!isOpen && <AdminSideNavigationLabel label="Config" />}

              <Typography
                variant="sm"
                weight="semi-bold"
                className={`${isOpen ? "visible" : "hidden"} pl-[16px]`}
              >
                Config
              </Typography>
            </div>
          </Link>
        </div>

        <div
          className={`flex items-center relative my-[16px] md:my-[8px] py-[8px] px-[8px] rounded-lg ${isOpen ? "w-full pl-[12px]" : ""}`}
        >
          <button
            onClick={() =>
              handleSignOut(navigate, authStoreActions, authStoreContext)
            }
          >
            <div className="flex items-center group relative">
              <div className="transform rotate-180">
                <Icons iconName="SignOut" size="18" />
              </div>

              {!isOpen && <AdminSideNavigationLabel label="Sign Out" />}

              <Typography
                variant="sm"
                weight="semi-bold"
                className={`${isOpen ? "visible" : "hidden"} pl-[16px]`}
              >
                Sign Out
              </Typography>
            </div>
          </button>
        </div>

        <div
          className={`hidden md:block relative my-[16px] md:my-[8px] py-[8px] px-[8px] rounded-lg ${isOpen ? "w-full pl-[12px]" : ""}`}
        >
          <button onClick={handleOpenNav}>
            <div className="flex items-center group relative">
              <div className={isOpen ? "transform rotate-180" : ""}>
                <Icons iconName="ArrowRight" size="16" />
              </div>

              {!isOpen && <AdminSideNavigationLabel label="Expand" />}

              <Typography
                variant="sm"
                weight="semi-bold"
                className={`${isOpen ? "visible" : "hidden"} pl-[16px]`}
              >
                Collapse
              </Typography>
            </div>
          </button>
        </div>
      </div>
    </nav>
  );
};
