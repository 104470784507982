import { Banner } from "../components/Banner/Banner";
import { CaseStudies } from "../components/CaseStudies/CaseStudies";
import { Faqs } from "../components/Faqs/Faqs";
import { Footer } from "../components/Footer/Footer";
import { Header } from "../components/Header/Header";
import { Hero } from "../components/Hero/Hero";
import { SimpleSteps } from "../components/SimpleSteps/SimpleSteps";
import { Trustpilot } from "../components/Trustpilot/Trustpilot";
import { useTabletMediaQuery } from "../../mediaQueries";
import { USP } from "../components/USP/USP";
import Typography from "@/foundation/Typography/Typography";
import { ContactFormBanner } from "../components/ContactForm/ContactFormBanner";

import HouseWithTarget from "../../assets/house-with-target.svg";
import ManWithTarget from "../../assets/man-with-target.svg";
import MultipleHouses from "../../assets/multiple-houses.svg";
import PinkShirtMan from "../../assets/pink-shirt-man.png";

export const SellerLandingPage = () => {
  const isTablet = useTabletMediaQuery();

  return (
    <>
      <Header theme="light" />
      <Hero />
      <USP />
      <CaseStudies />

      <Banner
        backgroundColour="bg-brand-heavy-teal-100"
        imageUrl={PinkShirtMan}
      >
        <Typography
          className="font-display text-brand-sand-100 mb-[40px] text-center md:text-left"
          type="h2"
          variant={isTablet ? "h1" : "h2"}
          weight="semi-bold"
        >
          Made for every seller, every situation and every home
        </Typography>

        <div className="flex flex-col items-center text-center md:flex-row md:justify-between md:text-left">
          <div className="flex flex-col mb-[35px] px-[8px] text-center md:text-left">
            <div>
              <img src={ManWithTarget} className="mb-[24px] h-[90px]" />
            </div>

            <Typography
              className="text-brand-sand-100 max-w-[230px]"
              type="p"
              variant="xl"
              weight="semi-bold"
            >
              Whether you have found an estate agent or not
            </Typography>
          </div>

          <div className="flex flex-col mb-[35px] px-[8px] text-center md:text-left">
            <div>
              <img src={HouseWithTarget} className="mb-[24px] h-[90px]" />
            </div>

            <Typography
              className="text-brand-sand-100 max-w-[230px]"
              type="p"
              variant="xl"
              weight="semi-bold"
            >
              Whether you found your next home or not
            </Typography>
          </div>

          <div className="flex flex-col mb-[35px] px-[8px] text-center md:text-left">
            <div>
              <img src={MultipleHouses} className="mb-[24px] h-[90px]" />
            </div>

            <Typography
              className="text-brand-sand-100  max-w-[230px]"
              type="p"
              variant="xl"
              weight="semi-bold"
            >
              Whether you have a house or flat
            </Typography>
          </div>
        </div>
      </Banner>

      <Trustpilot />
      <SimpleSteps />

      <div className="hidden">
        <Faqs />
      </div>

      <ContactFormBanner />
      <Footer />
    </>
  );
};
