import { useState } from "react";

import { Accordion } from "../../components/Accordion/Accordion";
import { Container } from "../../../components/Container/Container";
import BackgroundFade from "../../../assets/background-brand-heavy-warmth-75.png";
import Typography from "@/foundation/Typography/Typography";
import { useTabletMediaQuery } from "../../../mediaQueries";
import { CaseStudiesContent } from "./CaseStudiesContent";

export const CaseStudies = () => {
  const isTablet = useTabletMediaQuery();
  const [openAccordionIndex, setOpenAccordionIndex] = useState<number | null>(
    0,
  );

  const toggleAccordion = (index: number | null) => {
    setOpenAccordionIndex(index === openAccordionIndex ? null : index);
  };

  return (
    <div
      className="bg-brand-heavy-warmth-75 bg-no-repeat bg-contain pt-[40px] md:pt-[140px] lg:pt-[180px]"
      style={{ backgroundImage: `url(${BackgroundFade})` }}
    >
      <div className="bg-brand-heavy-warmth-100 pb-[90px] px-[16px]">
        <Container maxWidth={1150}>
          <div className="pt-[48px] px-[12px] md:px-[24px]">
            <Typography
              className="font-display text-brand-heavy-teal-100 mx-auto text-center mb-[16px]"
              type="h2"
              variant={isTablet ? "h1" : "h2"}
              weight="semi-bold"
            >
              How we helped home sellers like you
            </Typography>

            <Typography
              className="text-brand-heavy-teal-100 mx-auto text-center mb-[60px]"
              type="p"
              variant="lg"
              weight="regular"
            >
              Click the arrows to discover our customers’ home selling stories
            </Typography>
          </div>

          <div className="flex flex-col items-center">
            <div className="max-w-[950px] w-full shrink-0">
              {CaseStudiesContent.map((content, index) => (
                <Accordion
                  key={index}
                  isOpen={openAccordionIndex === index}
                  onToggle={() => toggleAccordion(index)}
                  propertyType={content.propertyType}
                  title={content.title}
                  subtitle={content.subtitle}
                  description={content.description}
                  imageUrl={content.imageUrl}
                  quoteHeadline={content.quoteHeadline}
                  quoteBody={content.quoteBody}
                />
              ))}
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};
