import * as Sentry from "@sentry/react";
import { api } from ".";

export const verifyRecaptcha = async (token: string): Promise<void> => {
  try {
    await api.get("/authentication/verify-recaptcha", {
      params: {
        token,
      },
    });
  } catch (error: unknown) {
    Sentry.captureException(error);

    if (error instanceof Error) {
      throw new Error(`Request to verify captcha failed: ${error.message}`);
    } else {
      throw new Error("Request to verify captcha failed");
    }
  }
};
