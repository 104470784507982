import { create } from "zustand";
import { persist } from "zustand/middleware";

import { AdminUser } from "../../pages/UserDetails/types";

type State = {
  users: AdminUser[];
  updateUsers: (updatedUsers: Partial<AdminUser>[]) => void;
};

export const initialUserStateObj: AdminUser[] = [];

export const useUsersStore = create<State>()(
  persist(
    (set) => ({
      users: initialUserStateObj,
      updateUsers: (updatedUsers) => {
        set((state) => ({
          users: updatedUsers.map((user, index) => ({
            ...(state.users[index] || initialUserStateObj[0]),
            ...user,
          })),
          updateUsers: state.updateUsers,
        }));
      },
    }),
    {
      name: "admin-users",
    },
  ),
);
