import { useField } from "formik";

import { CurrencyInput } from "@/components/Input/CurrencyInput";
import ErrorText from "@/foundation/Typography/ErrorText";
import { FieldSet } from "../../FieldSet/FieldSet";

interface PriceProps {
  priceTitle: string;
  priceDescription?: string;
  typeOfPrice: string;
  placeholder?: string;
  minimumPriceValue?: string;
  className?: string;
}

export const Price = ({
  priceTitle,
  priceDescription = "",
  typeOfPrice,
  placeholder,
  minimumPriceValue,
  className,
}: PriceProps) => {
  const [field, meta] = useField(typeOfPrice);
  return (
    <FieldSet legend={priceTitle} description={priceDescription}>
      <CurrencyInput
        id={field.name}
        name={field.name}
        placeholder={placeholder}
        className={`w-full ${className}`}
        onChange={field.onChange}
        onBlur={field.onBlur}
        value={field.value}
        minimumValue={minimumPriceValue || ""}
        variant={meta.error && meta.touched ? "error" : "enabled"}
      />

      <div className="h-[16px]">
        {meta.error && meta.touched && <ErrorText>{meta.error}</ErrorText>}
      </div>
    </FieldSet>
  );
};
