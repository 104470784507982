import { capitalizeFirstLetter } from "@shared/utils";
import Icon from "@/foundation/Icons/Icons";
import Typography from "@/foundation/Typography/Typography";
import { Role } from "@shared/types";

const renderedSVGs = Array.from({ length: 5 }).map((_, index) => (
  <div key={index} className="mr-[2px]">
    <Icon iconName="TrustPilotStar" colour="enabled" size="24" />
  </div>
));

interface TestimonialProps {
  subtitle: string;
  description: string;
  role: Role;
}

export const Testimonial = ({
  role,
  subtitle,
  description,
}: TestimonialProps) => (
  <div className="md:w-[222px] mb-[36px]" data-testid="item-description-rate">
    <div className="flex items-center">
      <Typography
        type="span"
        variant="h3"
        weight="bold"
        className="bg-brand-teal-25 flex justify-center items-center text-brand-heavy-teal-100 text-left rounded-full h-12 w-[48px] mr-2"
      >
        {capitalizeFirstLetter(role).charAt(0)}
      </Typography>

      <Typography
        type="h3"
        variant="base"
        weight="bold"
        className="text-ui-white-100"
      >
        {capitalizeFirstLetter(role)}er
      </Typography>
    </div>

    <div className="flex items-center my-[12px]">
      <div className="flex my-2">{renderedSVGs}</div>

      <div className="bg-ui-trust-pilot-green-100 flex justify-center items-center rounded-full w-[16px] h-auto p-1 mx-2">
        <Icon iconName="Checkmark" colour="complement" size="8" />
      </div>

      <Typography
        type="span"
        variant="xs"
        weight="regular"
        className="text-brand-heavy-teal-50"
      >
        Verified
      </Typography>
    </div>

    <Typography
      type="h4"
      variant="h4"
      weight="bold"
      className="text-ui-white-100 text-left my-2"
    >
      {subtitle}
    </Typography>

    <Typography
      type="p"
      variant="sm"
      weight="regular"
      className="text-ui-white-100 text-left"
    >
      {description}
    </Typography>
  </div>
);
