import { useState } from "react";
import { Field, FieldProps, FormikProps } from "formik";

import { Button } from "@/components/Button/Button";
import { CheckboxButtons } from "@/components/QuestionType/CheckboxButtons";
import { BuyerPropertyDetailsState } from "../../../../schema";
import { fundingOptions } from "../Fields";
import { Remortgage } from "./Remortgage";
import { RadioButtonQuestion } from "../RadioButtonQuestion/RadioButtonQuestion";
import { TextArea } from "@/components/Input/TextArea";
import { AddGiftorModal } from "../AddPersonModal/AddGiftorModal";
import { AdditionalGiftorSummaryCard } from "../../../AdditionalPerson/AdditionalGiftorSummaryCard";
import { Input } from "../../../Formik/Input/Input";
import { FieldSet } from "../../FieldSet/FieldSet";

interface PurchaseFundedFormProps {
  formik: FormikProps<BuyerPropertyDetailsState>;
}

export const PurchaseFunded = ({ formik }: PurchaseFundedFormProps) => {
  const { values, setFieldValue, setFieldTouched, touched, errors } = formik;

  // Handle Funding Options:
  const handleSelectedFundingOptions = (selectedLabels: string[]) => {
    fundingOptions.forEach(({ label, value }) => {
      if (!selectedLabels.includes("Other")) {
        setFieldValue("funding.otherFundingOption", "");
      }
      setFieldValue(`funding.${value}`, selectedLabels.includes(label));
    });
  };

  const selectedFundingOptionsStorage = {
    "Savings from employment": values.funding.savingsFromEmployment,
    "Investment portfolio": values.funding.investmentPortfolio,
    Inheritance: values.funding.inheritance,
    "Divorce settlement": values.funding.divorceSettlement,
    "Pension lump sum withdrawal": values.funding.pensionLumpSumWithdrawal,
    "Sale of shares": values.funding.saleOfShares,
    "Directors loans dividends": values.funding.directorsLoansDividends,
    Other: values.funding.otherFunding,
  };

  // Handle Giftors:
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onDeletePerson = (id: string) => {
    if (values.giftors && values.giftors.length <= 1) {
      setFieldValue("funding.gifts", null);
    }
    if (values.giftors && values.giftors.length) {
      setFieldValue(
        "giftors",
        values.giftors.filter((giftor) => giftor.personId !== id),
      );
    }
  };

  return (
    <>
      {/* Mortgage */}

      <RadioButtonQuestion
        title="Will this purchase be funded through a mortgage?"
        label="mortgageRequiredForPurchase"
        options={["Yes", "No", "Not Sure"]}
        selectedRadioButtonQuestionOption={
          values.mortgageRequiredForPurchase === null
            ? null
            : values.mortgageRequiredForPurchase === "yes"
              ? "Yes"
              : values.mortgageRequiredForPurchase === "no"
                ? "No"
                : "Not Sure"
        }
        setSelectedRadioButtonQuestionOption={(newValue) => {
          if (newValue === "No" || newValue === "Not Sure") {
            setFieldValue("mortgageProvider", undefined);
          }
          setFieldValue(
            "mortgageRequiredForPurchase",
            newValue === "Yes" ? "yes" : newValue === "No" ? "no" : "not_sure",
          );
        }}
        radioButtonQuestionError={
          touched.mortgageRequiredForPurchase
            ? errors.mortgageRequiredForPurchase
            : ""
        }
      />
      {values.mortgageRequiredForPurchase === "yes" && (
        <Remortgage formik={formik} />
      )}

      {/* Gifts */}

      <RadioButtonQuestion
        title="Will this purchase be funded through gifts?"
        description="A gift refers to money given to you by family and/or friends to help you
        buy the property without the expectation of being repaid. The person(s)
        gifting the money will have no rights nor legal interest in the property
        being purchased."
        label="gifts"
        options={["Yes", "No", "Not Sure"]}
        selectedRadioButtonQuestionOption={
          values.funding.gifts === null
            ? null
            : values.funding.gifts === "yes"
              ? "Yes"
              : values.funding.gifts === "no"
                ? "No"
                : "Not Sure"
        }
        setSelectedRadioButtonQuestionOption={(newValue) => {
          setFieldValue(
            "funding.gifts",
            newValue === "Yes" ? "yes" : newValue === "No" ? "no" : "not_sure",
          );
          if (newValue === "Yes") {
            setIsModalOpen(true);
          } else {
            setFieldValue("giftors", []);
          }
        }}
        radioButtonQuestionError={
          touched.funding?.gifts ? errors.funding?.gifts : ""
        }
      />

      {isModalOpen && (
        <AddGiftorModal
          onCancel={() => {
            if (values.giftors && values.giftors.length <= 1) {
              setFieldValue("funding.gifts", null);
              setFieldValue("giftors", []);
            }
            setIsModalOpen(false);
          }}
          setIsModalOpen={setIsModalOpen}
        />
      )}

      <div className="md:flex">
        {values.giftors.map((giftor, index) => {
          return (
            <div
              key={`giftor-summary-${index}`}
              className="flex md:flex-row md:flex-wrap flex-col w-full mt-[40px]"
            >
              <AdditionalGiftorSummaryCard
                values={giftor}
                onDeletePerson={(id) => onDeletePerson(id)}
              />
            </div>
          );
        })}
      </div>

      {values.giftors && values.giftors.length > 0 && (
        <div className="flex w-80">
          <Button
            variant="primary"
            className="w-[320px]"
            type="button"
            onClick={() => setIsModalOpen(true)}
            icon="PlusWhite"
          >
            Add another giftor
          </Button>
        </div>
      )}

      {/* Funding Options */}

      <FieldSet
        legend="How is the purchase being funded?"
        description="Select as many as apply."
      >
        <div className="flex">
          <CheckboxButtons
            options={fundingOptions.map((option) => option)}
            initialSelectedOptions={selectedFundingOptionsStorage}
            setSelectedOptions={(selectedValues) => {
              setTimeout(() =>
                setFieldTouched("funding.otherFundingOption", true),
              );
              handleSelectedFundingOptions(selectedValues);
            }}
          />
        </div>
        {values.funding.otherFunding && (
          <div className="w-full md:max-w-[320px]">
            <Input
              fieldName="funding.otherFundingOption"
              label="Please give details"
            />
          </div>
        )}
      </FieldSet>

      {/* Other Funding Comments */}

      <FieldSet
        legend="Is there anything your solicitor should be aware of about your funding
          arrangements?"
        description=" Use the comment box below to provide additional context, other important
        information, or anything that you’re particularly uncertain of about
        your funding arrangements to your solicitor."
      >
        <Field name="funding.otherFundingComments">
          {({ field }: FieldProps) => (
            <TextArea
              {...field}
              id="funding.otherFundingComments"
              name="funding.otherFundingComments"
              label="Please give details"
            />
          )}
        </Field>
      </FieldSet>
    </>
  );
};
