import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { useState } from "react";

import { ApiController } from "../../service/ApiController";
import { notifications, Notification } from "../../components/Notification";
import { sellerItems, buyerItems } from "./items";
import { Testimonial } from "../../components/Testimonial/Testimonial";
import { useAuthStore } from "../../store/auth/auth";
import { useLoginStore } from "../../store/Login/login";
import Icons, { TextInputIconProps } from "@/foundation/Icons/Icons";
import Typography from "@/foundation/Typography/Typography";
import { SignUp, SignUpSuccess } from "../../components/SignUp";

import { SignUpType } from "@schema";
import { useLastCustomerSignIn } from "@utils";
import Logo from "../../assets/logo-horizontal.svg";

export const GetStarted = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [errorMessage, setErrorMessage] = useState("");
  const [lessThan5Min, setLessThan5Min] = useState(false);

  const { userData } = useAuthStore();
  const { updateField, isRegistered, setIsRegistered, role, setRole } =
    useLoginStore();
  useLastCustomerSignIn(setIsRegistered, setLessThan5Min);

  const items = role === "buy" ? buyerItems : sellerItems;
  let pageTitle;

  if (role === "buy") {
    pageTitle = (
      <>
        <span className="text-brand-warmth-100">Move sooner</span> and
        <span className="text-brand-warmth-100"> save money </span>
        on your purchase
      </>
    );
  } else {
    pageTitle = (
      <>
        <span className="text-brand-warmth-100">Fast</span> and{" "}
        <span className="text-brand-warmth-100">confident</span> sales
      </>
    );
  }

  const handleSwitcher = () => {
    navigate(role === "buy" ? "/sell/get-started" : "/buy/get-started");
    setRole(role === "buy" ? "sell" : "buy");
  };

  const handleSignUp = async (values: SignUpType) => {
    try {
      setErrorMessage("");

      Object.entries(values).forEach(([key, value]) => {
        updateField(key as keyof SignUpType, value ?? "");
      });

      const preventEmail = searchParams.get("preventEmail");
      const response = await ApiController.signUp(values, role, !!preventEmail);

      if (response.redirectTo) {
        navigate(`${response.redirectTo}?account=true`);
      } else {
        setIsRegistered(true);

        const currentTime = Date.now();
        localStorage.setItem("lastClickTime", String(currentTime));
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        setErrorMessage(error.message);
        console.error(error.message);
      } else {
        setErrorMessage(
          "An unexpected error has occured. Please try again later.",
        );
      }
    }
  };

  if (userData) {
    return <Navigate to={`/welcome-back`} replace />;
  }

  return (
    <div className="bg-brand-heavy-teal-100 bg-[url('/src/assets/house-texture-clipped.svg')] bg-no-repeat bg-left-bottom">
      {notifications.map((n) => (
        <Notification key={n.params} params={n.params} message={n.message} />
      ))}

      <div className="flex flex-col lg:flex-row md:justify-center items-center p-[12px] md:p-[24px] lg:min-h-screen">
        <div
          className="w-full flex flex-col lg:flex-row justify-center items-center"
          data-testid="sign-in-description"
        >
          <div className="lg:hidden">
            <div className="flex flex-col items-start w-full max-w-[498px]">
              <Typography
                variant="h1"
                type="h1"
                weight="semi-bold"
                className="font-display text-ui-white-100 mt-[100px] mb-[32px] text-center lg:text-left"
              >
                {pageTitle}
              </Typography>
            </div>
          </div>

          <div className="mb-[50px] lg:mr-[100px]">
            <div
              className="bg-brand-medium-sand-50 rounded-2xl w-full max-w-[657px] flex flex-col justify-center items-center py-[50px] md:py-[75px] px-[25px] md:px-[125px]"
              data-testid="sign-in-container"
            >
              <img src={Logo} className="mb-[36px]" />

              {isRegistered ? (
                <SignUpSuccess
                  handleSignUp={handleSignUp}
                  lessThan5Min={lessThan5Min}
                  setLessThan5Min={setLessThan5Min}
                />
              ) : (
                <SignUp
                  handleSignUp={handleSignUp}
                  errorMessage={errorMessage}
                />
              )}
            </div>

            <div className="flex flex-row items-center justify-center">
              <button
                className="flex items-center mt-[24px] bg-brand-heavy-teal-100/80 border-2 border-brand-teal-100 rounded-xl py-[12px] px-[24px]"
                onClick={handleSwitcher}
              >
                <div className="mr-[8px]">
                  <Icons iconName="Switch" colour="complement" size="24" />
                </div>

                <Typography
                  type="p"
                  variant="sm"
                  weight="bold"
                  className="text-ui-white-100/80 pr-[8px]"
                >
                  {role === "sell" ? "Buying a property" : "Selling a property"}{" "}
                  through Propelr. Click here
                </Typography>
              </button>
            </div>
          </div>

          <div className="flex flex-col md:flex-wrap md:flex-row justify-between max-w-[498px] px-[24px] md:px-0">
            <div className="hidden lg:block">
              <div className="flex flex-col items-start w-full max-w-[498px]">
                <Typography
                  variant="h1"
                  type="h1"
                  weight="semi-bold"
                  className="font-display text-ui-white-100 mb-[32px] text-center lg:text-left"
                >
                  {pageTitle}
                </Typography>
              </div>
            </div>

            <div className="flex flex-row gap-[16px]">
              {items.firstLine.map((item) => (
                <div key={item.id}>
                  <div
                    className="md:w-[222px] mb-[36px]"
                    data-testid="item-description"
                  >
                    <Icons
                      iconName={item.icon as TextInputIconProps}
                      colour="electric"
                      size="36"
                    />

                    <Typography
                      type="h3"
                      variant="h3"
                      weight="bold"
                      className="font-display text-ui-white-100 text-left my-[12px]"
                    >
                      {item.subtitle}
                    </Typography>
                  </div>
                </div>
              ))}
            </div>

            <div className="w-full">
              <hr className="mb-[32px] border-t border-brand-heavy-teal-50" />
            </div>

            {items.secondLine.map((item) => (
              <div key={item.id}>
                <Testimonial
                  role={role}
                  subtitle={item.subtitle}
                  description={item.description}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
