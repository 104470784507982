import Typography from "@/foundation/Typography/Typography";

interface AdminSideNavigationLabelProps {
  label: string;
}

export const AdminSideNavigationLabel = ({
  label,
}: AdminSideNavigationLabelProps) => (
  <Typography
    variant="xs"
    className="hidden md:group-hover:block absolute left-[30px] top-[-2px] bg-brand-heavy-teal-100 text-ui-white-100 px-[8px] py-[4px] rounded-[4px] transition-all duration-300 ease-in-out text-nowrap"
  >
    {label}
  </Typography>
);
