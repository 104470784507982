import { EMAIL_ADDRESS } from "../../../constants";
import { initialLoginState } from "../../../store/Login/login";
import { LinkWrapper } from "@/components/LinkWrapper/LinkWrapper";
import { SignInForm } from "../../Form/SignInForm/SignInForm";
import Typography from "@/foundation/Typography/Typography";

import { SignInType } from "@schema";

export type SignInProps = {
  handleSignIn: (values: SignInType) => void;
  errorMessage: string;
};

export const SignIn = ({ handleSignIn, errorMessage }: SignInProps) => (
  <>
    <Typography
      className="font-display text-brand-heavy-teal-100 text-center"
      type="h2"
      variant="h2"
      weight="semi-bold"
      data-testid="login-title"
    >
      Sign In
    </Typography>

    {errorMessage ? (
      <Typography
        type="p"
        variant="xl"
        weight="semi-bold"
        className="text-ui-error-100 text-center my-[16px]"
      >
        {errorMessage}
      </Typography>
    ) : (
      <SignInForm onSubmit={handleSignIn} state={initialLoginState} />
    )}

    <Typography
      type="p"
      variant="sm"
      weight="regular"
      className="text-ui-heavy-teal-50"
    >
      To get help email{" "}
      <LinkWrapper>
        <a href={`mailto:${EMAIL_ADDRESS.SUPPORT}`}>{EMAIL_ADDRESS.SUPPORT}</a>
      </LinkWrapper>
    </Typography>
  </>
);
