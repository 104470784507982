import { Background } from "../../components/Background/Background";
import { Nav } from "../../components/Nav/Nav";
import { Header } from "../../components/Header/Header";
import { buyWhatsIncluded } from "../../components/Header/allStepperOptions";
import { Container } from "../../components/Container/Container";
import { FeesContainer } from "../../components/FeesContainer/FeesContainer";
import StepperGroup from "@/components/Stepper/StepperGroup";

export const Fees = () => (
  <Background className="flex flex-col">
    <Nav />

    <Container>
      <div className="relative flex flex-col mb-[160px] px-[24px] lg:px-0">
        <Header title="What’s included">
          <div className="md:max-w-[916px] lg:max-w-[1116px]">
            <StepperGroup options={buyWhatsIncluded}></StepperGroup>
          </div>
        </Header>

        <FeesContainer />
      </div>
    </Container>
  </Background>
);
