import * as Sentry from "@sentry/react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useEffect, useState } from "react";

import { CheckoutForm } from "./CheckoutForm";
import { PaymentSummary } from "./PaymentSummary";
import { StripeError } from "./StripeError";

import Typography from "@/foundation/Typography/Typography";
import { ApiController } from "../../service/ApiController/index";
import { PaymentIntentResponseSchema } from "@shared/schemas";
import { products } from "./products";
import { Product, StripeCustomerId } from "@shared/types";
import { STRIPE_CLIENT_API_KEY, EMAIL_ADDRESS } from "../../constants";

const stripePromise = loadStripe(STRIPE_CLIENT_API_KEY);

export interface PaymentMethodProps {
  stripeCustomerId: StripeCustomerId;
  transactionId: string;
  stripeError: boolean;
  setStripeError: React.Dispatch<React.SetStateAction<boolean>>;
}

export const PaymentMethod = ({
  stripeError,
  setStripeError,
  stripeCustomerId,
  transactionId,
}: PaymentMethodProps) => {
  const [clientSecret, setClientSecret] = useState("");
  const [subtotalOrderAmount, setSubtotalOrderAmount] = useState<number>(0);
  const [totalOrderAmount, setTotalOrderAmount] = useState<number>(0);
  const [vatOrderAmount, setVatOrderAmount] = useState<number>(0);

  const calculateTotalOrderAmount = (products: Product[]) => {
    const total = products.reduce((acc, product) => {
      return acc + product.price * product.quantity;
    }, 0);

    setTotalOrderAmount(total);
  };

  const calculateSubtotalOrderAmount = (products: Product[]) => {
    const subtotal = products.reduce((acc, product) => {
      if (product.incVat) {
        return acc + (product.price / 1.2) * product.quantity;
      } else {
        return acc + product.price * product.quantity;
      }
    }, 0);

    setSubtotalOrderAmount(subtotal);
  };

  useEffect(() => {
    calculateTotalOrderAmount(products);
    calculateSubtotalOrderAmount(products);

    if (totalOrderAmount > 0 && subtotalOrderAmount > 0) {
      setVatOrderAmount(totalOrderAmount - subtotalOrderAmount);
    }
  }, [totalOrderAmount, subtotalOrderAmount]);

  useEffect(() => {
    const fetchStripePaymentIntent = async () => {
      try {
        const response = await ApiController.fetchPaymentIntent(
          totalOrderAmount,
          stripeCustomerId,
          transactionId,
          products,
        );

        const { clientSecret } = PaymentIntentResponseSchema.parse(response);
        setClientSecret(clientSecret);
      } catch (error) {
        setStripeError(true);
        console.error("Error fetching Stripe client secret:", error);
        Sentry.captureException(error);
      }
    };

    if (totalOrderAmount > 0) {
      fetchStripePaymentIntent();
    }
  }, [totalOrderAmount, stripeCustomerId, transactionId, setStripeError]);

  const appearance = {
    theme: "stripe" as const,
    variables: {
      spacingUnit: "6px",
      colorText: "#666666",
      colorDanger: "#B04141",
      fontFamily: "'Inter Tight', sans-serif",
      borderRadius: "8px",
    },
    rules: {
      ".Label": {
        fontSize: "14px",
      },
      ".Error": {
        fontSize: "12px",
      },
    },
  };

  const options = {
    clientSecret,
    appearance,
    fonts: [
      {
        cssSrc:
          "https://fonts.googleapis.com/css2?family=Source+Sans+3:wght@600&display=swap",
      },
    ],
  };

  return (
    <div className="md:pb-[150px]">
      <Typography
        type="h2"
        variant="h4"
        weight="bold"
        className="text-brand-heavy-teal-100"
        data-testid="payment-summary"
      >
        Payment summary
      </Typography>

      <PaymentSummary
        totalAmount={totalOrderAmount}
        subtotalOrderAmount={subtotalOrderAmount}
        vatOrderAmount={vatOrderAmount}
      />

      <div className="mt-[48px]">
        {clientSecret && (
          <>
            <Typography
              type="h2"
              variant="h4"
              weight="bold"
              className="text-brand-heavy-teal-100 mb-[24px]"
            >
              Payment method
            </Typography>

            <Elements options={options} stripe={stripePromise}>
              <CheckoutForm totalAmount={totalOrderAmount} />
            </Elements>
          </>
        )}

        {stripeError && (
          <StripeError title="Payment form failed to load">
            <Typography
              type="p"
              variant="sm"
              weight="regular"
              className="text-ui-error-100 ml-[36px] mb-[8px]"
            >
              Please refresh the page and try again.
            </Typography>

            <Typography
              type="p"
              variant="sm"
              weight="regular"
              className="text-ui-error-100 ml-[36px] mb-[12px]"
            >
              If the issue persists, please email{" "}
              <a href={`mailto:${EMAIL_ADDRESS.SUPPORT}`} className="underline">
                {EMAIL_ADDRESS.SUPPORT}
              </a>
              .
            </Typography>
          </StripeError>
        )}
      </div>
    </div>
  );
};
