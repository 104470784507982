import { Field, FieldProps, useFormikContext } from "formik";

import Typography from "@/foundation/Typography/Typography";
import ErrorText from "@/foundation/Typography/ErrorText";
import Icons from "@/foundation/Icons/Icons";
import { Roles } from "@shared/types";
import { isSeller } from "@shared/utils";
import { getLawyerGroupName } from "./utils";
import { LawyerGroupFormState } from "./AssignSolicitorsBlock";
import { Select } from "@/components/Input/Select";
import { LawyerGroup } from "../../../types";

interface AssignSolicitorsProps {
  lawyerGroups: LawyerGroup[];
  isInputDisabled: boolean;
  displayConfirmCard: boolean;
}

export const AssignSolicitors = ({
  lawyerGroups,
  isInputDisabled,
  displayConfirmCard,
}: AssignSolicitorsProps) => {
  const { errors } = useFormikContext<LawyerGroupFormState>();

  const getLawyerGroupOptions = () => {
    return [
      { label: "Select a solicitor", value: "", disabed: true },
      ...lawyerGroups.map((x) => ({
        label: getLawyerGroupName(x),
        value: x._id,
      })),
    ];
  };

  return (
    <>
      {[Roles.Seller, Roles.Buyer].map((role) => {
        const lowerCaseRole = role.toLowerCase();
        const error =
          errors[`${lowerCaseRole}_lawyer_group` as keyof LawyerGroupFormState];
        return (
          <div key={role}>
            <Typography
              type="h2"
              variant="xl"
              weight="bold"
              className="text-brand-heavy-teal-100 my-[16px]"
            >
              {`${role} Solicitor`}
            </Typography>
            <div className="flex flex-col w-[60%]">
              <div
                className="flex flex-col w-[60%]"
                data-testid="assign-lawyer-group"
              >
                <Field name={`${lowerCaseRole}_lawyer_group`}>
                  {({ field }: FieldProps) => {
                    return (
                      <Select
                        {...field}
                        data-testid={`${lowerCaseRole}_lawyer_group`}
                        name={`${lowerCaseRole}_lawyer_group`}
                        id={`${lowerCaseRole}_lawyer_group`}
                        options={getLawyerGroupOptions()}
                        variant={
                          isInputDisabled || displayConfirmCard
                            ? "disabled"
                            : error
                              ? "error"
                              : "enabled"
                        }
                      />
                    );
                  }}
                </Field>
                <div className="mt-[16px]">
                  <ErrorText>{error}</ErrorText>
                </div>
              </div>
              {isSeller(role) && (
                <div className="mx-auto my-[12px]">
                  <Icons iconName="DoubleArrow" colour="complement" size="24" />
                </div>
              )}
            </div>
          </div>
        );
      })}
    </>
  );
};
