import * as Sentry from "@sentry/react";

import { api } from "../../../service/ApiController";
import { AdminTransaction } from "../../pages/TransactionDetails/types";

export const updateEstateAgentTransaction = async (
  transactionId: string,
  estateAgentId: string,
): Promise<AdminTransaction> => {
  try {
    const response = await api.put(
      `/transaction/${transactionId}/estate-agent`,
      {
        estateAgentId,
      },
    );
    return response.data;
  } catch (error) {
    Sentry.captureException(error);

    throw new Error(
      `There was an error updating the estate agent at transaction: ${error}`,
    );
  }
};
