import { useEffect, useState } from "react";

import { Card } from "@/components/Card/Card";
import { Table, TableProps } from "../Table/Table";
import { EstateAgent } from "../../../types";
interface EstateAgentOfficeBlockProps {
  office: EstateAgent["office"];
}

type TableAndCopyOptions = TableProps & { includeCopyButton?: boolean };

export const EstateAgentOfficeBlock = ({
  office,
}: EstateAgentOfficeBlockProps) => {
  const [fields, setFields] = useState<TableAndCopyOptions[]>([]);

  useEffect(() => {
    const officeFields = [
      {
        label: "Name",
        value: office?.name,
      },
      {
        label: "Phone Number",
        value: office?.phone_number,
      },
      { label: "Address Line 1", value: office?.address?.line_1 },
      { label: "Address Line 2", value: office?.address?.line_2 },
      { label: "Address Line 3", value: office?.address?.line_3 },
      { label: "Post Town", value: office?.address?.post_town },
      { label: "Country", value: office?.address?.country },
      { label: "Postcode", value: office?.address?.postcode },
    ];

    setFields(officeFields);
  }, [office]);

  return (
    <>
      <Card
        title="Office"
        titleBackgroundColor="brand-heavy-warmth-25"
        titleColor="brand-heavy-teal-100"
      >
        <>
          {fields.map(({ value, label, includeCopyButton }) => (
            <div key={label} className="flex jusify-top">
              <Table
                key={value as string}
                label={label}
                value={value}
                includeCopyButton={includeCopyButton}
              />
            </div>
          ))}
        </>
      </Card>
    </>
  );
};
