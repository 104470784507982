// This function transforms an array of Sellers and returns
// the single primary seller.

import { transformSeller } from "./transformSeller";
import { Seller } from "../admin/pages/TransactionDetails/types";
import { PersonalDetailsWithAddressState } from "../schema";

export const transformTransactionSellerState = (
  sellers: Seller[],
): PersonalDetailsWithAddressState => {
  const primarySeller = sellers.find((seller) => seller.primary_customer);

  if (!primarySeller) {
    throw Error("Could not find primary seller to transform to state");
  }

  return transformSeller(primarySeller);
};
