import { useEffect, useRef, useState } from "react";
import Icons from "@/foundation/Icons/Icons";
import Typography from "@/foundation/Typography/Typography";

interface SimpleAccordionProps {
  isOpen: boolean;
  onToggle: () => void;
  title: string;
  description: string;
}

export const SimpleAccordion = ({
  isOpen,
  onToggle,
  title,
  description,
}: SimpleAccordionProps) => {
  const [descriptionHeight, setDescriptionHeight] = useState<
    number | undefined
  >(undefined);
  const descriptionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (descriptionRef.current) {
      setDescriptionHeight(descriptionRef.current.clientHeight);
    }
  }, [description, isOpen]);

  return (
    <div className="rounded-2xl bg-ui-white-100 px-[14px] py-[32px] md:p-[40px] w-full mb-[24px]">
      <div className="relative flex flex-col md:flex-row">
        <button onClick={() => onToggle()}>
          <Typography
            className="text-brand-heavy-teal-100 text-left pr-[24px]"
            type="h3"
            variant="lg"
            weight="regular"
          >
            {title}
          </Typography>

          <div
            className={`absolute w-[24px] h-[24px] top-0 right-0 md:w-[50px] md:h-[50px] md:top-[50%] md:right-[-20px] md:mt-[-25px] rounded-full flex items-center justify-center ${isOpen ? "bg-brand-warmth-100" : "bg-transparent"}`}
          >
            <span className={isOpen ? "rotate-180" : "rotate-0"}>
              <Icons
                iconName="ArrowDown"
                colour={isOpen ? "complement" : "enabled"}
                size="12"
              />
            </span>
            <span className="hidden">Open</span>
          </div>
        </button>
      </div>

      <div
        className="transition-all duration-300 ease-in-out overflow-hidden"
        style={{ maxHeight: isOpen ? descriptionHeight : 0 }}
      >
        <div ref={descriptionRef}>
          <Typography
            className="text-brand-heavy-teal-100 pt-[30px] w-full max-w-[700px]"
            type="p"
            variant="sm"
            weight="regular"
          >
            {description}
          </Typography>
        </div>
      </div>
    </div>
  );
};
