import { ContactFormBanner } from "../components/ContactForm/ContactFormBanner";
import { Container } from "../../components/Container/Container";
import { Footer } from "../components/Footer/Footer";
import { Header } from "../components/Header/Header";
import { useTabletMediaQuery } from "../../mediaQueries";
import Typography from "@/foundation/Typography/Typography";

export const CookiePolicy = () => {
  const isTablet = useTabletMediaQuery();

  return (
    <>
      <Header theme="dark" />

      <div className="bg-[url('/src/assets/background-header-swoop.svg')] bg-cover bg-no-repeat bg-bottom pt-[96px] md:mb-[50px]">
        <Container maxWidth={1150}>
          <div className="flex flex-col items-start justify-center h-[150px] md:h-[250px] px-[24px] lg:px-0">
            <Typography
              className="font-display text-center text-brand-heavy-teal-100 mb-[16px]"
              type="h1"
              variant={isTablet ? "h1" : "h2"}
              weight="semi-bold"
            >
              Cookie Policy
            </Typography>
          </div>
        </Container>
      </div>

      <Container maxWidth={1150}>
        <div className="mb-[100px] px-[24px] lg:px-0">
          <div className="md:w-[60%] px-[16px] md:px-0">
            <Typography
              className="font-display text-brand-heavy-teal-100 mb-[16px] mt-[36px]"
              type="h2"
              variant="h3"
              weight="semi-bold"
            >
              1. Introduction
            </Typography>

            <Typography
              className="text-brand-heavy-teal-100 mb-[16px]"
              type="p"
              variant="base"
              weight="regular"
            >
              Our website,{" "}
              <a href="https://propelr.co.uk">https://propelr.co.uk</a>{" "}
              (hereinafter: “the website”) uses cookies and other related
              technologies (for convenience all technologies are referred to as
              “cookies”). Cookies are also placed by third parties we have
              engaged. In the document below we inform you about the use of
              cookies on our website.
            </Typography>

            <Typography
              className="font-display text-brand-heavy-teal-100 mb-[16px] mt-[36px]"
              type="h2"
              variant="h3"
              weight="semi-bold"
            >
              2. What are cookies?
            </Typography>

            <Typography
              className="text-brand-heavy-teal-100 mb-[16px]"
              type="p"
              variant="base"
              weight="regular"
            >
              A cookie is a small simple file that is sent along with pages of
              this website and stored by your browser on the hard drive of your
              computer or another device. The information stored therein may be
              returned to our servers or to the servers of the relevant third
              parties during a subsequent visit.
            </Typography>

            <Typography
              className="font-display text-brand-heavy-teal-100 mb-[16px] mt-[36px]"
              type="h2"
              variant="h3"
              weight="semi-bold"
            >
              3. What are scripts?
            </Typography>

            <Typography
              className="text-brand-heavy-teal-100 mb-[16px]"
              type="p"
              variant="base"
              weight="regular"
            >
              A script is a piece of program code that is used to make our
              website function properly and interactively. This code is executed
              on our server or on your device.
            </Typography>

            <Typography
              className="font-display text-brand-heavy-teal-100 mb-[16px] mt-[36px]"
              type="h2"
              variant="h3"
              weight="semi-bold"
            >
              4. Cookies
            </Typography>

            <Typography
              className="font-display text-brand-heavy-teal-100 mb-[16px]"
              type="h2"
              variant="h4"
              weight="semi-bold"
            >
              4.1 Technical or functional cookies
            </Typography>

            <Typography
              className="text-brand-heavy-teal-100 mb-[16px]"
              type="p"
              variant="base"
              weight="regular"
            >
              Some cookies ensure that certain parts of the website work
              properly and that your user preferences remain known. By placing
              functional cookies, we make it easier for you to visit our
              website. This way, you do not need to repeatedly enter the same
              information when visiting our website and, for example, the items
              remain in your shopping cart until you have paid. We may place
              these cookies without your consent.
            </Typography>

            <Typography
              className="font-display text-brand-heavy-teal-100 mb-[16px] mt-[36px]"
              type="h2"
              variant="h3"
              weight="semi-bold"
            >
              5. Placed cookies
            </Typography>

            <Typography
              className="text-brand-heavy-teal-100 mb-[16px]"
              type="p"
              variant="base"
              weight="regular"
            >
              Details of specific cookies placed on our website are outlined in
              this section, if applicable.
            </Typography>

            <Typography
              className="font-display text-brand-heavy-teal-100 mb-[16px] mt-[36px]"
              type="h2"
              variant="h3"
              weight="semi-bold"
            >
              6. Consent
            </Typography>

            <Typography
              className="text-brand-heavy-teal-100 mb-[16px]"
              type="p"
              variant="base"
              weight="regular"
            >
              When you visit our website for the first time, we will show you a
              pop-up with an explanation about cookies. As soon as you click on
              “Save preferences”, you consent to us using the categories of
              cookies and plug-ins you selected in the pop-up, as described in
              this Cookie Policy. You can disable the use of cookies via your
              browser, but please note that our website may no longer work
              properly.
            </Typography>

            <Typography
              className="font-display text-brand-heavy-teal-100 mb-[16px]"
              type="h2"
              variant="h4"
              weight="semi-bold"
            >
              6.1 Manage your consent settings
            </Typography>

            <Typography
              className="text-brand-heavy-teal-100 mb-[16px]"
              type="p"
              variant="base"
              weight="regular"
            >
              You have loaded the Cookie Policy without javascript support. On
              AMP, you can use the manage consent button on the bottom of the
              page.
            </Typography>

            <Typography
              className="font-display text-brand-heavy-teal-100 mb-[16px] mt-[36px]"
              type="h2"
              variant="h3"
              weight="semi-bold"
            >
              7. Enabling/disabling and deleting cookies
            </Typography>

            <Typography
              className="text-brand-heavy-teal-100 mb-[16px]"
              type="p"
              variant="base"
              weight="regular"
            >
              You can use your internet browser to automatically or manually
              delete cookies. You can also specify that certain cookies may not
              be placed. Another option is to change the settings of your
              internet browser so that you receive a message each time a cookie
              is placed. For more information about these options, please refer
              to the instructions in the Help section of your browser.
            </Typography>

            <Typography
              className="text-brand-heavy-teal-100 mb-[16px]"
              type="p"
              variant="base"
              weight="regular"
            >
              Please note that our website may not work properly if all cookies
              are disabled. If you do delete the cookies in your browser, they
              will be placed again after your consent when you visit our
              websites again.
            </Typography>

            <Typography
              className="font-display text-brand-heavy-teal-100 mb-[16px] mt-[36px]"
              type="h2"
              variant="h3"
              weight="semi-bold"
            >
              8. Your rights with respect to personal data
            </Typography>

            <Typography
              className="text-brand-heavy-teal-100 mb-[16px]"
              type="p"
              variant="base"
              weight="regular"
            >
              You have the following rights with respect to your personal data:
            </Typography>

            <ul className="list-disc list-outside" role="list">
              <li>
                <Typography
                  className="text-brand-heavy-teal-100 mb-[16px]"
                  type="p"
                  variant="base"
                  weight="regular"
                >
                  You have the right to know why your personal data is needed,
                  what will happen to it, and how long it will be retained for.
                </Typography>
              </li>
              <li>
                <Typography
                  className="text-brand-heavy-teal-100 mb-[16px]"
                  type="p"
                  variant="base"
                  weight="regular"
                >
                  Right of access: You have the right to access your personal
                  data that is known to us.
                </Typography>
              </li>
              <li>
                <Typography
                  className="text-brand-heavy-teal-100 mb-[16px]"
                  type="p"
                  variant="base"
                  weight="regular"
                >
                  Right to rectification: you have the right to supplement,
                  correct, have deleted or blocked your personal data whenever
                  you wish.
                </Typography>
              </li>
              <li>
                <Typography
                  className="text-brand-heavy-teal-100 mb-[16px]"
                  type="p"
                  variant="base"
                  weight="regular"
                >
                  If you give us your consent to process your data, you have the
                  right to revoke that consent and to have your personal data
                  deleted.
                </Typography>
              </li>
              <li>
                <Typography
                  className="text-brand-heavy-teal-100 mb-[16px]"
                  type="p"
                  variant="base"
                  weight="regular"
                >
                  Right to transfer your data: you have the right to request all
                  your personal data from the controller and transfer it in its
                  entirety to another controller.
                </Typography>
              </li>
              <li>
                <Typography
                  className="text-brand-heavy-teal-100 mb-[16px]"
                  type="p"
                  variant="base"
                  weight="regular"
                >
                  Right to object: you may object to the processing of your
                  data. We comply with this, unless there are justified grounds
                  for processing.
                </Typography>
              </li>
            </ul>

            <Typography
              className="text-brand-heavy-teal-100 mb-[16px]"
              type="p"
              variant="base"
              weight="regular"
            >
              To exercise these rights, please contact us. Please refer to the
              contact details at the bottom of this Cookie Policy. If you have a
              complaint about how we handle your data, we would like to hear
              from you, but you also have the right to submit a complaint to the
              supervisory authority (the Information Commissioner’s Office
              (ICO)).
            </Typography>

            <Typography
              className="font-display text-brand-heavy-teal-100 mb-[16px] mt-[36px]"
              type="h2"
              variant="h3"
              weight="semi-bold"
            >
              9. Contact details
            </Typography>

            <Typography
              className="text-brand-heavy-teal-100 mb-[16px]"
              type="p"
              variant="base"
              weight="regular"
            >
              For questions and/or comments about our Cookie Policy and this
              statement, please contact us by using the following contact
              details:
            </Typography>

            <Typography
              className="text-brand-heavy-teal-100 mb-[16px] leading-7"
              type="p"
              variant="base"
              weight="regular"
            >
              <strong>Propelr Property Limited</strong>
              <br />
              71 Queen Victoria Street, London, United Kingdom, EC4V 4BE
              <br />
              <a href="https://www.propelr.co.uk" className="underline">
                https://www.propelr.co.uk
              </a>
              <br />
              <a href="mailto:fin@propelr.co.uk" className="underline">
                fin@propelr.co.uk
              </a>
            </Typography>
          </div>
        </div>
      </Container>

      <ContactFormBanner />
      <Footer />
    </>
  );
};
