import Typography from "../../foundation/Typography/Typography";
import Icons from "../../foundation/Icons/Icons";

interface GenericPriceCardProps {
  card: { [key: string]: string | number | undefined };
}

export const GenericPriceCard = ({ card }: GenericPriceCardProps) => (
  <div className="relative flex flex-col bg-ui-white-100 shadow-questionType rounded-xl max-w-[555px] mb-[30px] border border-ui-black-10">
    <div className="bg-ui-white-100 flex flex-col rounded-xl ">
      <div className="h-[64px] flex justify-between items-center bg-brand-heavy-warmth-50 rounded-t-xl px-[32px]">
        <div>
          <Typography
            type="h4"
            variant="xl"
            weight="bold"
            className="text-brand-heavy-teal-100"
          >
            {card.name}
          </Typography>
        </div>

        <div>
          <div className="flex items-center gap-x-[12px]">
            <Typography
              type="h4"
              variant="xl"
              weight="bold"
              className="text-brand-heavy-teal-100"
            >
              £0
            </Typography>
            <Typography
              type="h4"
              variant="xl"
              weight="bold"
              className="text-brand-heavy-teal-50"
            >
              <del>{card.price}</del>
            </Typography>
          </div>
        </div>
      </div>
    </div>

    <div className="flex flex-col items-center h-[100%] p-[24px]">
      <div className="flex items-center pr-[24px] min-h-[105px]">
        <img
          src={
            (typeof card.image === "string" ? card.image : undefined) ||
            "https://via.placeholder.com/80x80"
          }
          width="100px"
          height="100px"
          className="mr-[20px]"
        />

        <Typography
          type="p"
          variant="base"
          weight="regular"
          className="font-normal text-brand-heavy-teal-100"
        >
          {card.description}
        </Typography>
      </div>

      <div>
        <hr className="mt-[22px] mb-[16px] border-brand-heavy-teal-25" />

        <div className="flex items-start">
          <div className="mr-[8px]">
            <Icons iconName="GreenTick" size="16px" />

            <Typography
              type="p"
              variant="sm"
              weight="regular"
              className="font-normal mt-[2px]"
            >
              {card.line_1}
            </Typography>
          </div>

          <div className="mr-[8px]">
            <Icons iconName="GreenTick" size="16px" />

            <Typography
              type="p"
              variant="sm"
              weight="regular"
              className="font-normal mt-[2px]"
            >
              {card.line_2}
            </Typography>
          </div>

          <div>
            <Icons iconName="GreenTick" size="16px" />

            <Typography
              type="p"
              variant="sm"
              weight="regular"
              className="font-normal mt-[2px]"
            >
              {card.line_3}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default GenericPriceCard;
