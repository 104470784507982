import ReactDOM from "react-dom/client";

import "./index.css";
import { App } from "./App";
import { AuthProvider } from "./store/auth/AuthContext";
import { initializeSentry } from "./sentry.config";
import { RecaptchaConsumer, RecaptchaProvider } from "./utils/recaptcha";

initializeSentry();

ReactDOM.createRoot(document.getElementById("root")!).render(
  <AuthProvider>
    <RecaptchaProvider>
      <RecaptchaConsumer>
        <App />
      </RecaptchaConsumer>
    </RecaptchaProvider>
  </AuthProvider>,
);
