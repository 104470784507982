import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import Typography from "@/foundation/Typography/Typography";

interface NotificationProps {
  message: string;
  params: string;
}

export const Notification = ({ message, params }: NotificationProps) => {
  const [searchParams] = useSearchParams();
  const [displayMessage, setDisplayMessage] = useState(false);

  useEffect(() => {
    const TIMEOUT = 10000;
    const signOut = searchParams.get(params);

    if (signOut) {
      setDisplayMessage(true);

      setTimeout(() => {
        setDisplayMessage(false);
      }, TIMEOUT);
    }
  }, [searchParams, params]);

  return (
    <div
      className={`${displayMessage ? "h-[80px]" : "h-0 overflow-hidden"} transition-all fixed top-[0] left-[0] z-50 px-[16px] bg-brand-electric-100 w-full flex items-center justify-center`}
    >
      <Typography
        weight="regular"
        variant="sm"
        className="text-brand-heavy-teal-100"
      >
        {message}
      </Typography>
    </div>
  );
};
