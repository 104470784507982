import { BuyerSellerBlock } from "../BuyerSellerBlock";
import { AdminTransaction } from "../../pages/TransactionDetails/types";

interface SellersTabProps {
  transaction: AdminTransaction;
}

export const SellersTab = ({ transaction }: SellersTabProps) => (
  <>
    {transaction.sellers.map((seller, index) => (
      <BuyerSellerBlock key={`seller-${index}`} buyerSeller={seller} isSeller />
    ))}
  </>
);
