import React, { ComponentProps } from "react";
import Icons, { TextInputIconProps } from "../../foundation/Icons/Icons";

export type ButtonProps = ComponentProps<"button"> & {
  children?: React.ReactNode;
  className?: string;
  size?: "sm" | "base" | "lg" | "xl" | "2xl" | "full" | "flex";
  variant: "primary" | "secondary" | "link" | "mk-primary" | "mk-secondary";
  outline?: boolean;
  disabled?: boolean;
  icon?: TextInputIconProps;
};

export const Button = ({
  children,
  disabled = false,
  variant,
  size = "base",
  outline = false,
  className,
  icon,
  ...props
}: ButtonProps) => {
  let disabledStyle = "cursor-pointer";

  if (disabled) {
    disabledStyle = "opacity-25 cursor-not-allowed";
  }

  let variantStyle;

  switch (variant) {
    case "primary":
      if (outline) {
        variantStyle =
          "border border-brand-heavy-teal-100 text-brand-heavy-teal-100 bg-trasparent hover:bg-brand-heavy-teal-100 hover:text-ui-white-100 flex leading-default items-center justify-center focus:outline-none ";
      } else {
        variantStyle =
          "text-ui-white-100 bg-brand-heavy-teal-100 hover:bg-brand-heavy-teal-75 flex leading-default items-center justify-center focus:outline-none";
      }
      break;

    case "secondary":
      if (outline) {
        variantStyle =
          "text-ui-white-100 bg-transparent border border-ui-white-100 hover:bg-ui-white-100 hover:text-brand-heavy-teal-75 flex leading-default items-center justify-center focus:outline-none";
      } else {
        variantStyle =
          "text-brand-heavy-teal-100 bg-ui-white-100 border border-brand-heavy-teal-100 hover:text-brand-heavy-teal-75 flex leading-default items-center justify-center focus:outline-none";
      }
      break;

    case "link":
      variantStyle = "text-inherit underline";
      break;

    case "mk-primary":
      variantStyle =
        "text-brand-heavy-teal-100 bg-brand-electric-100 hover:bg-brand-heavy-teal-75 flex leading-default items-center justify-center focus:outline-none";
      break;
  }

  let sizeStyle;

  switch (size) {
    case "flex":
      sizeStyle = "text-base rounded-lg px-[32px] h-12";
      break;
    case "full":
      sizeStyle = "text-base rounded-lg px-[32px] w-full h-12";
      break;
    case "2xl":
      sizeStyle = "text-base rounded-lg px-[32px] w-48 h-[64px]";
      break;
    case "xl":
      sizeStyle = "text-base rounded-lg px-[32px] w-96 h-12";
      break;
    case "lg":
      sizeStyle = "text-base rounded-lg px-[32px] w-48 h-12";
      break;
    case "base":
      sizeStyle = "text-base rounded-lg px-[32px] w-36 h-12";
      break;
    case "sm":
      sizeStyle = "text-sm rounded px-2 py-2.5 w-20 h-4";
      break;
  }

  const styles = `${variantStyle} ${sizeStyle} ${disabledStyle} font-semi-bold whitespace-nowrap`;

  return (
    <button {...props} className={`${styles} ${className}`} disabled={disabled}>
      {icon && (
        <div className="mr-[8px]">
          <Icons iconName={icon} colour="complement" size="24" />
        </div>
      )}
      {children || "Button"}
    </button>
  );
};
