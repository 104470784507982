import Typography from "@/foundation/Typography/Typography";
import Icons from "@/foundation/Icons/Icons";

interface StripeErrorProps {
  title: string;
  children: React.ReactNode;
}

export const StripeError = ({ title, children }: StripeErrorProps) => (
  <div className="bg-ui-error-25 p-[16px] rounded-2xl">
    <div className="flex flex-row items-center mb-[12px]">
      <div className="mr-[12px]">
        <Icons iconName="Info" size="24" colour="error" />
      </div>

      <Typography
        type="p"
        variant="lg"
        weight="bold"
        className="text-ui-error-100"
      >
        {title}
      </Typography>
    </div>

    {children}
  </div>
);
