import Typography from "@/foundation/Typography/Typography";

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  itemsPerPage: number;
  totalFilteredItems: number;
  handlePageChange: (newPage: number) => void;
}

export const Pagination = ({
  currentPage,
  totalPages,
  itemsPerPage,
  totalFilteredItems,
  handlePageChange,
}: PaginationProps) => {
  const generatePageNumbers = () => {
    const pages = [];

    if (totalPages <= 5) {
      // Show all page numbers if total pages are 5 or less:
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      if (currentPage <= 3) {
        // Show first 3 pages if currentPage is within the first 3 pages:
        pages.push(1, 2, 3);
        pages.push("...");
        pages.push(totalPages);
      } else if (currentPage > totalPages - 3) {
        // Show last 3 pages if currentPage is within the last 3 pages:
        pages.push(1);
        pages.push("...");
        for (let i = totalPages - 2; i <= totalPages; i++) {
          pages.push(i);
        }
      } else {
        // Show surrounding pages with ellipsis in between:
        pages.push(1);
        pages.push("...");
        pages.push(currentPage - 1, currentPage, currentPage + 1);
        pages.push("...");
        pages.push(totalPages);
      }
    }
    return pages;
  };

  const pages = generatePageNumbers();

  const startItem = (currentPage - 1) * itemsPerPage + 1;
  const endItem = Math.min(currentPage * itemsPerPage, totalFilteredItems);
  const totalItemsDisplay = `${startItem} - ${endItem} of ${totalFilteredItems}`;

  return (
    <div className="flex mt-[40px] justify-center">
      <div className="flex flex-col items-center sm:flex-row sm:justify-between">
        <div className="mb-2 sm:mb-0 sm:flex-1 sm:text-left">
          {/* Total Items Display */}
          <Typography variant="sm" className="text-brand-heavy-teal-100">
            {totalItemsDisplay}
          </Typography>
        </div>

        {/* Pagination Controls */}
        <div className="flex items-center justify-center">
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className={`text-brand-heavy-teal-100 mr-[8px] ${currentPage === 1 ? "invisible" : ""}`}
          >
            ←
          </button>
          {pages.map((page, index) => (
            <button
              key={index}
              onClick={() => typeof page === "number" && handlePageChange(page)}
              disabled={page === "..." || page === currentPage}
            >
              <Typography
                type="span"
                variant="base"
                weight="bold"
                className={`flex items-center justify-center rounded-lg text-left h-[40px] w-[40px] mx-[2px] ${
                  page === currentPage
                    ? "bg-brand-heavy-teal-5/50 text-brand-heavy-teal-100 font-bold"
                    : "text-brand-heavy-teal-100"
                }`}
              >
                {page}
              </Typography>
            </button>
          ))}
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            className={`text-brand-heavy-teal-100 ml-[8px] ${currentPage === totalPages ? "invisible" : ""}`}
          >
            →
          </button>
        </div>
      </div>
    </div>
  );
};
