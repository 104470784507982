import * as Sentry from "@sentry/react";

import { api } from ".";
import { SignInType } from "@schema";
import { paths } from "@shared/types.d";

type SignInBody =
  paths["/api/authentication/sign-in"]["post"]["requestBody"]["content"]["application/json"];

export const signIn = async (
  email: SignInType["email"],
  preventEmail: boolean,
): Promise<void> => {
  try {
    const body: SignInBody = { destination: email, preventEmail };
    await api.post("/authentication/sign-in", body);
  } catch (error: unknown) {
    Sentry.captureException(error);

    if (error instanceof Error) {
      throw new Error(`Sign in failed: ${error.message}`);
    } else {
      throw new Error("Sign in failed for an unknown reason");
    }
  }
};
