import { api } from ".";
import {
  PaymentIntentResponse,
  Product,
  StripeCustomerId,
} from "@shared/types";

export const fetchPaymentIntent = async (
  totalOrderAmount: number,
  stripeCustomerId: StripeCustomerId,
  transactionId: string,
  products: Product[],
): Promise<PaymentIntentResponse> => {
  try {
    const response = await api.post("/stripe/create-payment-intent", {
      stripeCustomerId,
      totalOrderAmount,
      transactionId,
      products,
    });

    return response.data;
  } catch (error) {
    throw new Error("Create Stripe payment intent failed");
  }
};
