import { Container } from "../../../components/Container/Container";
import { UspCard } from "./UspCard";
import Award from "../../../assets/usp-award.svg";
import Calendar from "../../../assets/usp-calendar.svg";
import Target from "../../../assets/usp-target.svg";
import Typography from "@/foundation/Typography/Typography";

export const USP = () => (
  <Container maxWidth={1150}>
    <div className="px-[24px] mt-[80px] mb-[40px]">
      <Typography
        className="font-display text-brand-heavy-teal-100 mx-auto max-w-[950px] text-center"
        type="h2"
        variant="h1"
        weight="semi-bold"
      >
        Propelr lets you sell your property quickly, easily and confidently
      </Typography>
    </div>

    <div className="grid flex-col md:grid-cols-2 md:gap-[64px] lg:grid-cols-3 lg:gap-[64px] mb-[88px] mx-[16px] align-center">
      <UspCard
        icon={Calendar}
        title="Cut up to 6 months from your sale"
        description="The average seller in the UK wastes over 6 months to complete after thinking they've secured a sale. Propelr cuts this time by 80-95% on average."
      />

      <UspCard
        icon={Target}
        title="Prevent last minute sale collapses"
        description="More than 37% of home transactions in the UK are destined to fail because of highly avoidable reasons. Propelr eliminates this risk entirely."
      />

      <UspCard
        icon={Award}
        title="Enjoy award winning conveyancing"
        description="The sales process is fraught with challenges, from unreliable buyers to unresponsive solicitors. "
      />
    </div>
  </Container>
);
