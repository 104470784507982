import { PurchaseIntentSignificantDates } from "../TransactionDetails/types";

export const getTimeOfferToExchangeInDays = (
  signifcantDates: PurchaseIntentSignificantDates | undefined,
): number | undefined => {
  const { sale_agreed, exchanged } = signifcantDates || {};

  if (!sale_agreed || !exchanged) {
    return undefined;
  }

  const timeOfferToExhange =
    new Date(exchanged).getTime() - new Date(sale_agreed).getTime();
  const differenceInDays = Math.round(timeOfferToExhange / (1000 * 3600 * 24));

  return differenceInDays;
};
