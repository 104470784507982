import { useEffect, useRef } from "react";
import { Card } from "@/components/Card/Card";
import Typography from "@/foundation/Typography/Typography";

export const paidStatusFilter = {
  all: undefined,
  paid: true,
  unpaid: false,
};

export type PaidStatusFilter =
  (typeof paidStatusFilter)[keyof typeof paidStatusFilter];

interface TransactionsFilterModalProps {
  paidStatusFilterOption: PaidStatusFilter;
  setPaidStatusFilterOption: (value: PaidStatusFilter) => void;
  onCloseModal: () => void;
}

export const TransactionsFilterModal = ({
  paidStatusFilterOption,
  setPaidStatusFilterOption,
  onCloseModal,
}: TransactionsFilterModalProps) => {
  const transactionFilterModal = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handler = (event: MouseEvent) => {
      if (!transactionFilterModal.current) {
        return;
      }

      if (!transactionFilterModal.current.contains(event.target as Node)) {
        onCloseModal();
      }
    };

    document.addEventListener("click", handler, true);
    return () => {
      document.removeEventListener("click", handler, true);
    };
  }, [onCloseModal]);

  return (
    <div ref={transactionFilterModal} className="w-[320px]">
      <Card>
        <>
          <Typography variant="h4" weight="bold" className="mb-[24px]">
            Transaction Filters
          </Typography>
          <Typography className="mb-[16px]">Paid status:</Typography>
          <div className="flex space-x-5">
            {Object.entries(paidStatusFilter).map(([key, value]) => {
              const entryKey = `paid-status-filter-${key}`;
              return (
                <div key={entryKey}>
                  <input
                    className="mr-[5px]"
                    type="radio"
                    value={entryKey}
                    name="paid-status-filter"
                    checked={paidStatusFilterOption === value}
                    onChange={() => setPaidStatusFilterOption(value)}
                  />
                  <label htmlFor={entryKey}>{key}</label>
                </div>
              );
            })}
          </div>
        </>
      </Card>
    </div>
  );
};
