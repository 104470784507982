import { Select } from "@/components/Input/Select";

interface ItemsPerPageProps {
  setItemsPerPage: (itemsPerPage: number) => void;
}

export const ItemsPerPage = ({ setItemsPerPage }: ItemsPerPageProps) => {
  const getItemsPerPageOptions = () => {
    const items = ["10", "20", "50", "100"];
    const options = items.map((x) => ({ label: x, value: x }));
    return [
      {
        label: "No. items",
        value: "",
        disabled: true,
      },
      ...options,
    ];
  };

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    setItemsPerPage(parseInt(value));
  };

  return (
    <div className="flex space-x-5">
      <Select
        name="items_per_page"
        id="items_per_page"
        options={getItemsPerPageOptions()}
        onChange={handleSelectChange}
        width="xs"
        defaultValue=""
      />
    </div>
  );
};
