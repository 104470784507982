import { api, transformPurchaseIntentForApi } from ".";
import { PurchaseIntentFormState } from "../../store/PurchaseIntentForm/purchaseIntentForm";

export const updatePurchaseIntent = async (
  id: string,
  updatedPurchaseIntent: PurchaseIntentFormState,
): Promise<PurchaseIntentFormState> => {
  try {
    const transformedData = transformPurchaseIntentForApi(
      updatedPurchaseIntent,
    );
    const response = await api.put(`/purchase-intent/${id}`, transformedData);
    return response.data;
  } catch (error) {
    throw new Error("Update purchase intent failed");
  }
};
