import { paths } from "@shared/types.d";
import { api } from ".";

export type FetchTransactionTimelineResponse =
  paths["/api/events/transaction/{id}/timeline"]["get"]["responses"]["200"]["content"]["application/json"];

export const fetchTransactionEventsTimeline = async (
  id: string,
): Promise<FetchTransactionTimelineResponse> => {
  try {
    const response = await api.get(`/events/transaction/${id}/timeline`);
    return response.data;
  } catch (error) {
    throw new Error("Error fetching transaction events timeline");
  }
};
