import validator from "validator";

import { Property } from "./types";

export const isValidPostcode = (postcode: string) => {
  return validator.isPostalCode(postcode, "GB");
};

export const getAddressesAndPropertiesOptions = (properties: Property[]) => {
  return properties.map((property, index) => {
    const address = property.address;
    return {
      label: `${address.line_1}, ${address.line_2}`,
      value: property._id || index.toString(),
    };
  });
};
