import { useParams } from "react-router-dom";

import { Fees } from "../pages/Fees/Fees";
import { PageNotFound } from "../pages/PageNotFound/PageNotFound";
import { ProtectedRoute } from "../components/ProtectedRoute/ProtectedRoute";
import { Success } from "../pages/Success/Success";
import { TermsAndConditions } from "../pages/TermsAndConditions/TermsAndConditions";
import { Welcome } from "../pages/Welcome/Welcome";
import { BuyerAboutYou } from "../pages/BuyerAboutYou/BuyerAboutYou";
import { BuyerAboutTheProperty } from "../pages/BuyerAboutTheProperty/BuyerAboutTheProperty";

export const BuyerRouter = () => {
  const { page } = useParams();

  switch (page) {
    case "success":
      return (
        <ProtectedRoute>
          <Success />
        </ProtectedRoute>
      );
    case "whats-included":
      return (
        <ProtectedRoute>
          <Fees />
        </ProtectedRoute>
      );

    case "terms-and-conditions":
      return (
        <ProtectedRoute>
          <TermsAndConditions />
        </ProtectedRoute>
      );
    case "about-the-property":
      return (
        <ProtectedRoute>
          <BuyerAboutTheProperty />
        </ProtectedRoute>
      );
    case "about-you":
      return (
        <ProtectedRoute>
          <BuyerAboutYou />
        </ProtectedRoute>
      );
    case "welcome":
      return (
        <ProtectedRoute>
          <Welcome />
        </ProtectedRoute>
      );
    default:
      return <PageNotFound />;
  }
};
