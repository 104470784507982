import { Card } from "@/components/Card/Card";
import Typography from "@/foundation/Typography/Typography";
import { AddressState } from "../../../../schema";

interface AddressDisplayCardProps {
  cardAddressTitle: string;
  onEditAddress: () => void;
  address: AddressState;
}

export const AddressDisplayCard = ({
  cardAddressTitle,
  onEditAddress,
  address,
}: AddressDisplayCardProps) => (
  <div className="w-full max-w-[500px]">
    <Card
      titleColor="brand-heavy-teal-100"
      titleBackgroundColor="brand-heavy-warmth-50"
      title={cardAddressTitle}
      titleRightContent={
        <button
          className="underline cursor-pointer text-brand-heavy-teal-75"
          onClick={onEditAddress}
          type="button"
        >
          <Typography
            variant="base"
            weight="regular"
            type="span"
            className="text-brand-heavy-teal-75"
          >
            Edit
          </Typography>
        </button>
      }
    >
      <div className="mb-[2px]">
        <Typography
          type="span"
          variant="lg"
          weight="regular"
          className="text-brand-heavy-teal-100"
        >
          {address.line_1} <br />
          {address?.line_2 && (
            <>
              {address?.line_2} <br />
            </>
          )}
          {address?.line_3 && (
            <>
              {address?.line_3} <br />
            </>
          )}
          {address.post_town} <br />
          {address.country} <br />
          {address.postcode}
        </Typography>
      </div>
    </Card>
  </div>
);
