import { TransactionResponse } from "@shared/types";
import { api, transformTransactionForApi } from ".";
import { TransactionFormState } from "../../store/TransactionForm/transactionForm";

export const updateTransaction = async (
  updatedTransaction: TransactionFormState,
): Promise<{ transaction: TransactionResponse }> => {
  try {
    const transformedData = transformTransactionForApi(updatedTransaction);

    const response = await api.put(
      `/transaction/${transformedData._id}`,
      transformedData,
    );
    return response.data;
  } catch (error) {
    throw new Error("Update transaction failed");
  }
};
