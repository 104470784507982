export const reviews = [
  {
    type: "Buyer",
    initials: "HS",
    title: "Propelr got us the exchange",
    content:
      "Overall process was smooth and efficient, and got us the timely exchange that we wanted. Propelr and the solicitors were professional throughout. No complaints and very happy with the service!",
  },
  {
    type: "Seller",
    initials: "AW",
    title: "Propelr pulled off a miracle!",
    content:
      "I attempted to sell my apartment (which was undergoing cladding replacement) for a year before I came across Propelr. A cost of living crisis, several mortgage rate rises and cladding issues resulted in three buyers pulling out a tthe last minute leaving us distraught. On the verge of losing our dream home, my wife and I approached Propelr and they helped us exchange and complete in time to purchase our dream home and also avoid a big interest rate hike. An absolute miracle - I can’t thank them enough!",
  },
  {
    type: "Agent",
    initials: "TF",
    title: "Propelr were crucial",
    content:
      "Having the buyer being able to see the full report on title before he made a decision to move forward was invaluable in this process.",
  },
  {
    type: "Seller",
    initials: "SM",
    title: "Propelr is a no brainer",
    content:
      "From the moment we instructed Propelr to exchange of contracts we couldn’t believe how seamless the process was. After wasting four months under offer last year, with the buyer pulling out at the last moment, incurring hefty legal fees, we decided we needed more control over the conveyancing process by instructing Propelr. We agreed a sale after only three weeks and exchanged contracts five days later, removing the worry of the sale falling through or the buyer renegotiating the price at a later date. I would urge anyone selling their house to use Propelr and expect it to be an industry norm in the future.",
  },
  {
    type: "Buyer",
    initials: "RR",
    title: "A brilliant service",
    content:
      "A brilliant service. Having all of the survey information, searches and title information pre-prepared made exchanging contracts extremely quick and efficient. Two weeks from start to finish.",
  },
  {
    type: "Buyer",
    initials: "SK",
    title: "Remarkably fast",
    content:
      "Fantastic service. As soon as I put the offer in, the Propelr team was very responsive, and the process itself was remarkably fast. I am very pleased and highly recommend Propelr to anyone in search of a smooth and hassle-free property experience.",
  },
];
