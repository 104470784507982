import { useEffect, useState } from "react";

import { Table, TableProps } from "../Table/Table";
import { Address } from "../../pages/UserDetails/types";
import { Card } from "@/components/Card/Card";

interface PropertyTabProps {
  address: Address;
  hasMortgage: boolean;
  onwardPurchase: boolean;
}

export const PropertyTab = ({
  address,
  hasMortgage,
  onwardPurchase,
}: PropertyTabProps) => {
  const [addressDetails, setAddressDetails] = useState<TableProps[]>([]);

  useEffect(() => {
    setAddressDetails([
      {
        label: "Address Line 1",
        value: address.line_1,
      },
      {
        label: "Address Line 2",
        value: address.line_2,
      },
      {
        label: "Address Line 3",
        value: address.line_3,
      },
      {
        label: "Post Town",
        value: address.post_town,
      },
      {
        label: "Country",
        value: address.country,
      },
      {
        label: "Postcode",
        value: address.postcode,
      },
      {
        label: "County",
        value: address.county,
      },
      {
        label: "URPN",
        value: address.uprn,
      },
      {
        label: "Building Number",
        value: address.building_number,
      },
      {
        label: "Sub Building Name",
        value: address.sub_building_name,
      },
      {
        label: "Building Name",
        value: address.building_name,
      },
      {
        label: "Orgnanisation Name",
        value: address.organisation_name,
      },
      {
        label: "PO Box",
        value: address.po_box,
      },
      {
        label: "Has Mortgage",
        value: hasMortgage ? "Yes" : "No",
      },
      {
        label: "Onward Purchase",
        value: onwardPurchase ? "Yes" : "No",
      },
    ]);
  }, [address, hasMortgage, onwardPurchase]);

  return (
    <>
      <Card
        title="Property Details"
        titleBackgroundColor="brand-heavy-warmth-25"
        titleColor="brand-heavy-teal-100"
      >
        <>
          {addressDetails.map(({ label, value }) => (
            <Table
              key={`property-details-${label}`}
              label={label}
              value={value}
            />
          ))}
        </>
      </Card>
    </>
  );
};
