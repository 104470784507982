import { Navigate } from "react-router-dom";

import { useAuthStore } from "../../store/auth/auth";
import { useLoginStore } from "../../store/Login/login";

interface AdminProtectedRouteProps {
  children: React.ReactNode;
}

export const AdminProtectedRoute = ({ children }: AdminProtectedRouteProps) => {
  const { userData } = useAuthStore();
  const { role } = useLoginStore();

  const isAllowed = userData?.type === "admin" && userData?.active;

  // If the user is not logged in AND not an admin, redirect to the sign-in page
  if (!isAllowed) {
    return <Navigate to={`/${role}/get-started`} replace />;
  }

  return children;
};
