import React, { useEffect, useState, useCallback } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

import Typography from "@/foundation/Typography/Typography";
import { Spinner } from "../../components/Spinner/Spinner";
import { Button } from "@/components/Button/Button";
import { StripeError } from "./StripeError";

import { CLIENT_ORIGIN_URL } from "../../constants";
import { formatCurrency } from "@shared/utils";
import { PaymentIntentStatusSchema, TotalAmountSchema } from "@shared/schemas";

interface CheckoutFormProps {
  totalAmount: number;
}

export const CheckoutForm = ({ totalAmount }: CheckoutFormProps) => {
  const stripe = useStripe();
  const elements = useElements();
  const validatedTotalAmount = TotalAmountSchema.parse(totalAmount);
  const [paymentStatusMessage, setPaymentStatusMessage] = useState<
    string | null
  >(null);
  const [isLoading, setIsLoading] = useState(false);

  const retrievePaymentIntent = useCallback(
    async (clientSecret: string) => {
      if (!stripe) {
        console.error("Stripe has not been initialized");
        return;
      }

      const response = await stripe.retrievePaymentIntent(clientSecret);

      if (!response || !response.paymentIntent) {
        console.error(
          "Failed to retrieve payment intent or paymentIntent is undefined",
        );
        return;
      }

      PaymentIntentStatusSchema.parse(response.paymentIntent.status);

      const { paymentIntent } = response;

      switch (paymentIntent.status) {
        case "succeeded":
          setPaymentStatusMessage("Payment succeeded!");
          break;
        case "processing":
          setPaymentStatusMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setPaymentStatusMessage(
            "Your payment was not successful, please try again.",
          );
          break;
        default:
          setPaymentStatusMessage("Something went wrong.");
      }
    },
    [stripe],
  );

  useEffect(() => {
    if (!stripe) return;

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret",
    );

    if (clientSecret) {
      retrievePaymentIntent(clientSecret);
    }
  }, [stripe, retrievePaymentIntent]);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!stripe || !elements) return;

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${CLIENT_ORIGIN_URL}/sell/payment-success`,
      },
    });

    if (error) {
      setPaymentStatusMessage(error.message || "An unexpected error occurred.");
    }

    setIsLoading(false);
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <div className="flex flex-col justify-between">
        <PaymentElement id="payment-element" />

        {paymentStatusMessage && (
          <div className="mt-[24px]">
            <StripeError title="Payment Error">
              <Typography
                type="p"
                variant="sm"
                weight="regular"
                className="text-ui-error-100 ml-[36px] mb-[8px]"
              >
                {paymentStatusMessage} Please check your details and try again.
              </Typography>
            </StripeError>
          </div>
        )}

        <Button
          variant="primary"
          className="w-full mt-[24px]"
          disabled={isLoading || !stripe || !elements}
          id="submit"
        >
          <span id="button-text">
            {isLoading ? (
              <span className="flex items-center">
                <Spinner /> Processing...
              </span>
            ) : (
              <div>{`Pay  ${formatCurrency(validatedTotalAmount)} now`}</div>
            )}
          </span>
        </Button>
      </div>
    </form>
  );
};
