// This function transforms an array of Buyers and returns
// the single primary buyer.

import { transformBuyer } from "./transformBuyer";
import { PurchaseIntentBuyer, PrimaryBuyerLocalState } from "@shared/types";

export const transformTransactionBuyerState = (
  buyers: PurchaseIntentBuyer[],
): PrimaryBuyerLocalState => {
  const primaryBuyer = buyers.find((buyer) => buyer.primary_customer);

  if (!primaryBuyer) {
    throw Error("Could not find primary buyer to transform to state");
  }

  return transformBuyer(primaryBuyer);
};
