import * as Sentry from "@sentry/react";
import { useEffect, useContext, useCallback } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { ApiController } from "../../service/ApiController";
import { AuthContext } from "../../store/auth/AuthContext";
import { useLoginStore } from "../../store/Login/login";
import { useAuthStore } from "../../store/auth/auth";

export const Authenticate = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const auth = useContext(AuthContext);
  const { setRole } = useLoginStore();
  const { userData } = useAuthStore();

  const authenticateUser = useCallback(async () => {
    const token = searchParams.get("token");
    const type = searchParams.get("type");
    const role = searchParams.get("role");

    if (!role && type === "customer") {
      Sentry.captureException(
        new Error(
          `Role is missing in the query params: ${window.location.href}`,
        ),
      );
    }

    setRole(role as "buy" | "sell");

    try {
      await ApiController.verifyUser(token);
      auth?.reinitializeAuth();

      if (userData) {
        if (type === "admin") {
          navigate("/admin", { replace: true });
        } else {
          navigate(`/welcome-back`, { replace: true });
        }
      }
    } catch (error) {
      Sentry.captureException(error);
      navigate(`/authenticate/link-expired`, { replace: true });
    }
  }, [auth, setRole, navigate, searchParams, userData]);

  useEffect(() => {
    authenticateUser();
  }, [authenticateUser]);

  return null;
};
