import {
  api,
  transformAdminPurchaseIntentForApi,
} from "../../../service/ApiController";
import { PurchaseIntent } from "../../pages/TransactionDetails/types";

export const updateAdminPurchaseIntent = async (
  purchaseIntent: PurchaseIntent,
): Promise<PurchaseIntent> => {
  try {
    const transformedData = transformAdminPurchaseIntentForApi(purchaseIntent);
    const response = await api.put(
      `/purchaseIntent/${transformedData._id}`,
      transformedData,
    );
    return response.data;
  } catch (error) {
    throw new Error("Update purchase intent failed");
  }
};
