import { useNavigate } from "react-router-dom";

import { Footer } from "../Footer/Footer";
import Typography from "@/foundation/Typography/Typography";
import GenericPriceCard from "@/components/PriceCard/GenericPriceCard";
import { feesCards } from "./feeBasket";
import { ApiController } from "../../service/ApiController";
import { usePurchaseIntentFormStore } from "../../store/PurchaseIntentForm/purchaseIntentForm";

export const FeesContainer = () => {
  const navigate = useNavigate();
  const { purchaseIntent } = usePurchaseIntentFormStore();

  const confirmPurchaseIntent = async () => {
    try {
      await ApiController.confirmPurchaseIntent(purchaseIntent._id);
    } catch (error) {
      throw new Error("Purchase intent confirmation failed.");
    }
  };

  const handleSuccess = () => {
    confirmPurchaseIntent();
    navigate("/buy/success");
  };

  return (
    <div className="flex flex-col items-start mt-[48px]">
      <Typography
        type="h2"
        variant="h4"
        weight="bold"
        className="mb-[12px] text-brand-heavy-teal-100"
      >
        By choosing Propelr you are saving:
      </Typography>

      <div className="flex flex-col md:flex-row gap-[16px]">
        {feesCards.map((card) => (
          <div key={card.id} className="flex">
            <GenericPriceCard card={card} />
          </div>
        ))}
      </div>

      <Footer
        handleBack={() => navigate("/buy/terms-and-conditions")}
        handleContinue={handleSuccess}
        hasValidation={false}
        continueTitle="Amazing, let's go!"
      />
    </div>
  );
};
