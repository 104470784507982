import Typography from "@/foundation/Typography/Typography";
import { useTabletMediaQuery } from "../../../mediaQueries";
import { Review } from "../Review/Review";
import TrustpilotLogo from "../../../assets/trustpilot.svg";
import { reviews } from "./reviews";

export const Trustpilot = () => {
  const isTablet = useTabletMediaQuery();

  return (
    <div className="pb-[80px]">
      <div className="bg-gradient-to-b from-brand-heavy-teal-10 to-transparent">
        <div className="flex justify-center items-center pt-[100px] mb-[56px]">
          <img src={TrustpilotLogo} />
        </div>

        <Typography
          className={`font-display text-center text-brand-heavy-teal-100 pb-[140px]`}
          type="h2"
          variant={isTablet ? "h1" : "h2"}
          weight="semi-bold"
        >
          Propelr creates happy sellers
        </Typography>
      </div>

      <div className="w-full flex flex-row overflow-x-scroll pt-[50px] mt-[-100px]">
        {reviews.map((review, index) => (
          <Review
            key={index}
            type={review.type}
            initials={review.initials}
            title={review.title}
            content={review.content}
          />
        ))}
      </div>
    </div>
  );
};
