import { useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";

import { FeatureCard } from "../../../../packages/pongo/src/components/FeatureCard/FeatureCard";
import { Header } from "../../components/Header/Header";
import { Nav } from "../../components/Nav/Nav";
import { Container } from "../../components/Container/Container";
import { Background } from "../../components/Background/Background";
import Typography from "@/foundation/Typography/Typography";
import StepperGroup from "@/components/Stepper/StepperGroup";
import { LinkWrapper } from "@/components/LinkWrapper/LinkWrapper";
import { sellPaymentSuccess } from "../../components/Header/allStepperOptions";
import { EMAIL_ADDRESS } from "../../constants";

export const PaymentSuccess = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const paymentIntent = searchParams.get("payment_intent");
    const redirectStatus = searchParams.get("redirect_status");

    if (!paymentIntent || redirectStatus !== "succeeded") {
      navigate("/sell/payment");
    }
  }, [searchParams, navigate]);

  return (
    <Background className="flex flex-col">
      <Nav />

      <Container>
        <div className="relative flex flex-col mb-[160px] px-[24px] lg:px-0">
          <Header title="Success">
            <StepperGroup options={sellPaymentSuccess}></StepperGroup>
          </Header>

          <Typography
            type="h2"
            variant="h4"
            weight="bold"
            className="mb-[32px] pt-[48px] text-brand-heavy-teal-100"
          >
            Thank you for your payment. You will receive a confirmation shortly.
          </Typography>

          <div className="flex flex-col md:flex-row">
            <div className="md:pr-[68px] md:border-r border-brand-heavy-teal-25 mb-[40px]">
              <Typography
                type="h2"
                variant="h4"
                weight="bold"
                className="mb-[8px] text-brand-heavy-teal-100"
              >
                Next steps for us:
              </Typography>

              <div className="flex flex-col">
                <FeatureCard
                  customClass="max-w-[380px]"
                  description="We'll raise searches on behalf of your future buyer."
                  iconName="Search"
                  title="Raise searches"
                  orientation="portrait"
                />
                <FeatureCard
                  customClass="max-w-[380px]"
                  description="We’ll introduce you via email to your pre-approved solicitor."
                  iconName="MatchSolicitor"
                  title="Introduce you to your solicitor"
                  orientation="portrait"
                />
              </div>
            </div>

            <div className="md:pl-[68px] mb-[40px]">
              <div className="flex items-center">
                <Typography
                  type="h2"
                  variant="h4"
                  weight="bold"
                  className="mb-[8px] ml-[6px] text-brand-heavy-teal-100"
                >
                  Next steps for you:
                </Typography>
              </div>

              <div className="flex flex-col">
                <FeatureCard
                  customClass="max-w-[380px]"
                  description="You'll shortly receive a text message from Thirdfort. Please follow the instructions to complete your ID checks."
                  iconName="ID"
                  title="Complete ID check"
                  cardColour="bg-brand-warmth-100"
                  orientation="portrait"
                />
                <FeatureCard
                  customClass="max-w-[380px]"
                  description="Your solicitor will be in touch shortly to introduce themselves and onboard you."
                  iconName="Sign"
                  title="Sign engagement letter"
                  cardColour="bg-brand-warmth-100"
                  orientation="portrait"
                />
                <FeatureCard
                  customClass="max-w-[380px]"
                  description="Your solicitor will give you 'TA' forms to fill in. It's very important that you complete these in as soon as possible."
                  iconName="FormWebsite"
                  title="Fill in TA forms"
                  cardColour="bg-brand-warmth-100"
                  orientation="portrait"
                />
              </div>
            </div>
          </div>

          <Typography
            type="h2"
            variant="h4"
            weight="bold"
            className="mb-[12px] text-brand-heavy-teal-100"
          >
            Any questions?
          </Typography>

          <Typography
            type="p"
            variant="base"
            weight="semi-bold"
            className="mb-[24px] text-brand-heavy-teal-100"
          >
            If you have any questions regarding the next steps please get in
            touch with us by emailing{" "}
            <LinkWrapper>
              <a href={`mailto:${EMAIL_ADDRESS.SUPPORT}`}>
                {EMAIL_ADDRESS.SUPPORT}
              </a>
            </LinkWrapper>
            .
          </Typography>
        </div>
      </Container>
    </Background>
  );
};
