import { Container } from "../../../components/Container/Container";
import Typography from "@/foundation/Typography/Typography";
import { useTabletMediaQuery } from "../../../mediaQueries";
import { steps } from "./steps";
import { Step } from "./Step";

import Arrow1 from "../../../assets/arrows/arrow1.svg";
import Arrow2 from "../../../assets/arrows/arrow2.svg";

export const SimpleSteps = () => {
  const isTablet = useTabletMediaQuery();

  return (
    <div className="pb-[80px]">
      <Container maxWidth={1150}>
        <div className="mb-[80px] px-[16px]">
          <Typography
            className={`font-display text-center text-brand-heavy-teal-100 mb-[16px]`}
            type="h2"
            variant={isTablet ? "h1" : "h2"}
            weight="semi-bold"
          >
            Simple steps to get started
          </Typography>
        </div>

        <div className="relative flex flex-col justify-between items-center md:flex-row md:items-start">
          <img
            src={Arrow1}
            className="absolute top-[270px] md:top-[-10px] rotate-90 md:rotate-0 right-[20px] md:right-auto md:left-[200px] w-[110px]"
          />
          <img
            src={Arrow2}
            className="absolute top-[580px] rotate-90 md:rotate-0 left-[20px] md:left-auto md:top-auto md:bottom-0 md:right-[300px] w-[110px]"
          />

          {steps.map((step, index) => (
            <Step
              key={index}
              title={step.title}
              imageUrl={step.imageUrl}
              content={step.content}
            />
          ))}
        </div>
      </Container>
    </div>
  );
};
