import z from "zod";
import { DateInputValue } from "./types";

const DateInputSchema: z.ZodType<DateInputValue> = z.object({
  day: z
    .string()
    .optional()
    .transform((value, ctx) => {
      if (!value) return;

      const dayNumber = parseInt(value);

      if (dayNumber < 1 || dayNumber > 31) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Day must be between 1 and 31",
        });
        return z.NEVER;
      }

      return value;
    }),
  month: z
    .string()
    .optional()
    .transform((value, ctx) => {
      if (!value) return;

      const monthNumber = parseInt(value);

      if (monthNumber < 1 || monthNumber > 12) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Month must be between 1 and 12",
        });
        return z.NEVER;
      }

      return value;
    }),
  year: z
    .string()
    .optional()
    .transform((value, ctx) => {
      if (!value) return;

      const yearNumber = parseInt(value);

      if (yearNumber < 1900) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Year must be greater than 1900",
        });
        return z.NEVER;
      }

      return value;
    }),
});

export const DateInputOptionalSchema = DateInputSchema.refine(
  (value) => {
    const { day, month, year } = value;

    if (!day && !month && !year) return true;
    if (day && month && year) {
      const date = z.string().date();
      const { success } = date.safeParse(`${year}-${month}-${day}`);
      return success;
    }
    return false;
  },
  { message: "Please enter a valid date or clear all date fields" },
);

export const DateInputRequiredSchema = DateInputSchema.refine(
  (value) => {
    const { day, month, year } = value;

    if (day && month && year) return true;
    return false;
  },
  { message: "Please enter a valid date" },
);
