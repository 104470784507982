import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Header } from "../../components/Header/Header";
import { Footer } from "../../components/Footer/Footer";
import { Nav } from "../../components/Nav/Nav";
import { OverviewSeller } from "../../components/TermsAndConditions/OverviewSeller";
import { OverviewBuyer } from "../../components/TermsAndConditions/OverviewBuyer";
import { TermsAndConditionsOfUse } from "../../components/TermsAndConditions/TermsAndConditionsOfUse";
import { buyTerms, sellTerms } from "../../components/Header/allStepperOptions";
import { Background } from "../../components/Background/Background";
import { Container } from "../../components/Container/Container";
import StepperGroup from "@/components/Stepper/StepperGroup";
import { useTransactionFormStore } from "../../store/TransactionForm/transactionForm";
import { ApiController } from "../../service/ApiController";
import { useLoginStore } from "../../store/Login/login";
import { usePurchaseIntentFormStore } from "../../store/PurchaseIntentForm/purchaseIntentForm";
import {
  transformTransactionPropertyState,
  transformTransactionSellerState,
} from "@transformers";
import { ErrorMessageWithEmail } from "../../components/Form/DetailsForm/AddressLookup/ErrorMessageWithEmail";

export const TermsAndConditions = () => {
  const [error, setError] = useState<string>("");
  const { transaction, updateTransaction } = useTransactionFormStore();
  const { purchaseIntent, updatePurchaseIntent } = usePurchaseIntentFormStore();
  const { role } = useLoginStore();
  const navigate = useNavigate();

  const handleFormSubmit = async () => {
    try {
      if (role === "sell") {
        let existingTransaction;
        if (transaction._id !== "") {
          existingTransaction = await ApiController.findTransaction(
            transaction._id,
          );
        }
        if (existingTransaction) {
          await ApiController.updateTransaction(transaction);
          navigate("/sell/payment");
        } else {
          transaction.primarySeller.termsAndConditions = true;
          const createdTransaction =
            await ApiController.createTransaction(transaction);

          if (createdTransaction) {
            const transformedPrimarySeller = transformTransactionSellerState(
              createdTransaction.transaction.sellers,
            );
            const transformedProperty = transformTransactionPropertyState(
              createdTransaction.transaction,
            );
            updateTransaction({
              ...createdTransaction.transaction,
              primarySeller: transformedPrimarySeller,
              property: transformedProperty,
            });
            navigate("/sell/payment");
          }
        }
      } else {
        if (purchaseIntent._id) {
          await ApiController.updatePurchaseIntent(
            purchaseIntent._id,
            purchaseIntent,
          );
        } else {
          purchaseIntent.primaryBuyer.termsAndConditions = true;
          const createdPurchaseIntent =
            await ApiController.createPurchaseIntent(purchaseIntent);

          updatePurchaseIntent({
            ...purchaseIntent,
            _id: createdPurchaseIntent.purchaseIntent._id,
          });
        }
        navigate("/buy/whats-included");
      }
    } catch (error) {
      if (error instanceof Error) {
        setError(`${error.message}. Please contact support at`);
      }
    }
  };

  return (
    <Background className="flex flex-col">
      <Nav />

      <Container>
        <div className="flex flex-col px-[24px] lg:px-0">
          <Header title="Our Terms & Conditions">
            <StepperGroup
              options={role === "buy" ? buyTerms : sellTerms}
            ></StepperGroup>
          </Header>

          {error ? (
            <ErrorMessageWithEmail propertyErrorMessage={error} />
          ) : (
            <>
              <div className="flex flex-col md:flex-row">
                <div>
                  {role === "sell" ? <OverviewSeller /> : <OverviewBuyer />}
                </div>

                <TermsAndConditionsOfUse />
              </div>

              <Footer
                handleBack={() => navigate(`/${role}/about-the-property`)}
                handleContinue={handleFormSubmit}
                continueTitle="Agree and Continue"
                hasValidation={false}
              />
            </>
          )}
        </div>
      </Container>
    </Background>
  );
};
