// This function transforms the Buyer from the database into
// the state used in the Transaction local storage object

import { PurchaseIntentBuyer, PrimaryBuyerLocalState } from "@shared/types";

export const transformBuyer = (
  buyer: PurchaseIntentBuyer,
): PrimaryBuyerLocalState => {
  if (!buyer.user.profile) {
    throw Error(
      "Could not find profile on the buyer. Cannot transform to local storage.",
    );
  }

  const defaultValue = "";

  return {
    personId: buyer.user._id,
    firstName: buyer.user.first_name || defaultValue,
    middleName: buyer.user.middle_name || defaultValue,
    lastName: buyer.user.last_name || defaultValue,
    email: buyer.user.email,
    mobileNumber: buyer.user.profile?.phone_number || defaultValue,
    termsAndConditions: buyer.terms_and_conditions,
    correspondenceAddress: {
      line_1: buyer.user.profile?.correspondence_address?.line_1,
      line_2:
        buyer.user.profile?.correspondence_address?.line_2 || defaultValue,
      line_3:
        buyer.user.profile?.correspondence_address?.line_3 || defaultValue,
      post_town: buyer.user.profile?.correspondence_address?.post_town,
      postcode: buyer.user.profile?.correspondence_address?.postcode,
      country: buyer.user.profile?.correspondence_address?.country,
      county:
        buyer.user.profile?.correspondence_address?.county || defaultValue,
      uprn: buyer.user.profile?.correspondence_address?.uprn || defaultValue,
      district:
        buyer.user.profile?.correspondence_address?.district || defaultValue,
      building_number:
        buyer.user.profile?.correspondence_address?.building_number ||
        defaultValue,
      sub_building_name:
        buyer.user.profile?.correspondence_address?.sub_building_name ||
        defaultValue,
      building_name:
        buyer.user.profile?.correspondence_address?.building_name ||
        defaultValue,
      premise:
        buyer.user.profile?.correspondence_address?.premise || defaultValue,
      organisation_name:
        buyer.user.profile?.correspondence_address?.organisation_name ||
        defaultValue,
      po_box:
        buyer.user.profile?.correspondence_address?.po_box || defaultValue,
    },
    primaryCustomer: buyer.primary_customer,
    customerType: buyer.customer_type,
    giftors: buyer.giftors,
  };
};
