import { useState } from "react";

import { Card, Tenure } from "@shared/types";
import { TextInputIconProps } from "../../foundation/Icons/Icons";
import OtherSelectableCard from "./OtherSelectableCard";
import SelectableCard from "./SelectableCard";

export type SelectableCardGroupProps = {
  variant?: "enabled" | "disabled" | "error" | "success";
  options: Array<string | TextInputIconProps>;
  handleChange: (option: string) => void;
  radioName: string;
  value: string;
  card: Card<string>[];
  otherCard?: boolean;
  size?: string;
  isInitialSelected?: string;
  selectedOption?: string;
};

export const SelectableCardGroup = ({
  variant = "enabled",
  options,
  value,
  handleChange,
  radioName,
  card,
  otherCard = false,
  size,
  isInitialSelected,
  selectedOption,
}: SelectableCardGroupProps) => {
  const [isOtherSelected, setIsOtherSelected] = useState(false);

  return (
    <div className="flex flex-col md:flex-row md:flex-wrap gap-[16px]">
      {options.map((option) => {
        const cardItem = card.find((item) => item.option === option);
        const isSelected = option === value || option === selectedOption;
        const isError = variant === "error";

        const textColor = isSelected
          ? "text-ui-white-100"
          : "text-brand-heavy-teal-75";

        const bgColor = isSelected
          ? "bg-brand-heavy-teal-100"
          : isError
            ? "bg-ui-white-100 border-ui-error-100 border-2"
            : "bg-ui-white-100";

        if (otherCard && option === Tenure.Other) {
          return (
            <OtherSelectableCard
              label={cardItem ? cardItem.label : Tenure.Other}
              key={option}
              variant={variant}
              radioName={radioName}
              handleChange={(e) => {
                handleChange(e);
                setIsOtherSelected(isOtherSelected ? false : true);
              }}
              option={option}
              value={value}
              className={`w-full md:w-[200px] ${bgColor} ${textColor}`}
            />
          );
        } else {
          return (
            <SelectableCard
              key={option}
              variant={variant}
              label={cardItem ? cardItem.label : ""}
              message={cardItem ? cardItem.message : ""}
              radioName={radioName}
              icon={cardItem && cardItem.icon ? cardItem.icon : "Freehold"}
              whiteIcon={
                cardItem && cardItem.whiteIcon
                  ? cardItem.whiteIcon
                  : "FreeholdWhite"
              }
              handleChange={(e) => {
                handleChange(e);
                setIsOtherSelected(false);
              }}
              option={option}
              isInitialSelected={isInitialSelected}
              value={value}
              className={`${size ? size : "w-full md:w-[320px]"} ${bgColor} ${textColor}`}
            />
          );
        }
      })}
    </div>
  );
};
