import { useField } from "formik";
import { statusConfig } from "@/components/Input/statusConfig";

export const DateInputPart = ({
  variant = "enabled",
  name,
  placeholder,
  isYear = false,
}: {
  name: string;
  placeholder: string;
  isYear?: boolean;
  variant: "enabled" | "disabled" | "success" | "error";
}) => {
  const [field] = useField(name);

  let inputClass =
    "rounded-lg border-2 border-ui-black-25 h-14 flex flex-col justify-center items-start pl-3.5 pr-2.5  text-base text-ui-black-75 placeholder-ui-black-75 leading-default focus:outline-none appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none";

  if (isYear) {
    inputClass += " w-[130px]";
  } else {
    inputClass += " w-[64px]";
  }

  inputClass += ` ${statusConfig[variant]}` || "";

  return (
    <div>
      <input
        {...field}
        value={field.value || ""}
        placeholder={placeholder}
        className={inputClass}
        type="text"
        pattern="\d*"
        maxLength={isYear ? 4 : 2}
      />
    </div>
  );
};
