import { z } from "zod";

export const SignUpSchema = z
  .object({
    firstName: z.string().min(1, { message: "Please enter your first name" }),
    middleName: z.string().optional(),
    lastName: z.string().min(1, { message: "Please enter your last name" }),
    email: z.string().email({ message: "Please enter a valid email address" }),
  })
  .required({ email: true, firstName: true, lastName: true });

export type SignUpType = z.infer<typeof SignUpSchema>;
